import { useEffect, useState } from "react";
import { SocialFacebook } from "@styled-icons/foundation/SocialFacebook";
import { Instagram } from "@styled-icons/boxicons-logos/Instagram";
import { useNavigate } from "react-router-dom";
import {
  ContentfulNavbarDropdown,
  ContentfulNavbarHeader,
} from "../api/types/contentful-content";
import { NavbarDropdown } from "./navbar-dropdown";

type props = {
  navbarHeader1?: ContentfulNavbarHeader;
  navbarHeader2?: ContentfulNavbarHeader;
  navbarHeader3?: ContentfulNavbarHeader;
  navbarHeader4?: ContentfulNavbarHeader;
  navbarHeader5?: ContentfulNavbarHeader;
  navbarHeader6?: ContentfulNavbarHeader;
  navbarHeader7?: ContentfulNavbarHeader;
  navbarHeader8?: ContentfulNavbarHeader;
  navbarHeader9?: ContentfulNavbarHeader;
  navbarHeader10?: ContentfulNavbarHeader;
  navbarDropdown1?: ContentfulNavbarDropdown;
  navbarDropdown2?: ContentfulNavbarDropdown;
  navbarDropdown3?: ContentfulNavbarDropdown;
  navbarDropdown4?: ContentfulNavbarDropdown;
  navbarDropdown5?: ContentfulNavbarDropdown;
};

export const Navbar: React.FunctionComponent<props> = ({
  navbarHeader1,
  navbarHeader2,
  navbarHeader3,
  navbarHeader4,
  navbarHeader5,
  navbarHeader6,
  navbarHeader7,
  navbarHeader8,
  navbarHeader9,
  navbarHeader10,
  navbarDropdown1,
  navbarDropdown2,
  navbarDropdown3,
  navbarDropdown4,
  navbarDropdown5,
}) => {
  const navigate = useNavigate();
  const [responsive, setResponsive] = useState(false);
  const [offset, setOffset] = useState(0);
  const [showDropdown, setShowDropdown] = useState<string[]>([]);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      window.location.pathname.toLowerCase() === "/events" &&
      navbarHeader2?.disabled
    ) {
      navigate("/");
    }
    if (
      window.location.pathname.toLowerCase() === "/reservations" &&
      navbarHeader3?.disabled
    ) {
      navigate("/");
    }
    if (
      (window.location.pathname.toLowerCase() === "/our-cats" ||
        window.location.pathname.toLowerCase() === "/testimonials" ||
        window.location.pathname.toLowerCase() === "/apply-here") &&
      navbarDropdown1?.disabled
    ) {
      navigate("/");
    }
    if (
      window.location.pathname.toLowerCase() === "/careers" &&
      navbarHeader6?.disabled
    ) {
      navigate("/");
    }
    if (
      window.location.pathname.toLowerCase() === "/faq" &&
      navbarHeader8?.disabled
    ) {
      navigate("/");
    }
    if (
      window.location.pathname.toLowerCase() === "/gift-cards" &&
      navbarHeader9?.disabled
    ) {
      navigate("/");
    }
    if (
      window.location.pathname.toLowerCase() === "/online-menu" &&
      navbarHeader10?.disabled
    ) {
      navigate("/");
    }
  });

  useEffect(() => {
    if (responsive) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  });

  const handleDropdown = (dropdown: string) => {
    setShowDropdown((prevState) => {
      if (prevState.includes(dropdown)) {
        const clone = [...prevState];
        clone.splice(prevState.indexOf(dropdown), 1);
        return clone;
      } else {
        return [...prevState, dropdown];
      }
    });
  };

  return (
    <nav
      className={
        offset > 65 && !responsive
          ? "nav nav-responsive"
          : responsive
          ? "forced-theme-bg-color-2 nav"
          : "nav"
      }
    >
      <div className="nav-container">
        <div
          className={
            offset > 65 && !responsive ? "logo logo-responsive" : "logo"
          }
        >
          <img
            className="w-full"
            src={"/new-logo.png"}
            alt=""
            onClick={() => navigate("/")}
          />
        </div>
        <div
          className={
            responsive
              ? "nav-primary-list show-list"
              : "nav-primary-list hide-list"
          }
        >
          <ul>
            {navbarHeader1 && !navbarHeader1.disabled && (
              <li>
                <a href="/">{navbarHeader1.text}</a>
              </li>
            )}
            {navbarHeader2 && !navbarHeader2.disabled && (
              <li>
                <a href="/events">{navbarHeader2.text}</a>
              </li>
            )}
            {navbarHeader3 && !navbarHeader3.disabled && (
              <li>
                <a href="/reservations">{navbarHeader3.text}</a>
              </li>
            )}
            {navbarDropdown1 &&
              !navbarDropdown1.disabled &&
              navbarDropdown1.label && (
                <NavbarDropdown
                  navbarDropdown={navbarDropdown1}
                  url1={"/our-cats"}
                  url2={"/testimonials"}
                  url3={"/apply-here"}
                  onClick={() => handleDropdown("1")}
                  showDropdown={showDropdown.includes("1") ? true : false}
                />
              )}
            {navbarHeader6 && !navbarHeader6.disabled && (
              <li>
                <a href="/careers">{navbarHeader6.text}</a>
              </li>
            )}
            {navbarHeader7 && !navbarHeader7.disabled && (
              <li>
                <a href="https://smitten-kitten-cat-cafe.square.site/">
                  {navbarHeader7.text}
                </a>
              </li>
            )}
            {navbarDropdown2 &&
              !navbarDropdown2.disabled &&
              navbarDropdown2.label && (
                <NavbarDropdown
                  navbarDropdown={navbarDropdown2}
                  url1={"/gift-cards"}
                  url2={"/online-menu"}
                  onClick={() => handleDropdown("2")}
                  showDropdown={showDropdown.includes("2") ? true : false}
                />
              )}
            {navbarHeader8 && !navbarHeader8.disabled && (
              <li>
                <a href="/faq">{navbarHeader8.text}</a>
              </li>
            )}
            <li className="responsive-header-icons">
              <a
                className="header-facebook-icon"
                href="https://www.facebook.com/FishersCatCafe/"
                target="_blank"
                rel="noreferrer"
              >
                <SocialFacebook className="header-facebook-icon" />
              </a>
              <a
                className="header-instagram-icon"
                href="https://www.instagram.com/smittenkittencatcafe"
                target="_blank"
                rel="noreferrer"
              >
                <Instagram className="header-instagram-icon" />
              </a>
            </li>
          </ul>
        </div>
        <span
          className={responsive ? "nav-trigger active" : "nav-trigger"}
          onClick={() => setResponsive(!responsive)}
        >
          <i></i>
          <i></i>
          <i></i>
        </span>
      </div>
    </nav>
  );
};
