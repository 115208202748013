type props = {
  images: string[];
  onClick: Function;
};

export const ImageGallery: React.FunctionComponent<props> = ({
  images,
  onClick,
}) => {
  return (
    <div className="image-gallery-container">
      {images?.map((src, i) => (
        <div key={src + i} className="image-gallery-image">
          <img
            className="w-full vertical-align-middle image-scalar pointer"
            src={src}
            alt=""
            onClick={() => onClick(src)}
          />
        </div>
      ))}
    </div>
  );
};
