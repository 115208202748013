import { ContentfulCareer } from "../api/types/contentful-content";

type props = {
  career?: ContentfulCareer;
};

export const CareerTile: React.FunctionComponent<props> = ({ career }) => {
  return (
    <div className="w-full justify-self-center">
      {career?.title && (
        <h1 className="theme-text-color-3 text-3xl mb-1 px-5 default-px-10 text-left">
          {career.title}
        </h1>
      )}
      {career?.title && career?.descriptions && (
        <ul className="px-5 default-px-10 list-style-inside">
          {career?.descriptions.map((description, i) => (
            <li
              key={i}
              className="theme-text-color-3 text-xl default-my-2 my-4 text-left"
            >
              {description}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
