import { useEffect, useState } from "react";
import { useApi } from "../../api";
import { TESTIMONIALS_PAGE } from "../../api/contentful";
import {
  CONTENTFUL_GET_ENTRY_RESPONSE,
  ContentfulContent,
} from "../../api/types/contentful-content";
import { CounterTextBox } from "../../components/counter-textbox";
import { CustomHeader } from "../../components/custom-header";
import { CustomText } from "../../components/custom-text";
import { TestimonialTile } from "../../components/testimonial-tile";

export const Testimonials = () => {
  const api = useApi();
  const [contentfulContent, setContentfulContent] =
    useState<ContentfulContent>();

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.contentful.getEntry(TESTIMONIALS_PAGE);
      if (response.type !== CONTENTFUL_GET_ENTRY_RESPONSE) return null;
      setContentfulContent(response.data.fields);
      return response.data.fields;
    };
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const calculateDaysOpen = () => {
    var today = new Date();
    var openingDate = new Date("01/28/2023");
    var daysSinceOpening = getNumberOfDaysExcludingTuesdays(openingDate, today);
    return daysSinceOpening.toString();
  };

  const getNumberOfDaysExcludingTuesdays = (openingDate: Date, today: Date) => {
    let count = 0;
    const currentDate = new Date(openingDate.getTime());
    while (currentDate <= today) {
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek !== 2) count++;
      currentDate.setDate(currentDate.getDate() + 1);
    }

    if (
      contentfulContent?.daysAdjuster !== null &&
      contentfulContent?.daysAdjuster !== undefined
    ) {
      return count + contentfulContent.daysAdjuster;
    } else {
      return count;
    }
  };

  return (
    <div className="page-container flex flex-col">
      <div className="grid grid-cols-1">
        {contentfulContent?.header1 && (
          <CustomHeader
            className="theme-text-color-3 theme-font text-5xl mb-1 px-5 default-px-10"
            contentfulCustomText={contentfulContent.header1}
          />
        )}
        {contentfulContent?.description1 && (
          <CustomText
            className="theme-text-color-3 text-2xl px-5 mb-0 default-px-10"
            contentfulCustomText={contentfulContent.description1}
          />
        )}

        <CounterTextBox header="Days Open" counter={calculateDaysOpen()} />
        {contentfulContent?.descriptionText2 && (
          <CounterTextBox
            header="Cats Adopted"
            counter={contentfulContent?.descriptionText2}
          />
        )}
      </div>
      <div className="grid grid-cols-1">
        {contentfulContent?.testimonial1 && (
          <TestimonialTile testimonial={contentfulContent.testimonial1} />
        )}
        {contentfulContent?.testimonial2 && (
          <TestimonialTile testimonial={contentfulContent.testimonial2} />
        )}
        {contentfulContent?.testimonial3 && (
          <TestimonialTile testimonial={contentfulContent.testimonial3} />
        )}
        {contentfulContent?.testimonial4 && (
          <TestimonialTile testimonial={contentfulContent.testimonial4} />
        )}
        {contentfulContent?.testimonial5 && (
          <TestimonialTile testimonial={contentfulContent.testimonial5} />
        )}
        {contentfulContent?.testimonial6 && (
          <TestimonialTile testimonial={contentfulContent.testimonial6} />
        )}
        {contentfulContent?.testimonial7 && (
          <TestimonialTile testimonial={contentfulContent.testimonial7} />
        )}
        {contentfulContent?.testimonial8 && (
          <TestimonialTile testimonial={contentfulContent.testimonial8} />
        )}
        {contentfulContent?.testimonial9 && (
          <TestimonialTile testimonial={contentfulContent.testimonial9} />
        )}
        {contentfulContent?.testimonial10 && (
          <TestimonialTile testimonial={contentfulContent.testimonial10} />
        )}
      </div>
    </div>
  );
};
