import { useEffect, useState } from "react";
import { useApi } from "../../api";
import { OUR_CATS_PAGE } from "../../api/contentful";
import {
  CONTENTFUL_GET_ENTRY_RESPONSE,
  ContentfulContent,
  ContentfulOurCatsCat,
} from "../../api/types/contentful-content";
import { CatViewer } from "../../components/cat-viewer";
import { OurCatsCatTile } from "../../components/our-cats-cat-tile";

export const OurCats = () => {
  const api = useApi();
  const [contentfulContent, setContentfulContent] =
    useState<ContentfulContent>();
  const [focusedCat, setFocusedCat] = useState<ContentfulOurCatsCat>();
  const [slideshowIndex, setSlideshowIndex] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.contentful.getEntry(OUR_CATS_PAGE);
      if (response.type !== CONTENTFUL_GET_ENTRY_RESPONSE) return null;
      setContentfulContent(response.data.fields);
      return response.data.fields;
    };
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (focusedCat !== undefined) {
      const timer = setTimeout(() => {
        if (focusedCat.images.length - 1 > slideshowIndex) {
          setSlideshowIndex(slideshowIndex + 1);
        } else {
          setSlideshowIndex(0);
        }
      }, 3000);
      return () => clearTimeout(timer);
    }
  });

  const openCatViewer = (cat: ContentfulOurCatsCat) => {
    document.body.style.overflow = "hidden";
    setFocusedCat(cat);
  };

  const closeCatViewer = () => {
    document.body.style.overflow = "unset";
    setFocusedCat(undefined);
    setSlideshowIndex(0);
  };

  const changeImage = (index: number) => {
    if (focusedCat?.images && focusedCat.images.length - 1 > index) {
      setSlideshowIndex(index + 1);
    } else {
      setSlideshowIndex(0);
    }
  };

  return (
    <div className="page-container flex flex-col">
      {focusedCat && (
        <CatViewer
          cat={focusedCat}
          index={slideshowIndex}
          changeImage={(index: number) => changeImage(index)}
          onClick={() => closeCatViewer()}
        />
      )}
      <div className="grid grid-cols-1">
        {contentfulContent?.image1 && (
          <img className="w-full" src={contentfulContent.image1} alt="" />
        )}
        {contentfulContent?.headerText1 && (
          <h1 className="theme-text-color-3 theme-font text-5xl mb-1 px-5 text-center default-px-10">
            {contentfulContent.headerText1}
          </h1>
        )}
        {contentfulContent?.descriptionText1 && (
          <p className="theme-text-color-3 text-2xl px-5 default-px-10 mb-0 break-word">
            {contentfulContent.descriptionText1}
          </p>
        )}
        {contentfulContent?.descriptionText2 && (
          <p className="theme-text-color-3 text-2xl px-5 default-px-10 break-word">
            {contentfulContent.descriptionText2}
          </p>
        )}
        {/* <a
          className="theme-text-color-3 text-2xl px-5 pb-5 default-px-10 break-word"
          href="/apply-here"
        >
          Apply For Adoption
        </a> */}
        {/* <a
          className="theme-text-color-3 text-2xl italic px-5 default-px-10 break-word"
          href="https://adoptionapplication.petpoint.com/?shelterId=372"
        >Apply Here</a> */}
        {/* <a
        className="theme-text-color-3 text-2xl italic px-5 default-px-10 break-word"
        href="https://www.shelterluv.com/matchme/adopt/POMH/Cat"
      >Apply Here</a> */}
      </div>
      <div className="grid grid-cols-1 default-grid-cols-3 justify-items-center mt-3 mb-6">
        {contentfulContent?.cat1?.images && (
          <OurCatsCatTile
            cat={contentfulContent.cat1}
            onClick={() => openCatViewer(contentfulContent.cat1)}
          />
        )}
        {contentfulContent?.cat2?.images && (
          <OurCatsCatTile
            cat={contentfulContent.cat2}
            onClick={() => openCatViewer(contentfulContent.cat2)}
          />
        )}
        {contentfulContent?.cat3?.images && (
          <OurCatsCatTile
            cat={contentfulContent.cat3}
            onClick={() => openCatViewer(contentfulContent.cat3)}
          />
        )}
        {contentfulContent?.cat4?.images && (
          <OurCatsCatTile
            cat={contentfulContent.cat4}
            onClick={() => openCatViewer(contentfulContent.cat4)}
          />
        )}
        {contentfulContent?.cat5?.images && (
          <OurCatsCatTile
            cat={contentfulContent.cat5}
            onClick={() => openCatViewer(contentfulContent.cat5)}
          />
        )}
        {contentfulContent?.cat6?.images && (
          <OurCatsCatTile
            cat={contentfulContent.cat6}
            onClick={() => openCatViewer(contentfulContent.cat6)}
          />
        )}
        {contentfulContent?.cat7?.images && (
          <OurCatsCatTile
            cat={contentfulContent.cat7}
            onClick={() => openCatViewer(contentfulContent.cat7)}
          />
        )}
        {contentfulContent?.cat8?.images && (
          <OurCatsCatTile
            cat={contentfulContent.cat8}
            onClick={() => openCatViewer(contentfulContent.cat8)}
          />
        )}
        {contentfulContent?.cat9?.images && (
          <OurCatsCatTile
            cat={contentfulContent.cat9}
            onClick={() => openCatViewer(contentfulContent.cat9)}
          />
        )}
        {contentfulContent?.cat10?.images && (
          <OurCatsCatTile
            cat={contentfulContent.cat10}
            onClick={() => openCatViewer(contentfulContent.cat10)}
          />
        )}
        {contentfulContent?.cat11?.images && (
          <OurCatsCatTile
            cat={contentfulContent.cat11}
            onClick={() => openCatViewer(contentfulContent.cat11)}
          />
        )}
        {contentfulContent?.cat12?.images && (
          <OurCatsCatTile
            cat={contentfulContent.cat12}
            onClick={() => openCatViewer(contentfulContent.cat12)}
          />
        )}
        {contentfulContent?.cat13?.images && (
          <OurCatsCatTile
            cat={contentfulContent.cat13}
            onClick={() => openCatViewer(contentfulContent.cat13)}
          />
        )}
        {contentfulContent?.cat14?.images && (
          <OurCatsCatTile
            cat={contentfulContent.cat14}
            onClick={() => openCatViewer(contentfulContent.cat14)}
          />
        )}
        {contentfulContent?.cat15?.images && (
          <OurCatsCatTile
            cat={contentfulContent.cat15}
            onClick={() => openCatViewer(contentfulContent.cat15)}
          />
        )}
        {contentfulContent?.cat16?.images && (
          <OurCatsCatTile
            cat={contentfulContent.cat16}
            onClick={() => openCatViewer(contentfulContent.cat16)}
          />
        )}
        {contentfulContent?.cat17?.images && (
          <OurCatsCatTile
            cat={contentfulContent.cat17}
            onClick={() => openCatViewer(contentfulContent.cat17)}
          />
        )}
        {contentfulContent?.cat18?.images && (
          <OurCatsCatTile
            cat={contentfulContent.cat18}
            onClick={() => openCatViewer(contentfulContent.cat18)}
          />
        )}
        {contentfulContent?.cat19?.images && (
          <OurCatsCatTile
            cat={contentfulContent.cat19}
            onClick={() => openCatViewer(contentfulContent.cat19)}
          />
        )}
        {contentfulContent?.cat20?.images && (
          <OurCatsCatTile
            cat={contentfulContent.cat20}
            onClick={() => openCatViewer(contentfulContent.cat20)}
          />
        )}
        {contentfulContent?.cat21?.images && (
          <OurCatsCatTile
            cat={contentfulContent.cat21}
            onClick={() => openCatViewer(contentfulContent.cat21)}
          />
        )}
        {contentfulContent?.cat22?.images && (
          <OurCatsCatTile
            cat={contentfulContent.cat22}
            onClick={() => openCatViewer(contentfulContent.cat22)}
          />
        )}
        {contentfulContent?.cat23?.images && (
          <OurCatsCatTile
            cat={contentfulContent.cat23}
            onClick={() => openCatViewer(contentfulContent.cat23)}
          />
        )}
        {contentfulContent?.cat24?.images && (
          <OurCatsCatTile
            cat={contentfulContent.cat24}
            onClick={() => openCatViewer(contentfulContent.cat24)}
          />
        )}
        {contentfulContent?.cat25?.images && (
          <OurCatsCatTile
            cat={contentfulContent.cat25}
            onClick={() => openCatViewer(contentfulContent.cat25)}
          />
        )}
        {contentfulContent?.cat26?.images && (
          <OurCatsCatTile
            cat={contentfulContent.cat26}
            onClick={() => openCatViewer(contentfulContent.cat26)}
          />
        )}
        {contentfulContent?.cat27?.images && (
          <OurCatsCatTile
            cat={contentfulContent.cat27}
            onClick={() => openCatViewer(contentfulContent.cat27)}
          />
        )}
        {contentfulContent?.cat28?.images && (
          <OurCatsCatTile
            cat={contentfulContent.cat28}
            onClick={() => openCatViewer(contentfulContent.cat28)}
          />
        )}
        {contentfulContent?.cat29?.images && (
          <OurCatsCatTile
            cat={contentfulContent.cat29}
            onClick={() => openCatViewer(contentfulContent.cat29)}
          />
        )}
        {contentfulContent?.cat30?.images && (
          <OurCatsCatTile
            cat={contentfulContent.cat30}
            onClick={() => openCatViewer(contentfulContent.cat30)}
          />
        )}
      </div>
    </div>
  );
};
