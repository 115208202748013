import { ContentfulOurCatsCat } from "../api/types/contentful-content";

type props = {
  cat?: ContentfulOurCatsCat;
  onClick: Function;
};

export const OurCatsCatTile: React.FunctionComponent<props> = ({
  cat,
  onClick,
}) => {
  return (
    <div className="our-cats-tile-container">
      {cat?.images && (
        <img className="our-cats-tile-image" src={cat.images[0]} alt="" />
      )}
      {cat?.name && (
        <h1
          className={
            cat?.gender.toLowerCase() === "male"
              ? "theme-text-color-boy theme-font text-3xl default-text-5xl mb-1 px-5 default-px-10 text-center mt-2"
              : cat?.gender.toLowerCase() === "female"
              ? "theme-text-color-girl theme-font text-3xl default-text-5xl mb-1 px-5 default-px-10 text-center mt-2"
              : "theme-text-color-4 theme-font text-3xl default-text-5xl mb-1 px-5 default-px-10 text-center mt-2"
          }
        >
          {cat.name}
        </h1>
      )}
      {cat?.age && cat?.gender && (
        <p className="text-white text-1xl default-text-2xl mt-2 px-5 text-center">
          {cat.age} old{" "}
          <b
            className={
              cat?.gender.toLowerCase() === "male"
                ? "theme-text-color-boy"
                : cat?.gender.toLowerCase() === "female"
                ? "theme-text-color-girl"
                : ""
            }
          >
            {cat.gender}
          </b>
        </p>
      )}
      {cat?.information && (
        <p className="text-white text-1xl default-text-2xl my-2 px-5 text-center">
          {cat.information}
        </p>
      )}
      {cat?.name && cat?.description && (
        <p
          className={
            cat?.gender.toLowerCase() === "male"
              ? "theme-text-color-boy text-1xl hover-opacity pointer mx-1/4 default-mx-1/3 text-center"
              : cat?.gender.toLowerCase() === "female"
              ? "theme-text-color-girl text-1xl hover-opacity pointer mx-1/4 default-mx-1/3 text-center"
              : "text-1xl hover-opacity pointer mx-1/4 default-mx-1/3 text-center"
          }
          onClick={() => onClick(cat)}
        >
          Learn More
        </p>
      )}
      {cat?.url && (
        <a className="theme-text-color-4 text-1xl default-text-2xl underline my-2 px-5 text-center" href={cat.url}>
          Click here to apply for me!
        </a>
      )}
    </div>
  );
};
