import { useEffect, useState } from "react";
import { useApi } from "../../api";
import { FAQ_PAGE } from "../../api/contentful";
import {
  CONTENTFUL_GET_ENTRY_RESPONSE,
  ContentfulContent,
} from "../../api/types/contentful-content";

export const Faq = () => {
  const api = useApi();
  const [contentfulContent, setContentfulContent] =
    useState<ContentfulContent>();

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.contentful.getEntry(FAQ_PAGE);
      if (response.type !== CONTENTFUL_GET_ENTRY_RESPONSE) return null;
      setContentfulContent(response.data.fields);
      return response.data.fields;
    };
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="page-container flex flex-col">
      <div className="grid grid-cols-1">
        {contentfulContent?.image1 && (
          <img
            className="w-full default-w-2/3 default-mt-4 justify-self-center"
            src={contentfulContent.image1}
            alt=""
          />
        )}
      </div>
      <div className="grid grid-cols-1">
        {contentfulContent?.headerText1 && (
          <h1 className="theme-text-color-3 theme-font text-5xl mb-1 px-5 default-px-10 text-center">
            {contentfulContent.headerText1}
          </h1>
        )}
        {contentfulContent?.headerText2 && (
          <h1 className="theme-text-color-3 theme-font text-5xl mb-1 px-5 default-px-10 text-left">
            {contentfulContent.headerText2}
          </h1>
        )}
        {contentfulContent?.descriptionText1 && (
          <p className="theme-text-color-3 text-2xl my-1 px-5 default-px-10 text-left">
            {contentfulContent.descriptionText1}
          </p>
        )}
      </div>
      <div className="grid grid-cols-1">
        {contentfulContent?.headerText3 && (
          <h1 className="theme-text-color-3 theme-font text-5xl mb-1 px-5 default-px-10 text-left">
            {contentfulContent.headerText3}
          </h1>
        )}
        {contentfulContent?.descriptionText2 && (
          <p className="theme-text-color-3 text-2xl my-1 px-5 default-px-10 text-left">
            {contentfulContent.descriptionText2}
          </p>
        )}
      </div>
      <div className="grid grid-cols-1">
        {contentfulContent?.headerText4 && (
          <h1 className="theme-text-color-3 theme-font text-5xl mb-1 px-5 default-px-10 text-left">
            {contentfulContent.headerText4}
          </h1>
        )}
        {contentfulContent?.descriptionText3 && (
          <p className="theme-text-color-3 text-2xl my-1 px-5 default-px-10 text-left">
            {contentfulContent.descriptionText3}
          </p>
        )}
      </div>
      <div className="grid grid-cols-1">
        {contentfulContent?.headerText5 && (
          <h1 className="theme-text-color-3 theme-font text-5xl mb-1 px-5 default-px-10 text-left">
            {contentfulContent.headerText5}
          </h1>
        )}
        {contentfulContent?.descriptionText4 && (
          <p className="theme-text-color-3 text-2xl my-1 px-5 default-px-10 text-left">
            {contentfulContent.descriptionText4}
          </p>
        )}
      </div>
      <div className="grid grid-cols-1">
        {contentfulContent?.headerText6 && (
          <h1 className="theme-text-color-3 theme-font text-5xl mb-1 px-5 default-px-10 text-left">
            {contentfulContent.headerText6}
          </h1>
        )}
        {contentfulContent?.descriptionText5 && (
          <p className="theme-text-color-3 text-2xl my-1 px-5 default-px-10 text-left">
            {contentfulContent.descriptionText5}
          </p>
        )}
      </div>
      <div className="grid grid-cols-1">
        {contentfulContent?.headerText7 && (
          <h1 className="theme-text-color-3 theme-font text-5xl mb-1 px-5 default-px-10 text-left">
            {contentfulContent.headerText7}
          </h1>
        )}
        {contentfulContent?.descriptionText6 && (
          <p className="theme-text-color-3 text-2xl my-1 px-5 default-px-10 text-left">
            {contentfulContent.descriptionText6}
          </p>
        )}
      </div>
      <div className="grid grid-cols-1">
        {contentfulContent?.headerText8 && (
          <h1 className="theme-text-color-3 theme-font text-5xl mb-1 px-5 default-px-10 text-left">
            {contentfulContent.headerText8}
          </h1>
        )}
        {contentfulContent?.descriptionText7 && (
          <p className="theme-text-color-3 text-2xl mt-1 mb-4 px-5 default-px-10 text-left">
            {contentfulContent.descriptionText7}
          </p>
        )}
      </div>
    </div>
  );
};
