import { useEffect, useState } from "react";
import { useApi } from "../../api";
import emailjs from "@emailjs/browser";
import Configuration from "../../configuration.json";
import { APPLY_PAGE } from "../../api/contentful";
import {
  CONTENTFUL_GET_ENTRY_RESPONSE,
  ContentfulContent,
} from "../../api/types/contentful-content";
import { EmailJsApplicationRequest } from "../../api/types/emailjs-email";
import { CustomHeader } from "../../components/custom-header";
import { CustomText } from "../../components/custom-text";
import {
  ApplyForAdoptionApplicationFormData,
  ApplyForAdoptionApplicationFormDataErrors,
  ApplyForAdoptionPastPetsFormDataPet,
  ApplyForAdoptionPastVetsFormDataVet,
  ApplyForAdoptionPetsFormDataPet,
} from "../../api/types/apply-for-adoption-form-data";
import { AdoptionApplicationForm } from "../../components/adoption-application-form";
import { UnitedStates } from "../../lib/validation-helpers";

export const Apply = () => {
  const templateId = Configuration.EMAILJS_APPLICATION_TEMPLATEID;
  const api = useApi();
  const [contentfulContent, setContentfulContent] =
    useState<ContentfulContent>();
  const [
    applyForAdoptionApplicationFormData,
    setApplyForAdoptionApplicationFormData,
  ] = useState<ApplyForAdoptionApplicationFormData>({
    catNames: "",
    name: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    email: "",
    phoneNumber: "",
    married: "",
    numberOfChildren: "",
    childrenAges: "",
    ownOrRent: "",
    acreage: "",
    howLongAtPresentAddress: "",
    landlordName: "",
    landlordPhoneNumber: "",
    placeOfEmployment: "",
    howLongAtEmployment: "",
    businessPhoneNumber: "",
    veterinarianName: "",
    veterinarianPhoneNumber: "",
    overAge55: "",
    reasonsForAdoption: new Array<string>(),
    planToAdopt: "",
    primaryCaretaker: "",
    isAnyoneAlergic: "",
    areThereOtherPets: "",
    listOfPets: { pets: new Array<ApplyForAdoptionPetsFormDataPet>() },
    ownedOtherPetsRecently: "",
    pastPets: { pastPets: new Array<ApplyForAdoptionPastPetsFormDataPet>() },
    givenUpOnPet: "",
    givenUpOnPetWhy: "",
    haveYouUsedTheVetWithTheseAnimals: "",
    listPreviousVets: {
      pastVets: new Array<ApplyForAdoptionPastVetsFormDataVet>(),
    },
    catWillLiveLocation: "",
    petDoor: "",
    animalConfined: "",
    willYouDeclawCat: "",
    longTermCommitment: "",
    provisions: "",
    awareOfCost: "",
    willingToHandleCost: "",
    timeWithCat: "",
    animalAbuse: "",
    animalAbuseWhy: "",
    familyAnimalAbuse: "",
    familyAnimalAbuseWhy: "",
    additionalComments: "",
    howDidYouHear: new Array<string>(),
    signature: "",
    driverLicense: "",
    dateOfBirth: "",
  });
  const [
    applyForAdoptionApplicationFormDataErrors,
    setApplyForAdoptionApplicationFormDataErrors,
  ] = useState<ApplyForAdoptionApplicationFormDataErrors>({});
  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.contentful.getEntry(APPLY_PAGE);
      if (response.type !== CONTENTFUL_GET_ENTRY_RESPONSE) return null;
      setContentfulContent(response.data.fields);
      return response.data.fields;
    };
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const submitForm = () => {
    var formDataIsValid = validateApplyFormData();
    if (!formDataIsValid || !templateId) return;
    const message = buildApplyApplicationMessage();
    setLoading(true);
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICEID ?? "",
        templateId,
        message,
        process.env.REACT_APP_EMAILJS_USERID
      )
      .then(
        () => {
          setLoading(false);
          setFormSubmitted(true);
        },
        () => {
          setLoading(false);
          setFormError(true);
        }
      );
  };

  const getDate = () => {
    let today: any = new Date();
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1;
    let yyyy: any = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    today = mm + "/" + dd + "/" + yyyy;
    return today;
  };

  const buildApplyApplicationMessage = (): EmailJsApplicationRequest => {
    const emailJsRequest: EmailJsApplicationRequest = {
      applicationCatNames: applyForAdoptionApplicationFormData.catNames,
      applicationName: applyForAdoptionApplicationFormData.name,
      applicationAddress: applyForAdoptionApplicationFormData.address,
      applicationCity: applyForAdoptionApplicationFormData.city,
      applicationState: applyForAdoptionApplicationFormData.state,
      applicationZipCode: applyForAdoptionApplicationFormData.zipCode,
      applicationEmail: applyForAdoptionApplicationFormData.email,
      applicationPhoneNumber: applyForAdoptionApplicationFormData.phoneNumber,
      applicationMarried: applyForAdoptionApplicationFormData.married,
      applicationNumberOfChildren:
        applyForAdoptionApplicationFormData.numberOfChildren,
      applicationChildrenAges:
        applyForAdoptionApplicationFormData?.childrenAges,
      applicationOwnOrRent: applyForAdoptionApplicationFormData.ownOrRent,
      applicationAcreage: applyForAdoptionApplicationFormData.acreage,
      applicationHowLongAtPresentAddress:
        applyForAdoptionApplicationFormData.howLongAtPresentAddress,
      applicationLandlordName:
        applyForAdoptionApplicationFormData?.landlordName,
      applicationLandlordPhoneNumber:
        applyForAdoptionApplicationFormData?.landlordPhoneNumber,
      applicationPlaceOfEmployment:
        applyForAdoptionApplicationFormData.placeOfEmployment,
      applicationHowLongAtEmployment:
        applyForAdoptionApplicationFormData.howLongAtEmployment,
      applicationBusinessPhoneNumber:
        applyForAdoptionApplicationFormData?.businessPhoneNumber,
      applicationVeterinarianName:
        applyForAdoptionApplicationFormData.veterinarianName,
      applicationVeterinarianPhoneNumber:
        applyForAdoptionApplicationFormData.veterinarianPhoneNumber,
      applicationOverAge55: applyForAdoptionApplicationFormData.overAge55,
      applicationReasonsForAdoption: getReasonsForAdoptionAsString(),
      applicationPlanToAdopt: applyForAdoptionApplicationFormData.planToAdopt,
      applicationPrimaryCaretaker:
        applyForAdoptionApplicationFormData.primaryCaretaker,
      applicationIsAnyoneAlergic:
        applyForAdoptionApplicationFormData.isAnyoneAlergic,
      applicationAreThereOtherPets:
        applyForAdoptionApplicationFormData.areThereOtherPets,
      applicationListOfPets: applyForAdoptionPetsFormDataAsString(),
      applicationOwnedOtherPetsRecently:
        applyForAdoptionApplicationFormData.ownedOtherPetsRecently,
      applicationPastPets: applyForAdoptionPastPetsFormDataAsString(),
      applicationGivenUpOnPet: applyForAdoptionApplicationFormData.givenUpOnPet,
      applicationGivenUpOnPetWhy:
        applyForAdoptionApplicationFormData?.givenUpOnPetWhy,
      applicationHaveYouUsedTheVetWithTheseAnimals:
        applyForAdoptionApplicationFormData.haveYouUsedTheVetWithTheseAnimals,
      applicationListPreviousVets: applyForAdoptionPastVetsFormDataAsString(),
      applicationCatWillLiveLocation:
        applyForAdoptionApplicationFormData.catWillLiveLocation,
      applicationPetDoor: applyForAdoptionApplicationFormData.petDoor,
      applicationaAnimalConfined:
        applyForAdoptionApplicationFormData.animalConfined,
      applicationWillYouDeclawCat:
        applyForAdoptionApplicationFormData.willYouDeclawCat,
      applicationLongTermCommitment:
        applyForAdoptionApplicationFormData.longTermCommitment,
      applicationProvisions: applyForAdoptionApplicationFormData.provisions,
      applicationAwareOfCost: applyForAdoptionApplicationFormData.awareOfCost,
      applicationWillingToHandleCost:
        applyForAdoptionApplicationFormData.willingToHandleCost,
      applicationTimeWithCat: applyForAdoptionApplicationFormData.timeWithCat,
      applicationAnimalAbuse: applyForAdoptionApplicationFormData.animalAbuse,
      applicationAnimalAbuseWhy:
        applyForAdoptionApplicationFormData?.animalAbuseWhy,
      applicationFamilyAnimalAbuse:
        applyForAdoptionApplicationFormData.familyAnimalAbuse,
      applicationFamilyAnimalAbuseWhy:
        applyForAdoptionApplicationFormData?.familyAnimalAbuseWhy,
      applicationAdditionalComments:
        applyForAdoptionApplicationFormData?.additionalComments,
      applicationHowDidYouHear: getHowDidYouHearAsString(),
      applicationSignature: applyForAdoptionApplicationFormData.signature,
      applicationDate: getDate(),
      applicationDriverLicense:
        applyForAdoptionApplicationFormData.driverLicense,
      applicationDateOfBirth: applyForAdoptionApplicationFormData.dateOfBirth,
    };
    return emailJsRequest;
  };

  const validateApplyFormData = (): boolean => {
    var valid = true;
    var emailValidationRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (
      !applyForAdoptionApplicationFormData.catNames ||
      applyForAdoptionApplicationFormData.catNames.trim().length < 3
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          catNamesError: "Please enter cat name(s).",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          catNamesError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.name ||
      applyForAdoptionApplicationFormData.name.trim().length < 3
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          nameError: "Please enter a name.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          nameError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.address ||
      applyForAdoptionApplicationFormData.address.trim().length < 3
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          addressError: "Please enter an address.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          addressError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.city ||
      applyForAdoptionApplicationFormData.city.trim().length < 3
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          cityError: "Please enter a city.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          cityError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.state ||
      applyForAdoptionApplicationFormData.state.trim().length !== 2
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          stateError: "Please enter a state.",
        })
      );
      valid = false;
    } else if (
      !UnitedStates.includes(applyForAdoptionApplicationFormData.state)
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          stateError: "Please enter a United State code (ex. NY).",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          stateError: undefined,
        })
      );
    }

    if (!applyForAdoptionApplicationFormData.zipCode) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          zipCodeError: "Please enter a zip code.",
        })
      );
      valid = false;
    } else if (
      applyForAdoptionApplicationFormData.zipCode.trim().length !== 5
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          zipCodeError: "Please enter a 5 digit zip code.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          zipCodeError: undefined,
        })
      );
    }

    if (!applyForAdoptionApplicationFormData.email) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          emailError: "Please enter an email.",
        })
      );
      valid = false;
    } else if (
      !emailValidationRegex.test(applyForAdoptionApplicationFormData.email)
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          emailError: "Please enter a real email.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          emailError: undefined,
        })
      );
    }

    if (!applyForAdoptionApplicationFormData.phoneNumber) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          phoneNumberError: "Please enter a phone number.",
        })
      );
      valid = false;
    } else if (
      applyForAdoptionApplicationFormData.phoneNumber.trim().length !== 14
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          phoneNumberError:
            "Please enter a 10 digit phone number (XXX) XXX-XXXX.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          phoneNumberError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.married ||
      applyForAdoptionApplicationFormData.married.trim().length === 0
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          marriedError: "Please select an answer.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          marriedError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.ownOrRent ||
      applyForAdoptionApplicationFormData.ownOrRent.trim().length === 0
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          ownOrRentError: "Please select an answer.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          ownOrRentError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.acreage ||
      applyForAdoptionApplicationFormData.acreage.trim().length === 0
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          acreageError: "Please enter acreage.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          acreageError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.howLongAtPresentAddress ||
      applyForAdoptionApplicationFormData.howLongAtPresentAddress.trim()
        .length === 0
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          howLongAtPresentAddressError: "Please enter an answer.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          howLongAtPresentAddressError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.placeOfEmployment ||
      applyForAdoptionApplicationFormData.placeOfEmployment.trim().length === 0
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          placeOfEmploymentError: "Please enter employment.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          placeOfEmploymentError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.howLongAtEmployment ||
      applyForAdoptionApplicationFormData.howLongAtEmployment.trim().length ===
        0
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          howLongAtEmploymentError: "Please enter an answer.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          howLongAtEmploymentError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.veterinarianName ||
      applyForAdoptionApplicationFormData.veterinarianName.trim().length === 0
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          veterinarianNameError: "Please enter veterinarian name.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          veterinarianNameError: undefined,
        })
      );
    }

    if (!applyForAdoptionApplicationFormData.veterinarianPhoneNumber) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          veterinarianPhoneNumberError: "Please enter a phone number.",
        })
      );
      valid = false;
    } else if (
      applyForAdoptionApplicationFormData.veterinarianPhoneNumber.trim()
        .length !== 14
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          veterinarianPhoneNumberError:
            "Please enter a 10 digit phone number (XXX) XXX-XXXX.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          veterinarianPhoneNumberError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.overAge55 ||
      applyForAdoptionApplicationFormData.overAge55.trim().length === 0
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          overAge55Error: "Please enter select an answer.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          overAge55: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.reasonsForAdoption ||
      applyForAdoptionApplicationFormData.reasonsForAdoption.length === 0
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          reasonsForAdoptionError: "Please select an answer.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          reasonsForAdoptionError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.planToAdopt ||
      applyForAdoptionApplicationFormData.planToAdopt.trim().length === 0
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          planToAdoptError: "Please enter an answer.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          planToAdoptError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.primaryCaretaker ||
      applyForAdoptionApplicationFormData.primaryCaretaker.trim().length === 0
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          primaryCaretakerError: "Please enter an answer.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          primaryCaretakerError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.isAnyoneAlergic ||
      applyForAdoptionApplicationFormData.isAnyoneAlergic.trim().length === 0
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          isAnyoneAlergicError: "Please select an answer.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          isAnyoneAlergicError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.areThereOtherPets ||
      applyForAdoptionApplicationFormData.areThereOtherPets.trim().length === 0
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          areThereOtherPetsError: "Please select an answer.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          areThereOtherPetsError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.ownedOtherPetsRecently ||
      applyForAdoptionApplicationFormData.ownedOtherPetsRecently.trim()
        .length === 0
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          ownedOtherPetsRecentlyError: "Please select an answer.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          ownedOtherPetsRecentlyError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.givenUpOnPet ||
      applyForAdoptionApplicationFormData.givenUpOnPet.trim().length === 0
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          givenUpOnPetError: "Please select an answer.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          givenUpOnPetError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.haveYouUsedTheVetWithTheseAnimals ||
      applyForAdoptionApplicationFormData.haveYouUsedTheVetWithTheseAnimals.trim()
        .length === 0
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          haveYouUsedTheVetWithTheseAnimalsError: "Please select an answer.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          haveYouUsedTheVetWithTheseAnimalsError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.catWillLiveLocation ||
      applyForAdoptionApplicationFormData.catWillLiveLocation.trim().length ===
        0
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          catWillLiveLocationError: "Please select an answer.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          catWillLiveLocationError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.animalConfined ||
      applyForAdoptionApplicationFormData.animalConfined.trim().length === 0
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          animalConfinedError: "Please enter an answer.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          animalConfinedError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.petDoor ||
      applyForAdoptionApplicationFormData.petDoor.trim().length === 0
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          petDoorError: "Please select an answer.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          petDoorError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.willYouDeclawCat ||
      applyForAdoptionApplicationFormData.willYouDeclawCat.trim().length === 0
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          willYouDeclawCatError: "Please select an answer.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          willYouDeclawCatError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.longTermCommitment ||
      applyForAdoptionApplicationFormData.longTermCommitment.trim().length === 0
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          longTermCommitmentError: "Please select an answer.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          longTermCommitmentError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.provisions ||
      applyForAdoptionApplicationFormData.provisions.trim().length === 0
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          provisionsError: "Please select an answer.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          provisionsError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.awareOfCost ||
      applyForAdoptionApplicationFormData.awareOfCost.trim().length === 0
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          awareOfCostError: "Please select an answer.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          awareOfCostError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.willingToHandleCost ||
      applyForAdoptionApplicationFormData.willingToHandleCost.trim().length ===
        0
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          willingToHandleCostError: "Please select an answer.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          willingToHandleCostError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.timeWithCat ||
      applyForAdoptionApplicationFormData.timeWithCat.trim().length === 0
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          timeWithCatError: "Please select an answer.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          timeWithCatError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.animalAbuse ||
      applyForAdoptionApplicationFormData.animalAbuse.trim().length === 0
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          animalAbuseError: "Please select an answer.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          animalAbuseError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.familyAnimalAbuse ||
      applyForAdoptionApplicationFormData.familyAnimalAbuse.trim().length === 0
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          familyAnimalAbuseError: "Please select an answer.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          familyAnimalAbuseError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.howDidYouHear ||
      applyForAdoptionApplicationFormData.howDidYouHear.length === 0
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          howDidYouHearError: "Please select an answer.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          howDidYouHearError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.signature ||
      applyForAdoptionApplicationFormData.signature.trim().length === 0
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          signatureError: "Please enter a signature.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          signatureError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.driverLicense ||
      applyForAdoptionApplicationFormData.driverLicense.trim().length === 0
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          driverLicenseError: "Please enter a driver license.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          driverLicenseError: undefined,
        })
      );
    }

    if (
      !applyForAdoptionApplicationFormData.dateOfBirth ||
      applyForAdoptionApplicationFormData.dateOfBirth.trim().length === 0
    ) {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          dateOfBirthError: "Please enter a Date Of Birth.",
        })
      );
      valid = false;
    } else {
      setApplyForAdoptionApplicationFormDataErrors(
        (applyForAdoptionApplicationFormDataErrors) => ({
          ...applyForAdoptionApplicationFormDataErrors,
          dateOfBirthError: undefined,
        })
      );
    }

    return valid;
  };

  const applyForAdoptionPetsFormDataAsString = (): string => {
    let str = "";
    if (applyForAdoptionApplicationFormData.listOfPets) {
      applyForAdoptionApplicationFormData.listOfPets.pets?.map((pet, i) => {
        str += `Pet ${i + 1}). ${pet.name} - ${pet.typeOfPet} - ${pet.sex} - ${pet.age} - ${pet.yearsOwned} - ${pet.dateOfVaccination} - ${pet.spayedOrNeutered} - ${pet.indoorsOrOutdoors}. `;
      });
    }
    return str;
  };

  const applyForAdoptionPastPetsFormDataAsString = (): string => {
    let str = "";
    if (applyForAdoptionApplicationFormData.pastPets) {
      applyForAdoptionApplicationFormData.pastPets.pastPets?.map((pet, i) => {
        str += `Pet ${i + 1}). ${pet.name} - ${pet.typeOfPet} - ${pet.age} - ${pet.yearsOwned} - ${pet.whatHappened}. `;
      });
    }
    return str;
  };

  const applyForAdoptionPastVetsFormDataAsString = (): string => {
    let str = "";
    if (applyForAdoptionApplicationFormData.listPreviousVets) {
      applyForAdoptionApplicationFormData.listPreviousVets.pastVets?.map(
        (vet, i) => {
          str += `Vet ${i + 1}). ${vet.animalName} - ${vet.veterinarian} - ${vet.phoneNumber}. `;
        }
      );
    }
    return str;
  };

  const getHowDidYouHearAsString = (): string => {
    let str = "";
    if (applyForAdoptionApplicationFormData.howDidYouHear) {
      applyForAdoptionApplicationFormData.howDidYouHear?.map((how, i) => {
        str += `Reason ${i + 1}). ${how} `;
      });
    }
    return str;
  };

  const getReasonsForAdoptionAsString = (): string => {
    let str = "";
    if (applyForAdoptionApplicationFormData.reasonsForAdoption) {
      applyForAdoptionApplicationFormData.reasonsForAdoption?.map((reason) => {
        str += `${reason} ::: `;
      });
    }
    return str;
  };

  return (
    <div className="page-container flex flex-col">
      <div className="grid grid-cols-1">
        {contentfulContent?.image1 && (
          <img
            className="w-full default-w-1/2 default-mt-4 justify-self-center"
            src={contentfulContent.image1}
            alt=""
          />
        )}
      </div>
      <div className="grid grid-cols-1">
        {contentfulContent?.header1 && (
          <CustomHeader
            className="theme-text-color-3 theme-font text-5xl mb-1 px-5 default-px-10"
            contentfulCustomText={contentfulContent.header1}
          />
        )}
        {contentfulContent?.description1 && !formSubmitted && (
          <CustomText
            className="theme-text-color-3 text-2xl px-5 mt-0 default-px-10"
            contentfulCustomText={contentfulContent.description1}
          />
        )}
        {formError && (
          <div className="grid grid-cols-1">
            <h1 className="theme-text-color-error theme-font text-5xl mb-0 px-5 default-px-10">
              Sorry!
            </h1>
            <p className="theme-text-color-error text-xl px-5 default-px-10">
              It appears something isn't working with our email service. Please
              try again later.
            </p>
          </div>
        )}
        {!formError && formSubmitted && (
          <div className="grid grid-cols-1">
            <h1 className="theme-text-color-3 theme-font text-5xl mb-0 px-5 default-px-10 text-center">
              THANK YOU!
            </h1>
            <p className="theme-text-color-3 text-xl px-5 default-px-10 text-center">
              A confirmation email has been sent to the email address you
              provided.
            </p>
          </div>
        )}
        {!formSubmitted && (
          <div>
            <AdoptionApplicationForm
              loading={loading}
              applyForAdoptionApplicationFormData={
                applyForAdoptionApplicationFormData
              }
              applyForAdoptionApplicationFormDataErrors={
                applyForAdoptionApplicationFormDataErrors
              }
              addPet={(currentPet: ApplyForAdoptionPetsFormDataPet) => {
                let oldArray = applyForAdoptionApplicationFormData.listOfPets;
                oldArray?.pets?.push(currentPet);
                setApplyForAdoptionApplicationFormData(
                  (applyForAdoptionApplicationFormData) => ({
                    ...applyForAdoptionApplicationFormData,
                    listOfPets: oldArray,
                  })
                );
              }}
              deletePet={(index: number) => {
                let oldArray = applyForAdoptionApplicationFormData.listOfPets;
                oldArray?.pets?.splice(index, 1);
                setApplyForAdoptionApplicationFormData(
                  (applyForAdoptionApplicationFormData) => ({
                    ...applyForAdoptionApplicationFormData,
                    listOfPets: oldArray,
                  })
                );
              }}
              addPastPet={(currentPet: ApplyForAdoptionPastPetsFormDataPet) => {
                let oldArray = applyForAdoptionApplicationFormData.pastPets;
                oldArray?.pastPets?.push(currentPet);
                setApplyForAdoptionApplicationFormData(
                  (applyForAdoptionApplicationFormData) => ({
                    ...applyForAdoptionApplicationFormData,
                    pastPets: oldArray,
                  })
                );
              }}
              deletePastPet={(index: number) => {
                let oldArray = applyForAdoptionApplicationFormData.pastPets;
                oldArray?.pastPets?.splice(index, 1);
                setApplyForAdoptionApplicationFormData(
                  (applyForAdoptionApplicationFormData) => ({
                    ...applyForAdoptionApplicationFormData,
                    pastPets: oldArray,
                  })
                );
              }}
              addPastVet={(currentVet: ApplyForAdoptionPastVetsFormDataVet) => {
                let oldArray =
                  applyForAdoptionApplicationFormData.listPreviousVets;
                oldArray?.pastVets?.push(currentVet);
                setApplyForAdoptionApplicationFormData(
                  (applyForAdoptionApplicationFormData) => ({
                    ...applyForAdoptionApplicationFormData,
                    listPreviousVets: oldArray,
                  })
                );
              }}
              deletePastVet={(index: number) => {
                let oldArray =
                  applyForAdoptionApplicationFormData.listPreviousVets;
                oldArray?.pastVets?.splice(index, 1);
                setApplyForAdoptionApplicationFormData(
                  (applyForAdoptionApplicationFormData) => ({
                    ...applyForAdoptionApplicationFormData,
                    listPreviousVets: oldArray,
                  })
                );
              }}
              onChange={(name: any, value: any) =>
                setApplyForAdoptionApplicationFormData(
                  (applyForAdoptionApplicationFormData) => ({
                    ...applyForAdoptionApplicationFormData,
                    [name]: value,
                  })
                )
              }
              onBlur={(name: any, value: any) =>
                setApplyForAdoptionApplicationFormData(
                  (applyForAdoptionApplicationFormData) => ({
                    ...applyForAdoptionApplicationFormData,
                    [name]: value,
                  })
                )
              }
              onChangeHowDidYouHear={(value: string) => {
                let oldArray =
                  applyForAdoptionApplicationFormData.howDidYouHear;
                if (oldArray?.includes(value)) {
                  let index = oldArray.indexOf(value);
                  oldArray.splice(index, 1);
                } else {
                  oldArray?.push(value);
                }
                setApplyForAdoptionApplicationFormData(
                  (applyForAdoptionApplicationFormData) => ({
                    ...applyForAdoptionApplicationFormData,
                    howDidYouHear: oldArray,
                  })
                );
              }}
              onChangeReasonsForAdoption={(value: string) => {
                let oldArray =
                  applyForAdoptionApplicationFormData.reasonsForAdoption;
                if (oldArray?.includes(value)) {
                  let index = oldArray.indexOf(value);
                  oldArray.splice(index, 1);
                } else {
                  oldArray?.push(value);
                }
                setApplyForAdoptionApplicationFormData(
                  (applyForAdoptionApplicationFormData) => ({
                    ...applyForAdoptionApplicationFormData,
                    reasonsForAdoption: oldArray,
                  })
                );
              }}
            />
            <button
              className="theme-3-btn theme-font-alt"
              onClick={() => submitForm()}
            >
              Submit
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
