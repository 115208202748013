import { useState } from "react";
import {
  ApplyForAdoptionPastPetsFormData,
  ApplyForAdoptionPastPetsFormDataPet,
  ApplyForAdoptionPastPetsFormDataPetErrors,
} from "../api/types/apply-for-adoption-form-data";
import { Input } from "./input";
import { RadioButton } from "./radio-button";

type props = {
  applyForAdoptionPastPetsFormData?: ApplyForAdoptionPastPetsFormData;
  addPastPet: Function;
  deletePastPet: Function;
};

export const ApplyForAdoptionPastPetsForm: React.FunctionComponent<props> = ({
  applyForAdoptionPastPetsFormData,
  addPastPet,
  deletePastPet,
}) => {
  const [currentPet, setCurrentPet] =
    useState<ApplyForAdoptionPastPetsFormDataPet>({
      name: "",
      typeOfPet: "",
      age: "",
      yearsOwned: "",
      whatHappened: "",
    });
  const [currentPetErrors, setCurrentPetErrors] =
    useState<ApplyForAdoptionPastPetsFormDataPetErrors>({});
  const [yesOrNo, setYesOrNo] = useState("Yes");

  const submitPet = () => {
    let valid = validatePet();
    if (valid) {
      addPastPet(currentPet);
      setCurrentPet({
        name: "",
        typeOfPet: "",
        age: "",
        yearsOwned: "",
        whatHappened: "",
      });
    }
  };

  const validatePet = (): boolean => {
    let valid = true;

    if (!currentPet.name || currentPet.name.trim().length === 0) {
      setCurrentPetErrors((currentPetErrors) => ({
        ...currentPetErrors,
        nameError: "Please enter a name.",
      }));
      valid = false;
    } else {
      setCurrentPetErrors((currentPetErrors) => ({
        ...currentPetErrors,
        nameError: undefined,
      }));
    }

    if (!currentPet.typeOfPet || currentPet.typeOfPet.trim().length === 0) {
      setCurrentPetErrors((currentPetErrors) => ({
        ...currentPetErrors,
        typeOfPetError: "Please enter a type of pet.",
      }));
      valid = false;
    } else {
      setCurrentPetErrors((currentPetErrors) => ({
        ...currentPetErrors,
        typeOfPetError: undefined,
      }));
    }

    if (!currentPet.age || currentPet.age.trim().length === 0) {
      setCurrentPetErrors((currentPetErrors) => ({
        ...currentPetErrors,
        ageError: "Please enter an age.",
      }));
      valid = false;
    } else {
      setCurrentPetErrors((currentPetErrors) => ({
        ...currentPetErrors,
        ageError: undefined,
      }));
    }

    if (!currentPet.yearsOwned || currentPet.yearsOwned.trim().length === 0) {
      setCurrentPetErrors((currentPetErrors) => ({
        ...currentPetErrors,
        yearsOwnedError: "Please enter years owned.",
      }));
      valid = false;
    } else {
      setCurrentPetErrors((currentPetErrors) => ({
        ...currentPetErrors,
        yearsOwnedError: undefined,
      }));
    }

    if (
      !currentPet.whatHappened ||
      currentPet.whatHappened.trim().length === 0
    ) {
      setCurrentPetErrors((currentPetErrors) => ({
        ...currentPetErrors,
        whatHappenedError: "Please enter an answer.",
      }));
      valid = false;
    } else {
      setCurrentPetErrors((currentPetErrors) => ({
        ...currentPetErrors,
        whatHappenedError: undefined,
      }));
    }

    return valid;
  };

  return (
    <div>
      <ul className="px-5 default-px-10 list-style-inside">
        {applyForAdoptionPastPetsFormData?.pastPets?.map((pet, i) => (
          <li
            key={i}
            className="theme-text-color-3 text-xl text-left list-style-none default-my-2 my-4"
          >
            <div className="grid grid-cols-2-8/10 mt-4">
              <p>
                Name: {pet.name} Type of Pet: {pet.typeOfPet}
                Age: {pet.age} Years Owned: {pet.yearsOwned} What Happened to
                Him/Her?: {pet.whatHappened}
              </p>
              <button
                className="theme-3-btn-small theme-font-alt mx-4"
                onClick={() => deletePastPet(i)}
              >
                X
              </button>
            </div>
          </li>
        ))}
      </ul>
      {yesOrNo === "Yes" && (
        <div className="grid grid-cols-1 default-grid-cols-2 mt-4">
          <Input
            containerClass="px-5 default-px-10 my-4 align-content-baseline"
            className="theme-3-input"
            label="Name"
            labelClass="theme-3-input-label"
            name="name"
            value={currentPet?.name}
            error={currentPetErrors?.nameError}
            onChange={(e) =>
              setCurrentPet((currentPet) => ({
                ...currentPet,
                name: e.target.value,
              }))
            }
            onBlur={() => {
              var output = currentPet?.name?.trim();
              setCurrentPet((currentPet) => ({
                ...currentPet,
                name: output,
              }));
            }}
          />
          <Input
            containerClass="px-5 default-px-10 my-4 align-content-baseline"
            className="theme-3-input"
            label="Type of Pet"
            labelClass="theme-3-input-label"
            name="typeOfPet"
            value={currentPet?.typeOfPet}
            error={currentPetErrors?.typeOfPetError}
            onChange={(e) =>
              setCurrentPet((currentPet) => ({
                ...currentPet,
                typeOfPet: e.target.value,
              }))
            }
            onBlur={() => {
              var output = currentPet?.typeOfPet?.trim();
              setCurrentPet((currentPet) => ({
                ...currentPet,
                typeOfPet: output,
              }));
            }}
          />
          <Input
            containerClass="px-5 default-px-10 my-4 align-content-baseline"
            className="theme-3-input"
            label="Age"
            labelClass="theme-3-input-label"
            name="age"
            value={currentPet?.age}
            error={currentPetErrors?.ageError}
            onChange={(e) =>
              setCurrentPet((currentPet) => ({
                ...currentPet,
                age: e.target.value,
              }))
            }
            onBlur={() => {
              var output = currentPet?.age?.trim();
              setCurrentPet((currentPet) => ({
                ...currentPet,
                age: output,
              }));
            }}
          />
          <Input
            containerClass="px-5 default-px-10 my-4 align-content-baseline"
            className="theme-3-input"
            label="Years Owned"
            labelClass="theme-3-input-label"
            name="yearsOwned"
            value={currentPet?.yearsOwned}
            error={currentPetErrors?.yearsOwnedError}
            onChange={(e) =>
              setCurrentPet((currentPet) => ({
                ...currentPet,
                yearsOwned: e.target.value,
              }))
            }
            onBlur={() => {
              var output = currentPet?.yearsOwned?.trim();
              setCurrentPet((currentPet) => ({
                ...currentPet,
                yearsOwned: output,
              }));
            }}
          />
          <Input
            containerClass="px-5 default-px-10 my-4 align-content-baseline"
            className="theme-3-input"
            label="What Happened to Him/Her?"
            labelClass="theme-3-input-label"
            name="whatHappened"
            value={currentPet?.whatHappened}
            error={currentPetErrors?.whatHappenedError}
            onChange={(e) =>
              setCurrentPet((currentPet) => ({
                ...currentPet,
                whatHappened: e.target.value,
              }))
            }
            onBlur={() => {
              var output = currentPet?.whatHappened?.trim();
              setCurrentPet((currentPet) => ({
                ...currentPet,
                whatHappened: output,
              }));
            }}
          />
        </div>
      )}
      {yesOrNo === "Yes" && (
        <button
          className="theme-3-btn-small theme-font-alt margin-auto"
          onClick={() => submitPet()}
        >
          Add Above Pet
        </button>
      )}
      <div className="grid grid-cols-1 mt-4 mb-6">
        <label className={"theme-3-input-label px-5 default-px-10 text-left"}>
          Would you like to add another Pet?
        </label>
        <RadioButton
          containerClass="px-5 default-px-10 mt-4 mb-2 align-content-baseline"
          label="Yes"
          labelClass={
            yesOrNo === "Yes"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="addAnotherPet_yes"
          checked={yesOrNo === "Yes"}
          value={yesOrNo}
          onChange={() => setYesOrNo("Yes")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-2 mb-4 align-content-baseline"
          label="No"
          labelClass={
            yesOrNo === "No" ? "radio-label radio-label-checked" : "radio-label"
          }
          name="addAnotherPet_no"
          checked={yesOrNo === "No"}
          value={yesOrNo}
          onChange={() =>
            yesOrNo === "Yes" &&
            applyForAdoptionPastPetsFormData?.pastPets?.length !== 0
              ? setYesOrNo("No")
              : ""
          }
        />
      </div>
    </div>
  );
};
