import { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { Navbar } from "./components/navbar";
import { Footer } from "./components/footer";
import { Home } from "./pages/home";
import { OurCats } from "./pages/our-cats";
import { Careers } from "./pages/careers";
import { Faq } from "./pages/faq";
import { Events } from "./pages/events";
import { Reservations } from "./pages/reservations";
import { GiftCards } from "./pages/gift-cards";
import { useApi } from "./api";
import {
  ContentfulContent,
  CONTENTFUL_GET_ENTRY_RESPONSE,
} from "./api/types/contentful-content";
import { NAVBAR_HEADERS } from "./api/contentful";
import { Testimonials } from "./pages/testimonials";
import { OnlineMenu } from "./pages/online-menu";
import { Apply } from "./pages/apply";

export const App = () => {
  const api = useApi();
  const [contentfulContent, setContentfulContent] =
    useState<ContentfulContent>();

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.contentful.getEntry(NAVBAR_HEADERS);
      if (response.type !== CONTENTFUL_GET_ENTRY_RESPONSE) return null;
      setContentfulContent(response.data.fields);
      return response.data.fields;
    };
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="min-h-screen theme-bg-color-2">
      <Navbar
        navbarHeader1={contentfulContent?.navbarHeader1}
        navbarHeader2={contentfulContent?.navbarHeader2}
        navbarHeader3={contentfulContent?.navbarHeader3}
        navbarHeader4={contentfulContent?.navbarHeader4}
        navbarHeader5={contentfulContent?.navbarHeader5}
        navbarHeader6={contentfulContent?.navbarHeader6}
        navbarHeader7={contentfulContent?.navbarHeader7}
        navbarHeader8={contentfulContent?.navbarHeader8}
        navbarHeader9={contentfulContent?.navbarHeader9}
        navbarHeader10={contentfulContent?.navbarHeader10}
        navbarDropdown1={contentfulContent?.navbarDropdown1}
        navbarDropdown2={contentfulContent?.navbarDropdown2}
        navbarDropdown3={contentfulContent?.navbarDropdown3}
        navbarDropdown4={contentfulContent?.navbarDropdown4}
        navbarDropdown5={contentfulContent?.navbarDropdown5}
      />
      <section className="w-full flex justify-center h-auto webkit-text-center">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/events" element={<Events />} />
          <Route path="/reservations" element={<Reservations />} />
          <Route path="/our-cats" element={<OurCats />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/apply-here" element={<Apply />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/gift-cards" element={<GiftCards />} />
          <Route path="/online-menu" element={<OnlineMenu />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </section>
      <Footer />
    </div>
  );
};
