import React from "react";

type props = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  className?: string;
  labelClass?: string;
  containerClass?: string;
  innerRef?: any;
  error?: string;
};

export const Input: React.FunctionComponent<props> = ({
  className,
  label,
  labelClass,
  containerClass,
  innerRef,
  error,
  ...props
}) => {
  return (
    <div className={`grid grid-cols-1 ${containerClass}`}>
      {label && (
        <label
          htmlFor={props.name}
          className={
            error ? `${labelClass} theme-text-color-error` : `${labelClass}`
          }
        >
          {label}
        </label>
      )}
      <input {...props} ref={innerRef} className={`${className}`} />
      {error && <p className="input-error-message">{error}</p>}
    </div>
  );
};
