export const CONTENTFUL_GET_ENTRY_RESPONSE = "CONTENTFUL_GET_ENTRY_RESPONSE";

export type ContentfulGetEntryResponse = {
  type: typeof CONTENTFUL_GET_ENTRY_RESPONSE;
  data: ContentfulFields;
};

export type ContentfulFields = {
  fields: ContentfulContent;
};

export type ContentfulContent = {
  name: string;
  header1: ContentfulCustomText;
  header2: ContentfulCustomText;
  header3: ContentfulCustomText;
  header4: ContentfulCustomText;
  header5: ContentfulCustomText;
  header6: ContentfulCustomText;
  header7: ContentfulCustomText;
  header8: ContentfulCustomText;
  header9: ContentfulCustomText;
  header10: ContentfulCustomText;
  daysAdjuster: number;
  description1: ContentfulCustomText;
  description2: ContentfulCustomText;
  description3: ContentfulCustomText;
  description4: ContentfulCustomText;
  description5: ContentfulCustomText;
  description6: ContentfulCustomText;
  description7: ContentfulCustomText;
  description8: ContentfulCustomText;
  description9: ContentfulCustomText;
  description10: ContentfulCustomText;
  headerText1: string;
  headerText2: string;
  headerText3: string;
  headerText4: string;
  headerText5: string;
  headerText6: string;
  headerText7: string;
  headerText8: string;
  descriptionText1: string;
  descriptionText2: string;
  descriptionText3: string;
  descriptionText4: string;
  descriptionText5: string;
  descriptionText6: string;
  descriptionText7: string;
  descriptionText8: string;
  images: string[];
  image1: string;
  image2: string;
  image3: string;
  image4: string;
  image5: string;
  image6: string;
  image7: string;
  label1: string;
  label2: string;
  label3: string;
  label4: string;
  label5: string;
  label6: string;
  label7: string;
  cat1: ContentfulOurCatsCat;
  cat2: ContentfulOurCatsCat;
  cat3: ContentfulOurCatsCat;
  cat4: ContentfulOurCatsCat;
  cat5: ContentfulOurCatsCat;
  cat6: ContentfulOurCatsCat;
  cat7: ContentfulOurCatsCat;
  cat8: ContentfulOurCatsCat;
  cat9: ContentfulOurCatsCat;
  cat10: ContentfulOurCatsCat;
  cat11: ContentfulOurCatsCat;
  cat12: ContentfulOurCatsCat;
  cat13: ContentfulOurCatsCat;
  cat14: ContentfulOurCatsCat;
  cat15: ContentfulOurCatsCat;
  cat16: ContentfulOurCatsCat;
  cat17: ContentfulOurCatsCat;
  cat18: ContentfulOurCatsCat;
  cat19: ContentfulOurCatsCat;
  cat20: ContentfulOurCatsCat;
  cat21: ContentfulOurCatsCat;
  cat22: ContentfulOurCatsCat;
  cat23: ContentfulOurCatsCat;
  cat24: ContentfulOurCatsCat;
  cat25: ContentfulOurCatsCat;
  cat26: ContentfulOurCatsCat;
  cat27: ContentfulOurCatsCat;
  cat28: ContentfulOurCatsCat;
  cat29: ContentfulOurCatsCat;
  cat30: ContentfulOurCatsCat;
  event1: ContentfulEventsEvent;
  event2: ContentfulEventsEvent;
  event3: ContentfulEventsEvent;
  event4: ContentfulEventsEvent;
  event5: ContentfulEventsEvent;
  event6: ContentfulEventsEvent;
  event7: ContentfulEventsEvent;
  event8: ContentfulEventsEvent;
  event9: ContentfulEventsEvent;
  event10: ContentfulEventsEvent;
  news1: ContentfulHomeNews;
  news2: ContentfulHomeNews;
  news3: ContentfulHomeNews;
  news4: ContentfulHomeNews;
  news5: ContentfulHomeNews;
  career1: ContentfulCareer;
  career2: ContentfulCareer;
  career3: ContentfulCareer;
  career4: ContentfulCareer;
  career5: ContentfulCareer;
  testimonial1: ContentfulTestimonial;
  testimonial2: ContentfulTestimonial;
  testimonial3: ContentfulTestimonial;
  testimonial4: ContentfulTestimonial;
  testimonial5: ContentfulTestimonial;
  testimonial6: ContentfulTestimonial;
  testimonial7: ContentfulTestimonial;
  testimonial8: ContentfulTestimonial;
  testimonial9: ContentfulTestimonial;
  testimonial10: ContentfulTestimonial;
  careerQuestion1: ContentfulCareerQuestion;
  careerQuestion2: ContentfulCareerQuestion;
  careerQuestion3: ContentfulCareerQuestion;
  careerQuestion4: ContentfulCareerQuestion;
  careerQuestion5: ContentfulCareerQuestion;
  navbarHeader1: ContentfulNavbarHeader;
  navbarHeader2: ContentfulNavbarHeader;
  navbarHeader3: ContentfulNavbarHeader;
  navbarHeader4: ContentfulNavbarHeader;
  navbarHeader5: ContentfulNavbarHeader;
  navbarHeader6: ContentfulNavbarHeader;
  navbarHeader7: ContentfulNavbarHeader;
  navbarHeader8: ContentfulNavbarHeader;
  navbarHeader9: ContentfulNavbarHeader;
  navbarHeader10: ContentfulNavbarHeader;
  navbarDropdown1: ContentfulNavbarDropdown;
  navbarDropdown2: ContentfulNavbarDropdown;
  navbarDropdown3: ContentfulNavbarDropdown;
  navbarDropdown4: ContentfulNavbarDropdown;
  navbarDropdown5: ContentfulNavbarDropdown;
  address: ContentfulFooterAddress;
  phoneNumber: string;
  bullets1: string[];
  bullets2: string[];
  bullets3: string[];
  bullets4: string[];
  bullets5: string[];
};

export type ContentfulCustomText = {
  text: string;
  alignment: string;
}

export type ContentfulImages = {
  src: string;
}

export type ContentfulOurCatsCat = {
  url: string;
  name: string;
  description: string;
  information: string;
  age: string;
  gender: string;
  images: string[];
};

export type ContentfulTestimonial = {
  image: string;
  name: string;
  description: string;
}

export type ContentfulEventsEvent = {
  name: string;
  description: string;
  date: ContentfulEventsEventDate;
  image: string;
  video: string;
};

export type ContentfulHomeNews = {
  image: string;
  title: string;
  text: string;
  link: string;
  linkText: string;
};

export type ContentfulEventsEventDate = {
  month: string;
  day: string;
};

export type ContentfulCareer = {
  title: string;
  descriptions: string[];
};

export type ContentfulCareerQuestion = {
  text: string;
  style: string;
}

export type ContentfulNavbarHeader = {
  disabled: boolean;
  text: string;
}

export type ContentfulNavbarDropdown = {
  disabled?: boolean;
  label?: string;
  navbarHeader1?: ContentfulNavbarHeader;
  navbarHeader2?: ContentfulNavbarHeader;
  navbarHeader3?: ContentfulNavbarHeader;
  navbarHeader4?: ContentfulNavbarHeader;
  navbarHeader5?: ContentfulNavbarHeader;
}

export type ContentfulFooterAddress = {
  line1: string;
  line2: string;
  line3: string;
}