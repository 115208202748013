import { useEffect, useState } from "react";
import { useApi } from "../../api";
import { GIFT_CARDS_PAGE } from "../../api/contentful";
import {
  CONTENTFUL_GET_ENTRY_RESPONSE,
  ContentfulContent,
} from "../../api/types/contentful-content";
import { CustomHeader } from "../../components/custom-header";
import { CustomText } from "../../components/custom-text";
import { GiftCardsItem } from "../../components/gift-cards-item";

export const GiftCards = () => {
  const api = useApi();
  const [contentfulContent, setContentfulContent] =
    useState<ContentfulContent>();

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.contentful.getEntry(GIFT_CARDS_PAGE);
      if (response.type !== CONTENTFUL_GET_ENTRY_RESPONSE) return null;
      setContentfulContent(response.data.fields);
      return response.data.fields;
    };
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="page-container flex flex-col">
      <div className="grid grid-cols-1">
        {contentfulContent?.image1 && (
          <img className="w-full default-w-1/2 default-mt-4 justify-self-center" src={contentfulContent.image1} alt="" />
        )}
        {contentfulContent?.header1 && (
          <CustomHeader
            className="theme-text-color-3 theme-font text-5xl mb-1 px-5 default-px-10"
            contentfulCustomText={contentfulContent.header1}
          />
        )}
        {contentfulContent?.description1 && (
          <CustomText
            className="theme-text-color-3 text-2xl px-5 mt-0 default-px-10"
            contentfulCustomText={contentfulContent.description1}
          />
        )}
      </div>
      <div className="grid grid-cols-1 default-grid-cols-2">
        <div className="grid grid-cols-1 place-items-center">
          {contentfulContent?.header2 && (
            <CustomHeader
              className="theme-text-color-3 theme-font text-4xl pt-4 mb-1 px-5 default-px-10"
              contentfulCustomText={contentfulContent.header2}
            />
          )}
          {contentfulContent?.image2 && (
            <GiftCardsItem
              imgClass="gift-cards-item-img-1"
              src={contentfulContent.image2}
              href="https://squareup.com/gift/MLXNGTKNBQJQX/order"
              buttonText="Gift Cards"
            />
          )}
        </div>
        <div className="grid grid-cols-1 place-items-center">
          {contentfulContent?.header3 && (
            <CustomHeader
              className="theme-text-color-3 theme-font text-4xl pt-4 mb-1 px-5 default-px-10"
              contentfulCustomText={contentfulContent.header3}
            />
          )}
          {contentfulContent?.image3 && (
            <GiftCardsItem
              imgClass="gift-cards-item-img-2"
              src={contentfulContent.image3}
              href="https://app.squarespacescheduling.com/catalog.php?owner=28037105"
              buttonText="Gift Certificates"
            />
          )}
        </div>
      </div>
    </div>
  );
};
