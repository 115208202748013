import {
  ApplyForAdoptionApplicationFormData,
  ApplyForAdoptionApplicationFormDataErrors,
  ApplyForAdoptionPastPetsFormDataPet,
  ApplyForAdoptionPastVetsFormDataVet,
  ApplyForAdoptionPetsFormDataPet,
} from "../api/types/apply-for-adoption-form-data";
import {
  formatDate,
  formatPhoneNumber,
  lettersOnly,
  numbersOnly,
} from "../lib/text-helpers";
import { AdoptionApplicationListOfPetsForm } from "./adoption-application-list-of-pets-form";
import { ApplyForAdoptionPastPetsForm } from "./adoption-past-pets-form";
import { ApplyForAdoptionPastVetsForm } from "./adoption-past-vets-form";
import { Input } from "./input";
import { RadioButton } from "./radio-button";

type props = {
  loading?: boolean;
  applyForAdoptionApplicationFormData?: ApplyForAdoptionApplicationFormData;
  applyForAdoptionApplicationFormDataErrors?: ApplyForAdoptionApplicationFormDataErrors;
  onChange: Function;
  onBlur: Function;
  addPet: Function;
  deletePet: Function;
  addPastPet: Function;
  deletePastPet: Function;
  addPastVet: Function;
  deletePastVet: Function;
  onChangeHowDidYouHear: Function;
  onChangeReasonsForAdoption: Function;
};

export const AdoptionApplicationForm: React.FunctionComponent<props> = ({
  loading,
  applyForAdoptionApplicationFormData,
  applyForAdoptionApplicationFormDataErrors,
  onChange,
  onBlur,
  addPet,
  deletePet,
  addPastPet,
  deletePastPet,
  addPastVet,
  deletePastVet,
  onChangeHowDidYouHear,
  onChangeReasonsForAdoption,
}) => {
  return (
    <div className={loading ? "div-loading" : ""}>
      <h1 className="theme-text-color-3 theme-font text-5xl mb-1 px-5 default-px-10 text-center">
        ADOPTION APPLICATION
      </h1>
      <h1 className="theme-text-color-3 theme-font text-5xl mb-1 px-5 default-px-10 text-center">
        HANCOCK COUNTY HUMANE SOCIETY
      </h1>
      <div className="grid grid-cols-1 default-grid-cols-2 mt-4">
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="Name of cat(s) applying for:"
          labelClass="theme-3-input-label"
          name="catNames"
          value={applyForAdoptionApplicationFormData?.catNames}
          error={applyForAdoptionApplicationFormDataErrors?.catNamesError}
          onChange={(e) => onChange("catNames", e.target.value)}
          onBlur={() => {
            var output = applyForAdoptionApplicationFormData?.catNames?.trim();
            onBlur("catNames", output);
          }}
        />
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="Name"
          labelClass="theme-3-input-label"
          name="name"
          value={applyForAdoptionApplicationFormData?.name}
          error={applyForAdoptionApplicationFormDataErrors?.nameError}
          onChange={(e) => onChange("name", e.target.value)}
          onBlur={() => {
            var output = applyForAdoptionApplicationFormData?.name?.trim();
            onBlur("name", output);
          }}
        />
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="Phone Number"
          labelClass="theme-3-input-label"
          name="phoneNumber"
          value={applyForAdoptionApplicationFormData?.phoneNumber}
          error={applyForAdoptionApplicationFormDataErrors?.phoneNumberError}
          onChange={(e) => {
            var output = formatPhoneNumber(e.target.value);
            if (output || output === "") onChange("phoneNumber", output);
          }}
        />
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="Address"
          labelClass="theme-3-input-label"
          name="address"
          value={applyForAdoptionApplicationFormData?.address}
          error={applyForAdoptionApplicationFormDataErrors?.addressError}
          onChange={(e) => onChange("address", e.target.value)}
          onBlur={() => {
            var output = applyForAdoptionApplicationFormData?.address?.trim();
            onBlur("address", output);
          }}
        />
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="City"
          labelClass="theme-3-input-label"
          name="city"
          value={applyForAdoptionApplicationFormData?.city}
          error={applyForAdoptionApplicationFormDataErrors?.cityError}
          onChange={(e) => {
            var output = lettersOnly(e.target.value);
            if (output || output === "") onChange("city", output);
          }}
          onBlur={() => {
            var output = applyForAdoptionApplicationFormData?.city?.trim();
            onBlur("city", output);
          }}
        />
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="State"
          labelClass="theme-3-input-label"
          name="state"
          maxLength={2}
          value={applyForAdoptionApplicationFormData?.state}
          error={applyForAdoptionApplicationFormDataErrors?.stateError}
          onChange={(e) => {
            var output = lettersOnly(e.target.value);
            if (output || output === "")
              onChange("state", output.toUpperCase());
          }}
          onBlur={() => {
            var output = applyForAdoptionApplicationFormData?.state?.trim();
            onBlur("state", output);
          }}
        />
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="Zip Code"
          labelClass="theme-3-input-label"
          name="zipCode"
          maxLength={5}
          value={applyForAdoptionApplicationFormData?.zipCode}
          error={applyForAdoptionApplicationFormDataErrors?.zipCodeError}
          onChange={(e) => {
            var output = numbersOnly(e.target.value);
            if (output || output === "") onChange("zipCode", output);
          }}
          onBlur={() => {
            var output = applyForAdoptionApplicationFormData?.zipCode?.trim();
            onBlur("zipCode", output);
          }}
        />
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="Email"
          labelClass="theme-3-input-label"
          name="email"
          value={applyForAdoptionApplicationFormData?.email}
          error={applyForAdoptionApplicationFormDataErrors?.emailError}
          onChange={(e) => onChange("email", e.target.value)}
          onBlur={() => {
            var output = applyForAdoptionApplicationFormData?.email?.trim();
            onBlur("email", output);
          }}
        />
      </div>
      <div className="grid grid-cols-1 mt-4 text-left">
        <label
          className={
            applyForAdoptionApplicationFormDataErrors?.marriedError
              ? "theme-3-input-label theme-text-color-error px-5 default-px-10"
              : "theme-3-input-label px-5 default-px-10"
          }
        >
          Married
        </label>
        <RadioButton
          containerClass="px-5 default-px-10 mt-4 mb-2 align-content-baseline"
          label="Yes"
          labelClass={
            applyForAdoptionApplicationFormData?.married === "Yes"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="married_yes"
          checked={applyForAdoptionApplicationFormData?.married === "Yes"}
          value={applyForAdoptionApplicationFormData?.married}
          error={applyForAdoptionApplicationFormDataErrors?.marriedError}
          onChange={() => onChange("married", "Yes")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-2 mb-4 align-content-baseline"
          label="No"
          labelClass={
            applyForAdoptionApplicationFormData?.married === "No"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="married_no"
          checked={applyForAdoptionApplicationFormData?.married === "No"}
          value={applyForAdoptionApplicationFormData?.married}
          error={applyForAdoptionApplicationFormDataErrors?.marriedError}
          onChange={() => onChange("married", "No")}
        />
      </div>
      <div className="grid grid-cols-1 default-grid-cols-2 mt-4">
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="Number of Children"
          labelClass="theme-3-input-label"
          name="numberOfChildren"
          value={applyForAdoptionApplicationFormData?.numberOfChildren}
          error={
            applyForAdoptionApplicationFormDataErrors?.numberOfChildrenError
          }
          onChange={(e) => onChange("numberOfChildren", e.target.value)}
          onBlur={() => {
            var output =
              applyForAdoptionApplicationFormData?.numberOfChildren?.trim();
            onBlur("numberOfChildren", output);
          }}
        />
        {applyForAdoptionApplicationFormData?.numberOfChildren !== "" &&
          applyForAdoptionApplicationFormData?.numberOfChildren !== "0" && (
            <Input
              containerClass="px-5 default-px-10 my-4 align-content-baseline"
              className="theme-3-input"
              label="Ages"
              labelClass="theme-3-input-label"
              name="childrenAges"
              value={applyForAdoptionApplicationFormData?.childrenAges}
              error={
                applyForAdoptionApplicationFormDataErrors?.childrenAgesError
              }
              onChange={(e) => onChange("childrenAges", e.target.value)}
              onBlur={() => {
                var output =
                  applyForAdoptionApplicationFormData?.childrenAges?.trim();
                onBlur("childrenAges", output);
              }}
            />
          )}
      </div>
      <div className="grid grid-cols-1 mt-4 text-left">
        <label
          className={
            applyForAdoptionApplicationFormDataErrors?.ownOrRentError
              ? "theme-3-input-label theme-text-color-error px-5 default-px-10"
              : "theme-3-input-label px-5 default-px-10"
          }
        >
          House/Apartment Living
        </label>
        <RadioButton
          containerClass="px-5 default-px-10 mt-4 mb-2 align-content-baseline"
          label="Own"
          labelClass={
            applyForAdoptionApplicationFormData?.ownOrRent === "Own"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="ownOrRent_own"
          checked={applyForAdoptionApplicationFormData?.ownOrRent === "Own"}
          value={applyForAdoptionApplicationFormData?.ownOrRent}
          error={applyForAdoptionApplicationFormDataErrors?.ownOrRentError}
          onChange={() => onChange("ownOrRent", "Own")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-2 mb-4 align-content-baseline"
          label="Rent"
          labelClass={
            applyForAdoptionApplicationFormData?.ownOrRent === "Rent"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="ownOrRent_rent"
          checked={applyForAdoptionApplicationFormData?.ownOrRent === "Rent"}
          value={applyForAdoptionApplicationFormData?.ownOrRent}
          error={applyForAdoptionApplicationFormDataErrors?.ownOrRentError}
          onChange={() => onChange("ownOrRent", "Rent")}
        />
      </div>
      <div className="grid grid-cols-1 default-grid-cols-2 mt-4">
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="Acreage"
          labelClass="theme-3-input-label"
          name="acreage"
          value={applyForAdoptionApplicationFormData?.acreage}
          error={applyForAdoptionApplicationFormDataErrors?.acreageError}
          onChange={(e) => onChange("acreage", e.target.value)}
          onBlur={() => {
            var output = applyForAdoptionApplicationFormData?.acreage?.trim();
            onBlur("acreage", output);
          }}
        />
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="How long at present address?"
          labelClass="theme-3-input-label"
          name="howLongAtPresentAddress"
          value={applyForAdoptionApplicationFormData?.howLongAtPresentAddress}
          error={
            applyForAdoptionApplicationFormDataErrors?.howLongAtPresentAddressError
          }
          onChange={(e) => onChange("howLongAtPresentAddress", e.target.value)}
          onBlur={() => {
            var output =
              applyForAdoptionApplicationFormData?.howLongAtPresentAddress?.trim();
            onBlur("howLongAtPresentAddress", output);
          }}
        />
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="Landlord Name"
          labelClass="theme-3-input-label"
          name="landlordName"
          value={applyForAdoptionApplicationFormData?.landlordName}
          error={applyForAdoptionApplicationFormDataErrors?.landlordNameError}
          onChange={(e) => onChange("landlordName", e.target.value)}
          onBlur={() => {
            var output =
              applyForAdoptionApplicationFormData?.landlordName?.trim();
            onBlur("landlordName", output);
          }}
        />
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="Landlord Phone Number"
          labelClass="theme-3-input-label"
          name="landlordPhoneNumber"
          value={applyForAdoptionApplicationFormData?.landlordPhoneNumber}
          error={
            applyForAdoptionApplicationFormDataErrors?.landlordPhoneNumberError
          }
          onChange={(e) => {
            var output = formatPhoneNumber(e.target.value);
            if (output || output === "")
              onChange("landlordPhoneNumber", output);
          }}
        />
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="Place of Employment"
          labelClass="theme-3-input-label"
          name="placeOfEmployment"
          value={applyForAdoptionApplicationFormData?.placeOfEmployment}
          error={
            applyForAdoptionApplicationFormDataErrors?.placeOfEmploymentError
          }
          onChange={(e) => onChange("placeOfEmployment", e.target.value)}
          onBlur={() => {
            var output =
              applyForAdoptionApplicationFormData?.placeOfEmployment?.trim();
            onBlur("placeOfEmployment", output);
          }}
        />
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="How long have you been at this company?"
          labelClass="theme-3-input-label"
          name="howLongAtEmployment"
          value={applyForAdoptionApplicationFormData?.howLongAtEmployment}
          error={
            applyForAdoptionApplicationFormDataErrors?.howLongAtEmploymentError
          }
          onChange={(e) => onChange("howLongAtEmployment", e.target.value)}
          onBlur={() => {
            var output =
              applyForAdoptionApplicationFormData?.howLongAtEmployment?.trim();
            onBlur("howLongAtEmployment", output);
          }}
        />
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="Business Phone"
          labelClass="theme-3-input-label"
          name="businessPhoneNumber"
          value={applyForAdoptionApplicationFormData?.businessPhoneNumber}
          error={
            applyForAdoptionApplicationFormDataErrors?.businessPhoneNumberError
          }
          onChange={(e) => {
            var output = formatPhoneNumber(e.target.value);
            if (output || output === "")
              onChange("businessPhoneNumber", output);
          }}
        />
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="Veterinarian Name"
          labelClass="theme-3-input-label"
          name="veterinarianName"
          value={applyForAdoptionApplicationFormData?.veterinarianName}
          error={
            applyForAdoptionApplicationFormDataErrors?.veterinarianNameError
          }
          onChange={(e) => onChange("veterinarianName", e.target.value)}
          onBlur={() => {
            var output =
              applyForAdoptionApplicationFormData?.veterinarianName?.trim();
            onBlur("veterinarianName", output);
          }}
        />
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="Veterinarian Phone"
          labelClass="theme-3-input-label"
          name="veterinarianPhoneNumber"
          value={applyForAdoptionApplicationFormData?.veterinarianPhoneNumber}
          error={
            applyForAdoptionApplicationFormDataErrors?.veterinarianPhoneNumberError
          }
          onChange={(e) => {
            var output = formatPhoneNumber(e.target.value);
            if (output || output === "")
              onChange("veterinarianPhoneNumber", output);
          }}
        />
      </div>
      <div className="grid grid-cols-1 mt-4 text-left">
        <label
          className={
            applyForAdoptionApplicationFormDataErrors?.overAge55Error
              ? "theme-3-input-label theme-text-color-error px-5 default-px-10"
              : "theme-3-input-label px-5 default-px-10"
          }
        >
          Are you over the age of 55?
        </label>
        <RadioButton
          containerClass="px-5 default-px-10 mt-4 mb-2 align-content-baseline"
          label="Yes"
          labelClass={
            applyForAdoptionApplicationFormData?.overAge55 === "Yes"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="overAge55_yes"
          checked={applyForAdoptionApplicationFormData?.overAge55 === "Yes"}
          value={applyForAdoptionApplicationFormData?.overAge55}
          error={applyForAdoptionApplicationFormDataErrors?.overAge55Error}
          onChange={() => onChange("overAge55", "Yes")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-2 mb-4 align-content-baseline"
          label="No"
          labelClass={
            applyForAdoptionApplicationFormData?.overAge55 === "No"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="overAge55_no"
          checked={applyForAdoptionApplicationFormData?.overAge55 === "No"}
          value={applyForAdoptionApplicationFormData?.overAge55}
          error={applyForAdoptionApplicationFormDataErrors?.overAge55Error}
          onChange={() => onChange("overAge55", "No")}
        />
        <label
          className={
            applyForAdoptionApplicationFormDataErrors?.reasonsForAdoptionError
              ? "theme-3-input-label theme-text-color-error px-5 default-px-10"
              : "theme-3-input-label px-5 default-px-10"
          }
        >
          Please check any and all of your reasons for wanting to adopt this
          pet:
        </label>
        <RadioButton
          containerClass="px-5 default-px-10 mt-2 mb-4 align-content-baseline"
          label="Companion"
          labelClass={
            applyForAdoptionApplicationFormData?.reasonsForAdoption?.includes(
              "Companion"
            )
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="reasonsForAdoption_companion"
          checked={
            applyForAdoptionApplicationFormData?.reasonsForAdoption?.includes(
              "Companion"
            )
              ? true
              : false
          }
          value={
            applyForAdoptionApplicationFormData?.reasonsForAdoption?.includes(
              "Companion"
            )
              ? "Companion"
              : ""
          }
          error={
            applyForAdoptionApplicationFormDataErrors?.reasonsForAdoptionError
          }
          onChange={() =>
            applyForAdoptionApplicationFormData?.reasonsForAdoption
          }
          onClick={() => onChangeReasonsForAdoption("Companion")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-2 mb-4 align-content-baseline"
          label="Family Pet"
          labelClass={
            applyForAdoptionApplicationFormData?.reasonsForAdoption?.includes(
              "Family Pet"
            )
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="reasonsForAdoption_family_pet"
          checked={
            applyForAdoptionApplicationFormData?.reasonsForAdoption?.includes(
              "Family Pet"
            )
              ? true
              : false
          }
          value={
            applyForAdoptionApplicationFormData?.reasonsForAdoption?.includes(
              "Family Pet"
            )
              ? "Family Pet"
              : ""
          }
          error={
            applyForAdoptionApplicationFormDataErrors?.reasonsForAdoptionError
          }
          onChange={() =>
            applyForAdoptionApplicationFormData?.reasonsForAdoption
          }
          onClick={() => onChangeReasonsForAdoption("Family Pet")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-2 mb-4 align-content-baseline"
          label="Childs Pet"
          labelClass={
            applyForAdoptionApplicationFormData?.reasonsForAdoption?.includes(
              "Childs Pet"
            )
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="reasonsForAdoption_childs_pet"
          checked={
            applyForAdoptionApplicationFormData?.reasonsForAdoption?.includes(
              "Childs Pet"
            )
              ? true
              : false
          }
          value={
            applyForAdoptionApplicationFormData?.reasonsForAdoption?.includes(
              "Childs Pet"
            )
              ? "Childs Pet"
              : ""
          }
          error={
            applyForAdoptionApplicationFormDataErrors?.reasonsForAdoptionError
          }
          onChange={() =>
            applyForAdoptionApplicationFormData?.reasonsForAdoption
          }
          onClick={() => onChangeReasonsForAdoption("Childs Pet")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-2 mb-4 align-content-baseline"
          label="Companion for other pet"
          labelClass={
            applyForAdoptionApplicationFormData?.reasonsForAdoption?.includes(
              "Companion for other pet"
            )
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="reasonsForAdoption_companion_other_pet"
          checked={
            applyForAdoptionApplicationFormData?.reasonsForAdoption?.includes(
              "Companion for other pet"
            )
              ? true
              : false
          }
          value={
            applyForAdoptionApplicationFormData?.reasonsForAdoption?.includes(
              "Companion for other pet"
            )
              ? "Companion for other pet"
              : ""
          }
          error={
            applyForAdoptionApplicationFormDataErrors?.reasonsForAdoptionError
          }
          onChange={() =>
            applyForAdoptionApplicationFormData?.reasonsForAdoption
          }
          onClick={() => onChangeReasonsForAdoption("Companion for other pet")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-2 mb-4 align-content-baseline"
          label="Mouser"
          labelClass={
            applyForAdoptionApplicationFormData?.reasonsForAdoption?.includes(
              "Mouser"
            )
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="reasonsForAdoption_mouser"
          checked={
            applyForAdoptionApplicationFormData?.reasonsForAdoption?.includes(
              "Mouser"
            )
              ? true
              : false
          }
          value={
            applyForAdoptionApplicationFormData?.reasonsForAdoption?.includes(
              "Mouser"
            )
              ? "Mouser"
              : ""
          }
          error={
            applyForAdoptionApplicationFormDataErrors?.reasonsForAdoptionError
          }
          onChange={() =>
            applyForAdoptionApplicationFormData?.reasonsForAdoption
          }
          onClick={() => onChangeReasonsForAdoption("Mouser")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-2 mb-4 align-content-baseline"
          label="Breeding"
          labelClass={
            applyForAdoptionApplicationFormData?.reasonsForAdoption?.includes(
              "Breeding"
            )
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="reasonsForAdoption_breeding"
          checked={
            applyForAdoptionApplicationFormData?.reasonsForAdoption?.includes(
              "Breeding"
            )
              ? true
              : false
          }
          value={
            applyForAdoptionApplicationFormData?.reasonsForAdoption?.includes(
              "Breeding"
            )
              ? "Breeding"
              : ""
          }
          error={
            applyForAdoptionApplicationFormDataErrors?.reasonsForAdoptionError
          }
          onChange={() =>
            applyForAdoptionApplicationFormData?.reasonsForAdoption
          }
          onClick={() => onChangeReasonsForAdoption("Breeding")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-2 mb-4 align-content-baseline"
          label="Other"
          labelClass={
            applyForAdoptionApplicationFormData?.reasonsForAdoption?.includes(
              "Other"
            )
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="reasonsForAdoption_other"
          checked={
            applyForAdoptionApplicationFormData?.reasonsForAdoption?.includes(
              "Other"
            )
              ? true
              : false
          }
          value={
            applyForAdoptionApplicationFormData?.reasonsForAdoption?.includes(
              "Other"
            )
              ? "Other"
              : ""
          }
          error={
            applyForAdoptionApplicationFormDataErrors?.reasonsForAdoptionError
          }
          onChange={() =>
            applyForAdoptionApplicationFormData?.reasonsForAdoption
          }
          onClick={() => onChangeReasonsForAdoption("Other")}
        />
        <label
          className={
            applyForAdoptionApplicationFormDataErrors?.planToAdoptError
              ? "theme-3-input-label theme-text-color-error px-5 default-px-10"
              : "theme-3-input-label px-5 default-px-10"
          }
        >
          Do all members of your household know you plan to adopt?
        </label>
        <RadioButton
          containerClass="px-5 default-px-10 mt-4 mb-2 align-content-baseline"
          label="Yes"
          labelClass={
            applyForAdoptionApplicationFormData?.planToAdopt === "Yes"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="planToAdopt_yes"
          checked={applyForAdoptionApplicationFormData?.planToAdopt === "Yes"}
          value={applyForAdoptionApplicationFormData?.planToAdopt}
          error={applyForAdoptionApplicationFormDataErrors?.planToAdoptError}
          onChange={() => onChange("planToAdopt", "Yes")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-2 mb-4 align-content-baseline"
          label="No"
          labelClass={
            applyForAdoptionApplicationFormData?.planToAdopt === "No"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="planToAdopt_no"
          checked={applyForAdoptionApplicationFormData?.planToAdopt === "No"}
          value={applyForAdoptionApplicationFormData?.planToAdopt}
          error={applyForAdoptionApplicationFormDataErrors?.planToAdoptError}
          onChange={() => onChange("planToAdopt", "No")}
        />
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="Who will be the primary caretaker of your new pet?"
          labelClass="theme-3-input-label"
          name="primaryCaretaker"
          value={applyForAdoptionApplicationFormData?.primaryCaretaker}
          error={
            applyForAdoptionApplicationFormDataErrors?.primaryCaretakerError
          }
          onChange={(e) => onChange("primaryCaretaker", e.target.value)}
          onBlur={() => {
            var output =
              applyForAdoptionApplicationFormData?.primaryCaretaker?.trim();
            onBlur("primaryCaretaker", output);
          }}
        />
      </div>
      <div className="grid grid-cols-1 mt-4 text-left">
        <label
          className={
            applyForAdoptionApplicationFormDataErrors?.isAnyoneAlergicError
              ? "theme-3-input-label theme-text-color-error px-5 default-px-10"
              : "theme-3-input-label px-5 default-px-10"
          }
        >
          Is any member of your household allergic to animals?
        </label>
        <RadioButton
          containerClass="px-5 default-px-10 mt-4 mb-2 align-content-baseline"
          label="Yes"
          labelClass={
            applyForAdoptionApplicationFormData?.isAnyoneAlergic === "Yes"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="isAnyoneAlergic_yes"
          checked={
            applyForAdoptionApplicationFormData?.isAnyoneAlergic === "Yes"
          }
          value={applyForAdoptionApplicationFormData?.isAnyoneAlergic}
          error={
            applyForAdoptionApplicationFormDataErrors?.isAnyoneAlergicError
          }
          onChange={() => onChange("isAnyoneAlergic", "Yes")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-2 mb-4 align-content-baseline"
          label="No"
          labelClass={
            applyForAdoptionApplicationFormData?.isAnyoneAlergic === "No"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="isAnyoneAlergic_no"
          checked={
            applyForAdoptionApplicationFormData?.isAnyoneAlergic === "No"
          }
          value={applyForAdoptionApplicationFormData?.isAnyoneAlergic}
          error={
            applyForAdoptionApplicationFormDataErrors?.isAnyoneAlergicError
          }
          onChange={() => onChange("isAnyoneAlergic", "No")}
        />
        <label
          className={
            applyForAdoptionApplicationFormDataErrors?.isAnyoneAlergicError
              ? "theme-3-input-label theme-text-color-error px-5 default-px-10"
              : "theme-3-input-label px-5 default-px-10"
          }
        >
          Do you have any pets at the present time?
        </label>
        <RadioButton
          containerClass="px-5 default-px-10 mt-4 mb-2 align-content-baseline"
          label="Yes"
          labelClass={
            applyForAdoptionApplicationFormData?.areThereOtherPets === "Yes"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="areThereOtherPets_yes"
          checked={
            applyForAdoptionApplicationFormData?.areThereOtherPets === "Yes"
          }
          value={applyForAdoptionApplicationFormData?.areThereOtherPets}
          error={
            applyForAdoptionApplicationFormDataErrors?.areThereOtherPetsError
          }
          onChange={() => onChange("areThereOtherPets", "Yes")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-2 mb-4 align-content-baseline"
          label="No"
          labelClass={
            applyForAdoptionApplicationFormData?.areThereOtherPets === "No"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="areThereOtherPets_no"
          checked={
            applyForAdoptionApplicationFormData?.areThereOtherPets === "No"
          }
          value={applyForAdoptionApplicationFormData?.areThereOtherPets}
          error={
            applyForAdoptionApplicationFormDataErrors?.areThereOtherPetsError
          }
          onChange={() => onChange("areThereOtherPets", "No")}
        />
        {applyForAdoptionApplicationFormData?.areThereOtherPets === "Yes" && (
          <AdoptionApplicationListOfPetsForm
            applyForAdoptionPetsFormData={
              applyForAdoptionApplicationFormData?.listOfPets
            }
            addPet={(currentPet: ApplyForAdoptionPetsFormDataPet) =>
              addPet(currentPet)
            }
            deletePet={(index: number) => deletePet(index)}
          />
        )}
        <label
          className={
            applyForAdoptionApplicationFormDataErrors?.ownedOtherPetsRecentlyError
              ? "theme-3-input-label theme-text-color-error px-5 default-px-10"
              : "theme-3-input-label px-5 default-px-10"
          }
        >
          Have you owned any other animals in the last five years?
        </label>
        <RadioButton
          containerClass="px-5 default-px-10 mt-4 mb-2 align-content-baseline"
          label="Yes"
          labelClass={
            applyForAdoptionApplicationFormData?.ownedOtherPetsRecently ===
            "Yes"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="ownedOtherPetsRecently_yes"
          checked={
            applyForAdoptionApplicationFormData?.ownedOtherPetsRecently ===
            "Yes"
          }
          value={applyForAdoptionApplicationFormData?.ownedOtherPetsRecently}
          error={
            applyForAdoptionApplicationFormDataErrors?.ownedOtherPetsRecentlyError
          }
          onChange={() => onChange("ownedOtherPetsRecently", "Yes")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-2 mb-4 align-content-baseline"
          label="No"
          labelClass={
            applyForAdoptionApplicationFormData?.ownedOtherPetsRecently === "No"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="ownedOtherPetsRecently_no"
          checked={
            applyForAdoptionApplicationFormData?.ownedOtherPetsRecently === "No"
          }
          value={applyForAdoptionApplicationFormData?.ownedOtherPetsRecently}
          error={
            applyForAdoptionApplicationFormDataErrors?.ownedOtherPetsRecentlyError
          }
          onChange={() => onChange("ownedOtherPetsRecently", "No")}
        />
        {applyForAdoptionApplicationFormData?.ownedOtherPetsRecently ===
          "Yes" && (
          <ApplyForAdoptionPastPetsForm
            applyForAdoptionPastPetsFormData={
              applyForAdoptionApplicationFormData?.pastPets
            }
            addPastPet={(currentPet: ApplyForAdoptionPastPetsFormDataPet) =>
              addPastPet(currentPet)
            }
            deletePastPet={(index: number) => deletePastPet(index)}
          />
        )}
        <label
          className={
            applyForAdoptionApplicationFormDataErrors?.givenUpOnPetError
              ? "theme-3-input-label theme-text-color-error px-5 default-px-10"
              : "theme-3-input-label px-5 default-px-10"
          }
        >
          Have you ever given up a pet?
        </label>
        <RadioButton
          containerClass="px-5 default-px-10 mt-4 mb-2 align-content-baseline"
          label="Yes"
          labelClass={
            applyForAdoptionApplicationFormData?.givenUpOnPet === "Yes"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="givenUpOnPet_yes"
          checked={applyForAdoptionApplicationFormData?.givenUpOnPet === "Yes"}
          value={applyForAdoptionApplicationFormData?.givenUpOnPet}
          error={applyForAdoptionApplicationFormDataErrors?.givenUpOnPetError}
          onChange={() => onChange("givenUpOnPet", "Yes")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-2 mb-4 align-content-baseline"
          label="No"
          labelClass={
            applyForAdoptionApplicationFormData?.givenUpOnPet === "No"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="givenUpOnPet_no"
          checked={applyForAdoptionApplicationFormData?.givenUpOnPet === "No"}
          value={applyForAdoptionApplicationFormData?.givenUpOnPet}
          error={applyForAdoptionApplicationFormDataErrors?.givenUpOnPetError}
          onChange={() => onChange("givenUpOnPet", "No")}
        />
        {applyForAdoptionApplicationFormData?.givenUpOnPet === "Yes" && (
          <Input
            containerClass="px-5 default-px-10 my-4 align-content-baseline"
            className="theme-3-input"
            label="If so, why, and what did you do with it (e.g. turned it into shelter, gave to friend or family member, etc.)?"
            labelClass="theme-3-input-label"
            name="givenUpOnPetWhy"
            value={applyForAdoptionApplicationFormData?.givenUpOnPetWhy}
            error={
              applyForAdoptionApplicationFormDataErrors?.givenUpOnPetWhyError
            }
            onChange={(e) => onChange("givenUpOnPetWhy", e.target.value)}
            onBlur={() => {
              var output =
                applyForAdoptionApplicationFormData?.givenUpOnPetWhy?.trim();
              onBlur("givenUpOnPetWhy", output);
            }}
          />
        )}
        <label
          className={
            applyForAdoptionApplicationFormDataErrors?.haveYouUsedTheVetWithTheseAnimalsError
              ? "theme-3-input-label theme-text-color-error px-5 default-px-10"
              : "theme-3-input-label px-5 default-px-10"
          }
        >
          Have you used the same veterinarian with all of these animals?
        </label>
        <RadioButton
          containerClass="px-5 default-px-10 mt-4 mb-2 align-content-baseline"
          label="Yes"
          labelClass={
            applyForAdoptionApplicationFormData?.haveYouUsedTheVetWithTheseAnimals ===
            "Yes"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="haveYouUsedTheVetWithTheseAnimals_yes"
          checked={
            applyForAdoptionApplicationFormData?.haveYouUsedTheVetWithTheseAnimals ===
            "Yes"
          }
          value={
            applyForAdoptionApplicationFormData?.haveYouUsedTheVetWithTheseAnimals
          }
          error={
            applyForAdoptionApplicationFormDataErrors?.haveYouUsedTheVetWithTheseAnimalsError
          }
          onChange={() => onChange("haveYouUsedTheVetWithTheseAnimals", "Yes")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-2 mb-4 align-content-baseline"
          label="No"
          labelClass={
            applyForAdoptionApplicationFormData?.haveYouUsedTheVetWithTheseAnimals ===
            "No"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="haveYouUsedTheVetWithTheseAnimals_no"
          checked={
            applyForAdoptionApplicationFormData?.haveYouUsedTheVetWithTheseAnimals ===
            "No"
          }
          value={
            applyForAdoptionApplicationFormData?.haveYouUsedTheVetWithTheseAnimals
          }
          error={
            applyForAdoptionApplicationFormDataErrors?.haveYouUsedTheVetWithTheseAnimalsError
          }
          onChange={() => onChange("haveYouUsedTheVetWithTheseAnimals", "No")}
        />
        {applyForAdoptionApplicationFormData?.haveYouUsedTheVetWithTheseAnimals ===
          "No" && (
          <label
            className={
              applyForAdoptionApplicationFormDataErrors?.haveYouUsedTheVetWithTheseAnimalsError
                ? "theme-3-input-label theme-text-color-error px-5 default-px-10"
                : "theme-3-input-label px-5 default-px-10"
            }
          >
            Please tell us about your PAST pets:
          </label>
        )}
      </div>
      {applyForAdoptionApplicationFormData?.haveYouUsedTheVetWithTheseAnimals ===
        "No" && (
        <ApplyForAdoptionPastVetsForm
          applyForAdoptionPastVetsFormData={
            applyForAdoptionApplicationFormData?.listPreviousVets
          }
          addPastVet={(currentVet: ApplyForAdoptionPastVetsFormDataVet) =>
            addPastVet(currentVet)
          }
          deletePastVet={(index: number) => deletePastVet(index)}
        />
      )}
      <div className="grid grid-cols-1 mt-4 text-left">
        <label
          className={
            applyForAdoptionApplicationFormDataErrors?.catWillLiveLocationError
              ? "theme-3-input-label theme-text-color-error px-5 default-px-10"
              : "theme-3-input-label px-5 default-px-10"
          }
        >
          Our cats will live:
        </label>
        <RadioButton
          containerClass="px-5 default-px-10 mt-4 mb-2 align-content-baseline"
          label="Indoors Only"
          labelClass={
            applyForAdoptionApplicationFormData?.catWillLiveLocation ===
            "Indoors Only"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="catWillLiveLocation_indoors_only"
          checked={
            applyForAdoptionApplicationFormData?.catWillLiveLocation ===
            "Indoors Only"
          }
          value={applyForAdoptionApplicationFormData?.catWillLiveLocation}
          error={
            applyForAdoptionApplicationFormDataErrors?.catWillLiveLocationError
          }
          onChange={() => onChange("catWillLiveLocation", "Indoors Only")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-4 mb-2 align-content-baseline"
          label="Indoors/Outdoors"
          labelClass={
            applyForAdoptionApplicationFormData?.catWillLiveLocation ===
            "Indoors/Outdoors"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="catWillLiveLocation_indoors_outdoors"
          checked={
            applyForAdoptionApplicationFormData?.catWillLiveLocation ===
            "Indoors/Outdoors"
          }
          value={applyForAdoptionApplicationFormData?.catWillLiveLocation}
          error={
            applyForAdoptionApplicationFormDataErrors?.catWillLiveLocationError
          }
          onChange={() => onChange("catWillLiveLocation", "Indoors/Outdoors")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-4 mb-2 align-content-baseline"
          label="Outdoors Only"
          labelClass={
            applyForAdoptionApplicationFormData?.catWillLiveLocation ===
            "Outdoors Only"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="catWillLiveLocation_outdoors_only"
          checked={
            applyForAdoptionApplicationFormData?.catWillLiveLocation ===
            "Outdoors Only"
          }
          value={applyForAdoptionApplicationFormData?.catWillLiveLocation}
          error={
            applyForAdoptionApplicationFormDataErrors?.catWillLiveLocationError
          }
          onChange={() => onChange("catWillLiveLocation", "Outdoors Only")}
        />
        <label
          className={
            applyForAdoptionApplicationFormDataErrors?.petDoorError
              ? "theme-3-input-label theme-text-color-error px-5 default-px-10"
              : "theme-3-input-label px-5 default-px-10"
          }
        >
          Do you have a pet door?
        </label>
        <RadioButton
          containerClass="px-5 default-px-10 mt-4 mb-2 align-content-baseline"
          label="Yes"
          labelClass={
            applyForAdoptionApplicationFormData?.petDoor === "Yes"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="petDoor_yes"
          checked={applyForAdoptionApplicationFormData?.petDoor === "Yes"}
          value={applyForAdoptionApplicationFormData?.petDoor}
          error={applyForAdoptionApplicationFormDataErrors?.petDoorError}
          onChange={() => onChange("petDoor", "Yes")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-2 mb-4 align-content-baseline"
          label="No"
          labelClass={
            applyForAdoptionApplicationFormData?.petDoor === "No"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="petDoor_no"
          checked={applyForAdoptionApplicationFormData?.petDoor === "No"}
          value={applyForAdoptionApplicationFormData?.petDoor}
          error={applyForAdoptionApplicationFormDataErrors?.petDoorError}
          onChange={() => onChange("petDoor", "No")}
        />
      </div>
      <div className="grid grid-cols-1 default-grid-cols-2 mt-4">
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="How will this animal be confined outside?"
          labelClass="theme-3-input-label"
          name="catNames"
          value={applyForAdoptionApplicationFormData?.animalConfined}
          error={applyForAdoptionApplicationFormDataErrors?.animalConfinedError}
          onChange={(e) => onChange("animalConfined", e.target.value)}
          onBlur={() => {
            var output =
              applyForAdoptionApplicationFormData?.animalConfined?.trim();
            onBlur("animalConfined", output);
          }}
        />
      </div>
      <div className="grid grid-cols-1 mt-4 text-left">
        <label
          className={
            applyForAdoptionApplicationFormDataErrors?.willYouDeclawCatError
              ? "theme-3-input-label theme-text-color-error px-5 default-px-10"
              : "theme-3-input-label px-5 default-px-10"
          }
        >
          Do you plan to declaw this pet?
        </label>
        <RadioButton
          containerClass="px-5 default-px-10 mt-4 mb-2 align-content-baseline"
          label="Yes"
          labelClass={
            applyForAdoptionApplicationFormData?.willYouDeclawCat === "Yes"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="willYouDeclawCat_yes"
          checked={
            applyForAdoptionApplicationFormData?.willYouDeclawCat === "Yes"
          }
          value={applyForAdoptionApplicationFormData?.willYouDeclawCat}
          error={
            applyForAdoptionApplicationFormDataErrors?.willYouDeclawCatError
          }
          onChange={() => onChange("willYouDeclawCat", "Yes")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-2 mb-4 align-content-baseline"
          label="No"
          labelClass={
            applyForAdoptionApplicationFormData?.willYouDeclawCat === "No"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="willYouDeclawCat_no"
          checked={
            applyForAdoptionApplicationFormData?.willYouDeclawCat === "No"
          }
          value={applyForAdoptionApplicationFormData?.willYouDeclawCat}
          error={
            applyForAdoptionApplicationFormDataErrors?.willYouDeclawCatError
          }
          onChange={() => onChange("willYouDeclawCat", "No")}
        />
        <label
          className={
            applyForAdoptionApplicationFormDataErrors?.longTermCommitmentError
              ? "theme-3-input-label theme-text-color-error px-5 default-px-10"
              : "theme-3-input-label px-5 default-px-10"
          }
        >
          Are you prepared to make a long term (10-15 years) commitment to care
          for this pet?
        </label>
        <RadioButton
          containerClass="px-5 default-px-10 mt-4 mb-2 align-content-baseline"
          label="Yes"
          labelClass={
            applyForAdoptionApplicationFormData?.longTermCommitment === "Yes"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="longTermCommitment_yes"
          checked={
            applyForAdoptionApplicationFormData?.longTermCommitment === "Yes"
          }
          value={applyForAdoptionApplicationFormData?.longTermCommitment}
          error={
            applyForAdoptionApplicationFormDataErrors?.longTermCommitmentError
          }
          onChange={() => onChange("longTermCommitment", "Yes")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-2 mb-4 align-content-baseline"
          label="No"
          labelClass={
            applyForAdoptionApplicationFormData?.longTermCommitment === "No"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="longTermCommitment_no"
          checked={
            applyForAdoptionApplicationFormData?.longTermCommitment === "No"
          }
          value={applyForAdoptionApplicationFormData?.longTermCommitment}
          error={
            applyForAdoptionApplicationFormDataErrors?.longTermCommitmentError
          }
          onChange={() => onChange("longTermCommitment", "No")}
        />
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="What provisions will you make for the pet should you be unable to continue care for it?"
          labelClass="theme-3-input-label"
          name="catNames"
          value={applyForAdoptionApplicationFormData?.provisions}
          error={applyForAdoptionApplicationFormDataErrors?.provisionsError}
          onChange={(e) => onChange("provisions", e.target.value)}
          onBlur={() => {
            var output =
              applyForAdoptionApplicationFormData?.provisions?.trim();
            onBlur("provisions", output);
          }}
        />
        <label
          className={
            applyForAdoptionApplicationFormDataErrors?.awareOfCostError
              ? "theme-3-input-label theme-text-color-error px-5 default-px-10"
              : "theme-3-input-label px-5 default-px-10"
          }
        >
          Are you aware of the costs involved and your responsibility for the
          veterinary care, food, flea/tick preventative, and the housing of this
          animal?
        </label>
        <RadioButton
          containerClass="px-5 default-px-10 mt-4 mb-2 align-content-baseline"
          label="Yes"
          labelClass={
            applyForAdoptionApplicationFormData?.awareOfCost === "Yes"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="awareOfCost_yes"
          checked={applyForAdoptionApplicationFormData?.awareOfCost === "Yes"}
          value={applyForAdoptionApplicationFormData?.awareOfCost}
          error={applyForAdoptionApplicationFormDataErrors?.awareOfCostError}
          onChange={() => onChange("awareOfCost", "Yes")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-2 mb-4 align-content-baseline"
          label="No"
          labelClass={
            applyForAdoptionApplicationFormData?.awareOfCost === "No"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="awareOfCost_no"
          checked={applyForAdoptionApplicationFormData?.awareOfCost === "No"}
          value={applyForAdoptionApplicationFormData?.awareOfCost}
          error={applyForAdoptionApplicationFormDataErrors?.awareOfCostError}
          onChange={() => onChange("awareOfCost", "No")}
        />
        <label
          className={
            applyForAdoptionApplicationFormDataErrors?.willingToHandleCostError
              ? "theme-3-input-label theme-text-color-error px-5 default-px-10"
              : "theme-3-input-label px-5 default-px-10"
          }
        >
          Are you willing to meet these costs?
        </label>
        <RadioButton
          containerClass="px-5 default-px-10 mt-4 mb-2 align-content-baseline"
          label="Yes"
          labelClass={
            applyForAdoptionApplicationFormData?.willingToHandleCost === "Yes"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="willingToHandleCost_yes"
          checked={
            applyForAdoptionApplicationFormData?.willingToHandleCost === "Yes"
          }
          value={applyForAdoptionApplicationFormData?.willingToHandleCost}
          error={
            applyForAdoptionApplicationFormDataErrors?.willingToHandleCostError
          }
          onChange={() => onChange("willingToHandleCost", "Yes")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-2 mb-4 align-content-baseline"
          label="No"
          labelClass={
            applyForAdoptionApplicationFormData?.willingToHandleCost === "No"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="willingToHandleCost_no"
          checked={
            applyForAdoptionApplicationFormData?.willingToHandleCost === "No"
          }
          value={applyForAdoptionApplicationFormData?.willingToHandleCost}
          error={
            applyForAdoptionApplicationFormDataErrors?.willingToHandleCostError
          }
          onChange={() => onChange("willingToHandleCost", "No")}
        />
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="Some animals will adjust to their new home within a matter of hours, while others may take considerably longer. What amount of time would you be willing to give this new pet to make this transition in your home?"
          labelClass="theme-3-input-label"
          name="timeWithCat"
          value={applyForAdoptionApplicationFormData?.timeWithCat}
          error={applyForAdoptionApplicationFormDataErrors?.timeWithCatError}
          onChange={(e) => onChange("timeWithCat", e.target.value)}
          onBlur={() => {
            var output =
              applyForAdoptionApplicationFormData?.timeWithCat?.trim();
            onBlur("timeWithCat", output);
          }}
        />
        <label
          className={
            applyForAdoptionApplicationFormDataErrors?.animalAbuseError
              ? "theme-3-input-label theme-text-color-error px-5 default-px-10"
              : "theme-3-input-label px-5 default-px-10"
          }
        >
          Have you ever been charged with or convicted of animal abuse and/or
          neglect?
        </label>
        <RadioButton
          containerClass="px-5 default-px-10 mt-4 mb-2 align-content-baseline"
          label="Yes"
          labelClass={
            applyForAdoptionApplicationFormData?.animalAbuse === "Yes"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="animalAbuse_yes"
          checked={applyForAdoptionApplicationFormData?.animalAbuse === "Yes"}
          value={applyForAdoptionApplicationFormData?.animalAbuse}
          error={applyForAdoptionApplicationFormDataErrors?.animalAbuseError}
          onChange={() => onChange("animalAbuse", "Yes")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-2 mb-4 align-content-baseline"
          label="No"
          labelClass={
            applyForAdoptionApplicationFormData?.animalAbuse === "No"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="animalAbuse_no"
          checked={applyForAdoptionApplicationFormData?.animalAbuse === "No"}
          value={applyForAdoptionApplicationFormData?.animalAbuse}
          error={applyForAdoptionApplicationFormDataErrors?.animalAbuseError}
          onChange={() => onChange("animalAbuse", "No")}
        />
        {applyForAdoptionApplicationFormData?.animalAbuse === "Yes" && (
          <Input
            containerClass="px-5 default-px-10 my-4 align-content-baseline"
            className="theme-3-input"
            label="If yes, please explain:"
            labelClass="theme-3-input-label"
            name="animalAbuseWhy"
            value={applyForAdoptionApplicationFormData?.animalAbuseWhy}
            error={
              applyForAdoptionApplicationFormDataErrors?.animalAbuseWhyError
            }
            onChange={(e) => onChange("animalAbuseWhy", e.target.value)}
            onBlur={() => {
              var output =
                applyForAdoptionApplicationFormData?.animalAbuseWhy?.trim();
              onBlur("animalAbuseWhy", output);
            }}
          />
        )}
        <label
          className={
            applyForAdoptionApplicationFormDataErrors?.animalAbuseError
              ? "theme-3-input-label theme-text-color-error px-5 default-px-10"
              : "theme-3-input-label px-5 default-px-10"
          }
        >
          Has anyone in your household ever been charged with or convicted of
          animal abuse and/or neglect?
        </label>
        <RadioButton
          containerClass="px-5 default-px-10 mt-4 mb-2 align-content-baseline"
          label="Yes"
          labelClass={
            applyForAdoptionApplicationFormData?.familyAnimalAbuse === "Yes"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="familyAnimalAbuse_yes"
          checked={
            applyForAdoptionApplicationFormData?.familyAnimalAbuse === "Yes"
          }
          value={applyForAdoptionApplicationFormData?.familyAnimalAbuse}
          error={
            applyForAdoptionApplicationFormDataErrors?.familyAnimalAbuseError
          }
          onChange={() => onChange("familyAnimalAbuse", "Yes")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-2 mb-4 align-content-baseline"
          label="No"
          labelClass={
            applyForAdoptionApplicationFormData?.familyAnimalAbuse === "No"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="familyAnimalAbuse_no"
          checked={
            applyForAdoptionApplicationFormData?.familyAnimalAbuse === "No"
          }
          value={applyForAdoptionApplicationFormData?.familyAnimalAbuse}
          error={
            applyForAdoptionApplicationFormDataErrors?.familyAnimalAbuseError
          }
          onChange={() => onChange("familyAnimalAbuse", "No")}
        />
        {applyForAdoptionApplicationFormData?.familyAnimalAbuse === "Yes" && (
          <Input
            containerClass="px-5 default-px-10 my-4 align-content-baseline"
            className="theme-3-input"
            label="If yes, please explain:"
            labelClass="theme-3-input-label"
            name="familyAnimalAbuse"
            value={applyForAdoptionApplicationFormData?.familyAnimalAbuseWhy}
            error={
              applyForAdoptionApplicationFormDataErrors?.familyAnimalAbuseWhyError
            }
            onChange={(e) => onChange("familyAnimalAbuseWhy", e.target.value)}
            onBlur={() => {
              var output =
                applyForAdoptionApplicationFormData?.familyAnimalAbuseWhy?.trim();
              onBlur("familyAnimalAbuseWhy", output);
            }}
          />
        )}
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="Additional Comments you want to share with us:"
          labelClass="theme-3-input-label"
          name="additionalComments"
          value={applyForAdoptionApplicationFormData?.additionalComments}
          error={
            applyForAdoptionApplicationFormDataErrors?.additionalCommentsError
          }
          onChange={(e) => onChange("additionalComments", e.target.value)}
          onBlur={() => {
            var output =
              applyForAdoptionApplicationFormData?.additionalComments?.trim();
            onBlur("additionalComments", output);
          }}
        />
      </div>
      <h1 className="theme-text-color-3 theme-font text-5xl mb-1 px-5 default-px-10 text-left">
        We Ask You To Seriously Think About The Following:
      </h1>
      <ol className="px-5 default-px-10 list-style-inside">
        <li className="theme-text-color-3 text-xl default-my-2 my-4 text-left">
          You must be prepared to pay for veterinary bills.
        </li>
        <li className="theme-text-color-3 text-xl default-my-2 my-4 text-left">
          You must obey local leash laws and other animal welfare laws.
        </li>
        <li className="theme-text-color-3 text-xl default-my-2 my-4 text-left">
          You must agree to have your pet spayed or neutered to prevent unwanted
          litters.
        </li>
        <li className="theme-text-color-3 text-xl default-my-2 my-4 text-left">
          If you are not committed to caring for and loving this pet for a
          lifetime, DON'T TAKE HIM/HER HOME!
        </li>
      </ol>
      <div className="grid grid-cols-1 mt-4 text-left">
        <label
          className={
            applyForAdoptionApplicationFormDataErrors?.howDidYouHearError
              ? "theme-3-input-label theme-text-color-error px-5 default-px-10"
              : "theme-3-input-label px-5 default-px-10"
          }
        >
          How did you hear about us (check all that apply):
        </label>
        <RadioButton
          containerClass="px-5 default-px-10 mt-4 mb-2 align-content-baseline"
          label="Online"
          labelClass={
            applyForAdoptionApplicationFormData?.howDidYouHear?.includes(
              "Online"
            )
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="howDidYouHear_online"
          checked={
            applyForAdoptionApplicationFormData?.howDidYouHear?.includes(
              "Online"
            )
              ? true
              : false
          }
          value={
            applyForAdoptionApplicationFormData?.howDidYouHear?.includes(
              "Online"
            )
              ? "Online"
              : ""
          }
          error={applyForAdoptionApplicationFormDataErrors?.howDidYouHearError}
          onChange={() => applyForAdoptionApplicationFormData?.howDidYouHear}
          onClick={() => onChangeHowDidYouHear("Online")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-4 mb-2 align-content-baseline"
          label="TV/Radio"
          labelClass={
            applyForAdoptionApplicationFormData?.howDidYouHear?.includes(
              "TV/Radio"
            )
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="howDidYouHear_tv_radio"
          checked={
            applyForAdoptionApplicationFormData?.howDidYouHear?.includes(
              "TV/Radio"
            )
              ? true
              : false
          }
          value={
            applyForAdoptionApplicationFormData?.howDidYouHear?.includes(
              "TV/Radio"
            )
              ? "TV/Radio"
              : ""
          }
          error={applyForAdoptionApplicationFormDataErrors?.howDidYouHearError}
          onChange={() => applyForAdoptionApplicationFormData?.howDidYouHear}
          onClick={() => onChangeHowDidYouHear("TV/Radio")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-4 mb-2 align-content-baseline"
          label="Newspaper"
          labelClass={
            applyForAdoptionApplicationFormData?.howDidYouHear?.includes(
              "Newspaper"
            )
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="howDidYouHear_newspaper"
          checked={
            applyForAdoptionApplicationFormData?.howDidYouHear?.includes(
              "Newspaper"
            )
              ? true
              : false
          }
          value={
            applyForAdoptionApplicationFormData?.howDidYouHear?.includes(
              "Newspaper"
            )
              ? "Newspaper"
              : ""
          }
          error={applyForAdoptionApplicationFormDataErrors?.howDidYouHearError}
          onChange={() => applyForAdoptionApplicationFormData?.howDidYouHear}
          onClick={() => onChangeHowDidYouHear("Newspaper")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-4 mb-2 align-content-baseline"
          label="Facebook"
          labelClass={
            applyForAdoptionApplicationFormData?.howDidYouHear?.includes(
              "Facebook"
            )
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="howDidYouHear_facebook"
          checked={
            applyForAdoptionApplicationFormData?.howDidYouHear?.includes(
              "Facebook"
            )
              ? true
              : false
          }
          value={
            applyForAdoptionApplicationFormData?.howDidYouHear?.includes(
              "Facebook"
            )
              ? "Facebook"
              : ""
          }
          error={applyForAdoptionApplicationFormDataErrors?.howDidYouHearError}
          onChange={() => applyForAdoptionApplicationFormData?.howDidYouHear}
          onClick={() => onChangeHowDidYouHear("Facebook")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-4 mb-2 align-content-baseline"
          label="Friend/Referral"
          labelClass={
            applyForAdoptionApplicationFormData?.howDidYouHear?.includes(
              "Friend/Referral"
            )
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="howDidYouHear_friend_referral"
          checked={
            applyForAdoptionApplicationFormData?.howDidYouHear?.includes(
              "Friend/Referral"
            )
              ? true
              : false
          }
          value={
            applyForAdoptionApplicationFormData?.howDidYouHear?.includes(
              "Friend/Referral"
            )
              ? "Friend/Referral"
              : ""
          }
          error={applyForAdoptionApplicationFormDataErrors?.howDidYouHearError}
          onChange={() => applyForAdoptionApplicationFormData?.howDidYouHear}
          onClick={() => onChangeHowDidYouHear("Friend/Referral")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-4 mb-2 align-content-baseline"
          label="Smitten Kitten Cat Cafe"
          labelClass={
            applyForAdoptionApplicationFormData?.howDidYouHear?.includes(
              "Smitten Kitten Cat Cafe"
            )
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="howDidYouHear_Smitten_Kitten_Cat_Cafe"
          checked={
            applyForAdoptionApplicationFormData?.howDidYouHear?.includes(
              "Smitten Kitten Cat Cafe"
            )
              ? true
              : false
          }
          value={
            applyForAdoptionApplicationFormData?.howDidYouHear?.includes(
              "Smitten Kitten Cat Cafe"
            )
              ? "Smitten Kitten Cat Cafe"
              : ""
          }
          error={applyForAdoptionApplicationFormDataErrors?.howDidYouHearError}
          onChange={() => applyForAdoptionApplicationFormData?.howDidYouHear}
          onClick={() => onChangeHowDidYouHear("Smitten Kitten Cat Cafe")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-4 mb-2 align-content-baseline"
          label="Other"
          labelClass={
            applyForAdoptionApplicationFormData?.howDidYouHear?.includes(
              "Other"
            )
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="howDidYouHear_other"
          checked={
            applyForAdoptionApplicationFormData?.howDidYouHear?.includes(
              "Other"
            )
              ? true
              : false
          }
          value={
            applyForAdoptionApplicationFormData?.howDidYouHear?.includes(
              "Other"
            )
              ? "Other"
              : ""
          }
          error={applyForAdoptionApplicationFormDataErrors?.howDidYouHearError}
          onChange={() => applyForAdoptionApplicationFormData?.howDidYouHear}
          onClick={() => onChangeHowDidYouHear("Other")}
        />
      </div>
      <p className="theme-text-color-3 text-xl my-6 px-5 default-px-10 text-center">
        I give a representative of the Hancock County Humane Society permission
        to check my veterinary records. I also give my permission for the
        representative to come onto my property to check housing and fencing
        arrangements as needed. All applications may be subject to a background
        check and a review by the HCHS board. We reserve the right to deny any
        application.
      </p>
      <div className="grid grid-cols-1 default-grid-cols-2 mt-4">
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="Signature"
          labelClass="theme-3-input-label"
          name="catNames"
          value={applyForAdoptionApplicationFormData?.signature}
          error={applyForAdoptionApplicationFormDataErrors?.signatureError}
          onChange={(e) => onChange("signature", e.target.value)}
          onBlur={() => {
            var output = applyForAdoptionApplicationFormData?.signature?.trim();
            onBlur("signature", output);
          }}
        />
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="Driver's License #"
          labelClass="theme-3-input-label"
          name="catNames"
          value={applyForAdoptionApplicationFormData?.driverLicense}
          error={applyForAdoptionApplicationFormDataErrors?.driverLicenseError}
          onChange={(e) => onChange("driverLicense", e.target.value)}
          onBlur={() => {
            var output =
              applyForAdoptionApplicationFormData?.driverLicense?.trim();
            onBlur("driverLicense", output);
          }}
        />
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="Date of Birth"
          labelClass="theme-3-input-label"
          name="catNames"
          value={applyForAdoptionApplicationFormData?.dateOfBirth}
          error={applyForAdoptionApplicationFormDataErrors?.dateOfBirthError}
          placeholder="MM/DD/YYYY"
          onChange={(e) => {
            var output = formatDate(e.target.value);
            if (output || output === "") onChange("dateOfBirth", output);
          }}
          onBlur={() => {
            var output =
              applyForAdoptionApplicationFormData?.dateOfBirth?.trim();
            onBlur("dateOfBirth", output);
          }}
        />
      </div>
    </div>
  );
};
