type props = {
  header?: string;
  counter?: string | null;
};

export const CounterTextBox: React.FunctionComponent<props> = ({
  header,
  counter,
}) => {
  return (
    <div className="grid grid-cols-2 self-center w-near-full theme-bg-color-3 border-theme-gradient-boy default-w-1/3 my-4">
      <h1 className="text-white theme-font text-4xl default-text-5xl text-center align-self-center my-0 py-5 default-px-5">
        {header}
      </h1>
      <h1 className="theme-text-color-1 theme-font text-5xl default-text-5xl text-center align-self-center my-0 py-5 default-px-5">
        {counter}
      </h1>
    </div>
  );
};
