import type { AxiosInstance } from "axios";
import Configuration from "../configuration.json";
import {
  ContentfulGetEntryResponse,
  CONTENTFUL_GET_ENTRY_RESPONSE,
} from "./types/contentful-content";
import { APIErrorResponse, API_ERROR_RESPONSE } from "./types/api-error";

export const NAVBAR_HEADERS = Configuration.CONTENTFUL_PAGES.NAVBAR_HEADERS;
export const FOOTER_CONTENT = Configuration.CONTENTFUL_PAGES.FOOTER_CONTENT;
export const ONLINE_MENU_PAGE = Configuration.CONTENTFUL_PAGES.ONLINE_MENU_PAGE;
export const HOME_PAGE = Configuration.CONTENTFUL_PAGES.HOME;
export const OUR_CATS_PAGE = Configuration.CONTENTFUL_PAGES.OUR_CATS;
export const TESTIMONIALS_PAGE = Configuration.CONTENTFUL_PAGES.TESTIMONIALS;
export const FAQ_PAGE = Configuration.CONTENTFUL_PAGES.FAQ;
export const CAREERS_PAGE = Configuration.CONTENTFUL_PAGES.CAREERS;
export const APPLY_PAGE = Configuration.CONTENTFUL_PAGES.APPLY_PAGE;
export const RESERVATIONS_PAGE = Configuration.CONTENTFUL_PAGES.RESERVATIONS;
export const GIFT_CARDS_PAGE = Configuration.CONTENTFUL_PAGES.GIFT_CARDS_PAGE;
export const EVENTS_PAGE = Configuration.CONTENTFUL_PAGES.EVENTS;

const CONTENTFUL_SPACE = Configuration.CONTENTFUL_SPACE;
const CONTENTFUL_ACCESS_TOKEN = Configuration.CONTENTFUL_ACCESS_TOKEN;

export const contentfulAPI = (api: AxiosInstance) => ({
  contentful: {
    getEntry: async (
      page: string
    ): Promise<ContentfulGetEntryResponse | APIErrorResponse> =>
      await api
        .get(
          "/spaces/" +
            CONTENTFUL_SPACE +
            "/entries/" +
            page +
            "?access_token=" +
            CONTENTFUL_ACCESS_TOKEN
        )
        .then(
          ({ data }) => {
            return { data: data, type: CONTENTFUL_GET_ENTRY_RESPONSE };
          },
          ({ response }) => ({
            ...(response || { data: {} }).data,
            type: API_ERROR_RESPONSE,
          })
        ),
  },
});
