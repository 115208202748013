type props = {
  index: number;
  onClick: Function;
  images: string[];
};

export const Slideshow: React.FunctionComponent<props> = ({
  index,
  onClick,
  images,
}) => {
  return (
    <div className="slideshow-container">
      {images?.map((img, i) => (
        <div
          key={img + i}
          className={index === i ? `slideshow-fade` : "display-none"}
        >
          <img
            className="w-full default-w-11/12 justify-self-center pointer"
            src={img}
            onClick={() => onClick(i)}
            alt=""
          />
        </div>
      ))}
    </div>
  );
};
