import { useEffect, useState } from "react";
import { useApi } from "../../api";
import emailjs from '@emailjs/browser'
import Configuration from "../../configuration.json";
import { CAREERS_PAGE } from "../../api/contentful";
import {
  CareersContactFormData,
  CareersContactFormDataErrors,
} from "../../api/types/careers-contact-form-data";
import {
  CONTENTFUL_GET_ENTRY_RESPONSE,
  ContentfulContent,
} from "../../api/types/contentful-content";
import { CareerTile } from "../../components/career-tile";
import { CareersContactForm } from "../../components/careers-contact-form";
import { UnitedStates } from "../../lib/validation-helpers";
import { EmailJsRequest } from "../../api/types/emailjs-email";

export const Careers = () => {
  const templateId = Configuration.EMAILJS_TEMPLATEID;
  const api = useApi();
  const [contentfulContent, setContentfulContent] =
    useState<ContentfulContent>();
  const [careersContactFormData, setCareersContactFormData] =
    useState<CareersContactFormData>({
      firstName: "",
      lastName: "",
      address: "",
      city: "",
      state: "",
      zipCode: "",
      email: "",
      phoneNumber: "",
      availability: "",
      extraQuestion1: "",
      extraQuestion2: "",
      extraQuestion3: "",
      extraQuestion4: "",
      extraQuestion5: "",
    });
  const [careersContactFormDataErrors, setCareersContactFormDataErrors] =
    useState<CareersContactFormDataErrors>({});
  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.contentful.getEntry(CAREERS_PAGE);
      if (response.type !== CONTENTFUL_GET_ENTRY_RESPONSE) return null;
      setContentfulContent(response.data.fields);
      return response.data.fields;
    };
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const submitForm = () => {
    var careerFormDataIsValid = validateCareerFormData();
    if (!careerFormDataIsValid || !templateId) return;
    const message = buildCareerApplicationMessage();
    setLoading(true);
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICEID ?? "",
        templateId,
        message,
        process.env.REACT_APP_EMAILJS_USERID
      )
      .then(
        () => {
          setLoading(false);
          setFormSubmitted(true);
        },
        () => {
          setLoading(false);
          setFormError(true);
        }
      );
  };

  const buildCareerApplicationMessage = (): EmailJsRequest => {
    const careerApplicationMessage: EmailJsRequest = {
      firstName: careersContactFormData.firstName,
      lastName: careersContactFormData.lastName,
      address: careersContactFormData.address,
      city: careersContactFormData.city,
      state: careersContactFormData.state,
      zipCode: careersContactFormData.zipCode,
      email: careersContactFormData.email,
      phoneNumber: careersContactFormData.phoneNumber,
      availability: careersContactFormData.availability,
      extraQuestion1Question: contentfulContent?.careerQuestion1?.text,
      extraQuestion1Answer: careersContactFormData.extraQuestion1,
      extraQuestion2Question: contentfulContent?.careerQuestion2?.text,
      extraQuestion2Answer: careersContactFormData.extraQuestion2,
      extraQuestion3Question: contentfulContent?.careerQuestion3?.text,
      extraQuestion3Answer: careersContactFormData.extraQuestion3,
      extraQuestion4Question: contentfulContent?.careerQuestion4?.text,
      extraQuestion4Answer: careersContactFormData.extraQuestion4,
      extraQuestion5Question: contentfulContent?.careerQuestion5?.text,
      extraQuestion5Answer: careersContactFormData.extraQuestion5,
    };
    return careerApplicationMessage;
  };

  const validateCareerFormData = (): boolean => {
    var valid = true;
    var emailValidationRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (
      !careersContactFormData.firstName ||
      careersContactFormData.firstName.trim().length < 3
    ) {
      setCareersContactFormDataErrors((careersContactFormDataErrors) => ({
        ...careersContactFormDataErrors,
        firstNameError: "Please enter a first name.",
      }));
      valid = false;
    } else {
      setCareersContactFormDataErrors((careersContactFormDataErrors) => ({
        ...careersContactFormDataErrors,
        firstNameError: undefined,
      }));
    }

    if (
      !careersContactFormData.lastName ||
      careersContactFormData.lastName.trim().length < 3
    ) {
      setCareersContactFormDataErrors((careersContactFormDataErrors) => ({
        ...careersContactFormDataErrors,
        lastNameError: "Please enter a last name.",
      }));
      valid = false;
    } else {
      setCareersContactFormDataErrors((careersContactFormDataErrors) => ({
        ...careersContactFormDataErrors,
        lastNameError: undefined,
      }));
    }

    if (
      !careersContactFormData.address ||
      careersContactFormData.address.trim().length < 3
    ) {
      setCareersContactFormDataErrors((careersContactFormDataErrors) => ({
        ...careersContactFormDataErrors,
        addressError: "Please enter an address.",
      }));
      valid = false;
    } else {
      setCareersContactFormDataErrors((careersContactFormDataErrors) => ({
        ...careersContactFormDataErrors,
        addressError: undefined,
      }));
    }

    if (
      !careersContactFormData.city ||
      careersContactFormData.city.trim().length < 3
    ) {
      setCareersContactFormDataErrors((careersContactFormDataErrors) => ({
        ...careersContactFormDataErrors,
        cityError: "Please enter a city.",
      }));
      valid = false;
    } else {
      setCareersContactFormDataErrors((careersContactFormDataErrors) => ({
        ...careersContactFormDataErrors,
        cityError: undefined,
      }));
    }

    if (
      !careersContactFormData.state ||
      careersContactFormData.state.trim().length !== 2
    ) {
      setCareersContactFormDataErrors((careersContactFormDataErrors) => ({
        ...careersContactFormDataErrors,
        stateError: "Please enter a state.",
      }));
      valid = false;
    } else if (!UnitedStates.includes(careersContactFormData.state)) {
      setCareersContactFormDataErrors((careersContactFormDataErrors) => ({
        ...careersContactFormDataErrors,
        stateError: "Please enter a United State code (ex. NY).",
      }));
      valid = false;
    } else {
      setCareersContactFormDataErrors((careersContactFormDataErrors) => ({
        ...careersContactFormDataErrors,
        stateError: undefined,
      }));
    }

    if (!careersContactFormData.zipCode) {
      setCareersContactFormDataErrors((careersContactFormDataErrors) => ({
        ...careersContactFormDataErrors,
        zipCodeError: "Please enter a zip code.",
      }));
      valid = false;
    } else if (careersContactFormData.zipCode.trim().length !== 5) {
      setCareersContactFormDataErrors((careersContactFormDataErrors) => ({
        ...careersContactFormDataErrors,
        zipCodeError: "Please enter a 5 digit zip code.",
      }));
      valid = false;
    } else {
      setCareersContactFormDataErrors((careersContactFormDataErrors) => ({
        ...careersContactFormDataErrors,
        zipCodeError: undefined,
      }));
    }

    if (!careersContactFormData.email) {
      setCareersContactFormDataErrors((careersContactFormDataErrors) => ({
        ...careersContactFormDataErrors,
        emailError: "Please enter an email.",
      }));
      valid = false;
    } else if (!emailValidationRegex.test(careersContactFormData.email)) {
      setCareersContactFormDataErrors((careersContactFormDataErrors) => ({
        ...careersContactFormDataErrors,
        emailError: "Please enter a real email.",
      }));
      valid = false;
    } else {
      setCareersContactFormDataErrors((careersContactFormDataErrors) => ({
        ...careersContactFormDataErrors,
        emailError: undefined,
      }));
    }

    if (!careersContactFormData.phoneNumber) {
      setCareersContactFormDataErrors((careersContactFormDataErrors) => ({
        ...careersContactFormDataErrors,
        phoneNumberError: "Please enter a phone number.",
      }));
      valid = false;
    } else if (careersContactFormData.phoneNumber.trim().length !== 14) {
      setCareersContactFormDataErrors((careersContactFormDataErrors) => ({
        ...careersContactFormDataErrors,
        phoneNumberError:
          "Please enter a 10 digit phone number (XXX) XXX-XXXX.",
      }));
      valid = false;
    } else {
      setCareersContactFormDataErrors((careersContactFormDataErrors) => ({
        ...careersContactFormDataErrors,
        phoneNumberError: undefined,
      }));
    }

    if (
      !careersContactFormData.availability ||
      careersContactFormData.availability.trim().length < 3
    ) {
      setCareersContactFormDataErrors((careersContactFormDataErrors) => ({
        ...careersContactFormDataErrors,
        availabilityError: "Please enter your availability.",
      }));
      valid = false;
    } else {
      setCareersContactFormDataErrors((careersContactFormDataErrors) => ({
        ...careersContactFormDataErrors,
        availabilityError: undefined,
      }));
    }

    if (
      !careersContactFormData.extraQuestion1 ||
      careersContactFormData.extraQuestion1.trim().length < 3
    ) {
      setCareersContactFormDataErrors((careersContactFormDataErrors) => ({
        ...careersContactFormDataErrors,
        extraQuestion1Error: "This is a required question.",
      }));
      valid = false;
    } else {
      setCareersContactFormDataErrors((careersContactFormDataErrors) => ({
        ...careersContactFormDataErrors,
        extraQuestion1Error: undefined,
      }));
    }

    if (
      !careersContactFormData.extraQuestion2 ||
      careersContactFormData.extraQuestion2.trim().length < 3
    ) {
      setCareersContactFormDataErrors((careersContactFormDataErrors) => ({
        ...careersContactFormDataErrors,
        extraQuestion2Error: "This is a required question.",
      }));
      valid = false;
    } else {
      setCareersContactFormDataErrors((careersContactFormDataErrors) => ({
        ...careersContactFormDataErrors,
        extraQuestion2Error: undefined,
      }));
    }

    if (
      !careersContactFormData.extraQuestion3 ||
      careersContactFormData.extraQuestion3.trim().length < 3
    ) {
      setCareersContactFormDataErrors((careersContactFormDataErrors) => ({
        ...careersContactFormDataErrors,
        extraQuestion3Error: "This is a required question.",
      }));
      valid = false;
    } else {
      setCareersContactFormDataErrors((careersContactFormDataErrors) => ({
        ...careersContactFormDataErrors,
        extraQuestion3Error: undefined,
      }));
    }

    if (
      !careersContactFormData.extraQuestion4 ||
      careersContactFormData.extraQuestion4.trim().length < 3
    ) {
      setCareersContactFormDataErrors((careersContactFormDataErrors) => ({
        ...careersContactFormDataErrors,
        extraQuestion4Error: "This is a required question.",
      }));
      valid = false;
    } else {
      setCareersContactFormDataErrors((careersContactFormDataErrors) => ({
        ...careersContactFormDataErrors,
        extraQuestion4Error: undefined,
      }));
    }

    if (
      !careersContactFormData.extraQuestion5 ||
      careersContactFormData.extraQuestion5.trim().length < 3
    ) {
      setCareersContactFormDataErrors((careersContactFormDataErrors) => ({
        ...careersContactFormDataErrors,
        extraQuestion5Error: "This is a required question.",
      }));
      valid = false;
    } else {
      setCareersContactFormDataErrors((careersContactFormDataErrors) => ({
        ...careersContactFormDataErrors,
        extraQuestion5Error: undefined,
      }));
    }

    return valid;
  };

  return (
    <div className="page-container flex flex-col">
      <div className="grid grid-cols-1">
        {contentfulContent?.image1 && (
          <img
            className="w-full default-w-1/2 default-mt-4 justify-self-center"
            src={contentfulContent.image1}
            alt=""
          />
        )}
      </div>
      <div className="grid grid-cols-1">
        {contentfulContent?.headerText1 && (
          <h1 className="theme-text-color-3 theme-font text-5xl mb-1 px-5 default-px-10 text-center">
            {contentfulContent.headerText1}
          </h1>
        )}
        {contentfulContent?.career1 && contentfulContent.career1?.title && (
          <CareerTile career={contentfulContent.career1} />
        )}
        {contentfulContent?.career2 && contentfulContent.career2?.title && (
          <CareerTile career={contentfulContent.career2} />
        )}
        {contentfulContent?.career3 && contentfulContent.career3?.title && (
          <CareerTile career={contentfulContent.career3} />
        )}
        {contentfulContent?.career4 && contentfulContent.career4?.title && (
          <CareerTile career={contentfulContent.career4} />
        )}
        {contentfulContent?.career5 && contentfulContent.career5?.title && (
          <CareerTile career={contentfulContent.career5} />
        )}
        {contentfulContent?.headerText2 && !formSubmitted && (
          <h1 className="theme-text-color-3 theme-font text-5xl mb-1 px-5 default-px-10 text-center">
            {contentfulContent.headerText2}
          </h1>
        )}
        {formError && (
          <div className="grid grid-cols-1">
            <h1 className="theme-text-color-error theme-font text-5xl mb-0 px-5 default-px-10">
              Sorry!
            </h1>
            <p className="theme-text-color-error text-xl px-5 default-px-10">
              It appears something isn't working with our email service. Please
              try again later.
            </p>
          </div>
        )}
        {!formError && formSubmitted && (
          <div className="grid grid-cols-1">
            <h1 className="theme-text-color-3 theme-font text-5xl mb-0 px-5 default-px-10 text-center">
              THANK YOU!
            </h1>
            <p className="theme-text-color-3 text-xl px-5 default-px-10 text-center">
              A confirmation email has been sent to the email address you
              provided.
            </p>
          </div>
        )}
        {!formSubmitted && (
          <CareersContactForm
            loading={loading}
            extraQuestion1={contentfulContent?.careerQuestion1}
            extraQuestion2={contentfulContent?.careerQuestion2}
            extraQuestion3={contentfulContent?.careerQuestion3}
            extraQuestion4={contentfulContent?.careerQuestion4}
            extraQuestion5={contentfulContent?.careerQuestion5}
            careersContactFormData={careersContactFormData}
            careersContactFormDataErrors={careersContactFormDataErrors}
            onChange={(name: any, value: any) =>
              setCareersContactFormData((careersContactFormData) => ({
                ...careersContactFormData,
                [name]: value,
              }))
            }
            onBlur={(name: any, value: any) =>
              setCareersContactFormData((careersContactFormData) => ({
                ...careersContactFormData,
                [name]: value,
              }))
            }
          />
        )}
        {!formSubmitted && (
          <div className="grid grid-cols-1 self-center webkit-text-right pr-20">
            <div className="grid grid-cols-2 w-full self-center justify-self-left align-self-center">
              <label className="theme-3-label align-self-center">Step 2:</label>
              <p className="text-2xl">
                <a
                  className="theme-3-btn-small place-content-center"
                  href="mailto:hollymoss@smittenkittencatcafe.com?subject=Resume: SmittenKittenCatCafe.com&body="
                >
                  Email Resumè
                </a>
              </p>
            </div>
            <div className="grid grid-cols-2 w-full self-center justify-self-left align-self-center">
              <label className="theme-3-label align-self-center">Step 3:</label>
              <button
                className="theme-3-btn-small theme-font-alt"
                onClick={() => submitForm()}
              >
                Send Application
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
