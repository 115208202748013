import { ContentfulOurCatsCat } from "../api/types/contentful-content";
import { Slideshow } from "./slideshow";

type props = {
  cat: ContentfulOurCatsCat;
  index: number;
  changeImage: Function;
  onClick: Function;
};

export const CatViewer: React.FunctionComponent<props> = ({
  cat,
  index,
  changeImage,
  onClick,
}) => {
  return (
    <div className="cat-viewer-container">
      <div className="cat-viewer-inner-container">
        <p
          className="text-white text-4xl pointer hover-opacity h-4 py-0 pr-5 ml-9/10 my-0 text-right"
          onClick={() => onClick()}
        >
          X
        </p>
        {cat?.name && (
          <h1
            onClick={() => onClick()}
            className={
              cat?.gender.toLowerCase() === "male"
                ? "theme-text-color-boy theme-font text-5xl mt-0 mb-1 px-5 default-px-10 text-center"
                : cat?.gender.toLowerCase() === "female"
                ? "theme-text-color-girl theme-font text-5xl mt-0 mb-1 px-5 default-px-10 text-center"
                : "theme-text-color-3 theme-font text-5xl mt-0 mb-1 px-5 default-px-10 text-center"
            }
          >
            {cat.name}
          </h1>
        )}
        {cat?.age && cat?.gender && (
          <p className="text-white text-2xl px-5 my-2 text-center">
            {cat.age} old{" "}
            <b
              className={
                cat?.gender.toLowerCase() === "male"
                  ? "theme-text-color-boy"
                  : cat?.gender.toLowerCase() === "female"
                  ? "theme-text-color-girl"
                  : ""
              }
            >
              {cat.gender}
            </b>
          </p>
        )}
        {cat?.images && (
          <Slideshow
            images={cat.images}
            index={index}
            onClick={(index: number) => changeImage(index)}
          />
        )}
        {cat?.name && (
          <h1
            className={
              cat?.gender.toLowerCase() === "male"
                ? "theme-text-color-boy theme-font text-4xl mb-1 px-5 default-px-10 text-left mt-2"
                : cat?.gender.toLowerCase() === "female"
                ? "theme-text-color-girl theme-font text-4xl mb-1 px-5 default-px-10 text-left mt-2"
                : "theme-text-color-3 theme-font text-4xl mb-1 px-5 default-px-10 text-left mt-2"
            }
          >
            About our furry friend
          </h1>
        )}
        {cat?.description && (
          <p className="cat-viewer-cat-description default-px-10">
            {cat.description}
          </p>
        )}
      </div>
    </div>
  );
};
