import axios from 'axios'
import { contentfulAPI } from './contentful'

const contentfulApi = axios.create({
    baseURL: 'https://cdn.contentful.com',
})

const Api = () => ({
    ...contentfulAPI(contentfulApi),
})

export const useApi = () => {
    return Api()
}
