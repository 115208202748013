type props = {
  src: string;
  onClick: Function;
};

export const ImageViewer: React.FunctionComponent<props> = ({
  src,
  onClick,
}) => {
  return (
    <div className="image-viewer-container">
      <div className="image-viewer-inner-container">
        <img
          className="w-full justify-self-center pointer mb-4"
          src={src}
          alt=""
          onClick={() => onClick()}
        />
      </div>
    </div>
  );
};
