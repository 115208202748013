import {
  CareersContactFormData,
  CareersContactFormDataErrors,
} from "../api/types/careers-contact-form-data";
import { ContentfulCareerQuestion } from "../api/types/contentful-content";
import {
  formatPhoneNumber,
  lettersOnly,
  numbersOnly,
} from "../lib/text-helpers";
import { Input } from "./input";
import { TextArea } from "./textarea";

type props = {
  loading?: boolean;
  extraQuestion1?: ContentfulCareerQuestion;
  extraQuestion2?: ContentfulCareerQuestion;
  extraQuestion3?: ContentfulCareerQuestion;
  extraQuestion4?: ContentfulCareerQuestion;
  extraQuestion5?: ContentfulCareerQuestion;
  careersContactFormData?: CareersContactFormData;
  careersContactFormDataErrors?: CareersContactFormDataErrors;
  onChange: Function;
  onBlur: Function;
};

export const CareersContactForm: React.FunctionComponent<props> = ({
  loading,
  extraQuestion1,
  extraQuestion2,
  extraQuestion3,
  extraQuestion4,
  extraQuestion5,
  careersContactFormData,
  careersContactFormDataErrors,
  onChange,
  onBlur,
}) => {
  return (
    <div className={loading ? "div-loading" : ""}>
      <div className="grid grid-cols-1 default-grid-cols-2 mt-4">
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="First Name"
          labelClass="theme-3-input-label"
          name="firstName"
          value={careersContactFormData?.firstName}
          error={careersContactFormDataErrors?.firstNameError}
          onChange={(e) => onChange("firstName", e.target.value)}
          onBlur={() => {
            var output = careersContactFormData?.firstName?.trim();
            onBlur("firstName", output);
          }}
        />
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="Last Name"
          labelClass="theme-3-input-label"
          name="lastName"
          value={careersContactFormData?.lastName}
          error={careersContactFormDataErrors?.lastNameError}
          onChange={(e) => onChange("lastName", e.target.value)}
          onBlur={() => {
            var output = careersContactFormData?.lastName?.trim();
            onBlur("lastName", output);
          }}
        />
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="Address"
          labelClass="theme-3-input-label"
          name="address"
          value={careersContactFormData?.address}
          error={careersContactFormDataErrors?.addressError}
          onChange={(e) => onChange("address", e.target.value)}
          onBlur={() => {
            var output = careersContactFormData?.address?.trim();
            onBlur("address", output);
          }}
        />
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="City"
          labelClass="theme-3-input-label"
          name="city"
          value={careersContactFormData?.city}
          error={careersContactFormDataErrors?.cityError}
          onChange={(e) => {
            var output = lettersOnly(e.target.value);
            if (output || output === "") onChange("city", output);
          }}
          onBlur={() => {
            var output = careersContactFormData?.city?.trim();
            onBlur("city", output);
          }}
        />
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="State"
          labelClass="theme-3-input-label"
          name="state"
          maxLength={2}
          value={careersContactFormData?.state}
          error={careersContactFormDataErrors?.stateError}
          onChange={(e) => {
            var output = lettersOnly(e.target.value);
            if (output || output === "")
              onChange("state", output.toUpperCase());
          }}
          onBlur={() => {
            var output = careersContactFormData?.state?.trim();
            onBlur("state", output);
          }}
        />
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="Zip Code"
          labelClass="theme-3-input-label"
          name="zipCode"
          maxLength={5}
          value={careersContactFormData?.zipCode}
          error={careersContactFormDataErrors?.zipCodeError}
          onChange={(e) => {
            var output = numbersOnly(e.target.value);
            if (output || output === "") onChange("zipCode", output);
          }}
          onBlur={() => {
            var output = careersContactFormData?.zipCode?.trim();
            onBlur("zipCode", output);
          }}
        />
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="Email"
          labelClass="theme-3-input-label"
          name="email"
          value={careersContactFormData?.email}
          error={careersContactFormDataErrors?.emailError}
          onChange={(e) => onChange("email", e.target.value)}
          onBlur={() => {
            var output = careersContactFormData?.email?.trim();
            onBlur("email", output);
          }}
        />
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="Phone Number"
          labelClass="theme-3-input-label"
          name="phoneNumber"
          value={careersContactFormData?.phoneNumber}
          error={careersContactFormDataErrors?.phoneNumberError}
          onChange={(e) => {
            var output = formatPhoneNumber(e.target.value);
            if (output || output === "") onChange("phoneNumber", output);
          }}
        />
      </div>
      <div className="grid grid-cols-1">
        <Input
          containerClass="px-5 default-px-10 my-4 align-content-baseline"
          className="theme-3-input"
          label="If hired, what date are you available to begin employment?"
          labelClass="theme-3-input-label"
          name="availability"
          value={careersContactFormData?.availability}
          error={careersContactFormDataErrors?.availabilityError}
          onChange={(e) => onChange("availability", e.target.value)}
          onBlur={() => {
            var output = careersContactFormData?.availability?.trim();
            onBlur("availability", output);
          }}
        />
        {extraQuestion1 && extraQuestion1?.style?.toLowerCase() === "line" && (
          <Input
            containerClass="px-5 default-px-10 my-4 align-content-baseline"
            className="theme-3-input"
            label={extraQuestion1?.text}
            labelClass="theme-3-input-label"
            name="extraQuestion1"
            value={careersContactFormData?.extraQuestion1}
            error={careersContactFormDataErrors?.extraQuestion1Error}
            onChange={(e) => onChange("extraQuestion1", e.target.value)}
            onBlur={() => {
              var output = careersContactFormData?.extraQuestion1?.trim();
              onBlur("extraQuestion1", output);
            }}
          />
        )}
        {extraQuestion1 && extraQuestion1?.style?.toLowerCase() === "box" && (
          <TextArea
            containerClass="px-5 default-px-10 my-4 align-content-baseline"
            className="theme-3-text-area"
            label={extraQuestion1?.text}
            labelClass="theme-3-input-label"
            name="extraQuestion1"
            value={careersContactFormData?.extraQuestion1}
            error={careersContactFormDataErrors?.extraQuestion1Error}
            onChange={(e) => onChange("extraQuestion1", e.target.value)}
            onBlur={() => {
              var output = careersContactFormData?.extraQuestion1?.trim();
              onBlur("extraQuestion1", output);
            }}
          />
        )}
        {extraQuestion2 && extraQuestion2?.style?.toLowerCase() === "line" && (
          <Input
            containerClass="px-5 default-px-10 my-4 align-content-baseline"
            className="theme-3-input"
            label={extraQuestion2?.text}
            labelClass="theme-3-input-label"
            name="extraQuestion2"
            value={careersContactFormData?.extraQuestion2}
            error={careersContactFormDataErrors?.extraQuestion2Error}
            onChange={(e) => onChange("extraQuestion2", e.target.value)}
            onBlur={() => {
              var output = careersContactFormData?.extraQuestion2?.trim();
              onBlur("extraQuestion2", output);
            }}
          />
        )}
        {extraQuestion2 && extraQuestion2?.style?.toLowerCase() === "box" && (
          <TextArea
            containerClass="px-5 default-px-10 my-4 align-content-baseline"
            className="theme-3-text-area"
            label={extraQuestion2?.text}
            labelClass="theme-3-input-label"
            name="extraQuestion2"
            value={careersContactFormData?.extraQuestion2}
            error={careersContactFormDataErrors?.extraQuestion2Error}
            onChange={(e) => onChange("extraQuestion2", e.target.value)}
            onBlur={() => {
              var output = careersContactFormData?.extraQuestion2?.trim();
              onBlur("extraQuestion2", output);
            }}
          />
        )}
        {extraQuestion3 && extraQuestion3?.style?.toLowerCase() === "line" && (
          <Input
            containerClass="px-5 default-px-10 my-4 align-content-baseline"
            className="theme-3-input"
            label={extraQuestion3?.text}
            labelClass="theme-3-input-label"
            name="extraQuestion3"
            value={careersContactFormData?.extraQuestion3}
            error={careersContactFormDataErrors?.extraQuestion3Error}
            onChange={(e) => onChange("extraQuestion3", e.target.value)}
            onBlur={() => {
              var output = careersContactFormData?.extraQuestion3?.trim();
              onBlur("extraQuestion3", output);
            }}
          />
        )}
        {extraQuestion3 && extraQuestion3?.style?.toLowerCase() === "box" && (
          <TextArea
            containerClass="px-5 default-px-10 my-4 align-content-baseline"
            className="theme-3-text-area"
            label={extraQuestion3?.text}
            labelClass="theme-3-input-label"
            name="extraQuestion3"
            value={careersContactFormData?.extraQuestion3}
            error={careersContactFormDataErrors?.extraQuestion3Error}
            onChange={(e) => onChange("extraQuestion3", e.target.value)}
            onBlur={() => {
              var output = careersContactFormData?.extraQuestion3?.trim();
              onBlur("extraQuestion3", output);
            }}
          />
        )}
        {extraQuestion4 && extraQuestion4?.style?.toLowerCase() === "line" && (
          <Input
            containerClass="px-5 default-px-10 my-4 align-content-baseline"
            className="theme-3-input"
            label={extraQuestion4?.text}
            labelClass="theme-3-input-label"
            name="extraQuestion4"
            value={careersContactFormData?.extraQuestion4}
            error={careersContactFormDataErrors?.extraQuestion4Error}
            onChange={(e) => onChange("extraQuestion4", e.target.value)}
            onBlur={() => {
              var output = careersContactFormData?.extraQuestion4?.trim();
              onBlur("extraQuestion4", output);
            }}
          />
        )}
        {extraQuestion4 && extraQuestion4?.style?.toLowerCase() === "box" && (
          <TextArea
            containerClass="px-5 default-px-10 my-4 align-content-baseline"
            className="theme-3-text-area"
            label={extraQuestion4?.text}
            labelClass="theme-3-input-label"
            name="extraQuestion4"
            value={careersContactFormData?.extraQuestion4}
            error={careersContactFormDataErrors?.extraQuestion4Error}
            onChange={(e) => onChange("extraQuestion4", e.target.value)}
            onBlur={() => {
              var output = careersContactFormData?.extraQuestion4?.trim();
              onBlur("extraQuestion4", output);
            }}
          />
        )}
        {extraQuestion5 && extraQuestion5?.style?.toLowerCase() === "line" && (
          <Input
            containerClass="px-5 default-px-10 my-4 align-content-baseline"
            className="theme-3-input"
            label={extraQuestion5?.text}
            labelClass="theme-3-input-label"
            name="extraQuestion5"
            value={careersContactFormData?.extraQuestion5}
            error={careersContactFormDataErrors?.extraQuestion5Error}
            onChange={(e) => onChange("extraQuestion5", e.target.value)}
            onBlur={() => {
              var output = careersContactFormData?.extraQuestion5?.trim();
              onBlur("extraQuestion5", output);
            }}
          />
        )}
        {extraQuestion5 && extraQuestion5?.style?.toLowerCase() === "box" && (
          <TextArea
            containerClass="px-5 default-px-10 my-4 align-content-baseline"
            className="theme-3-text-area"
            label={extraQuestion5?.text}
            labelClass="theme-3-input-label"
            name="extraQuestion5"
            value={careersContactFormData?.extraQuestion5}
            error={careersContactFormDataErrors?.extraQuestion5Error}
            onChange={(e) => onChange("extraQuestion5", e.target.value)}
            onBlur={() => {
              var output = careersContactFormData?.extraQuestion5?.trim();
              onBlur("extraQuestion5", output);
            }}
          />
        )}
      </div>
    </div>
  );
};
