import { ContentfulCustomText } from "../api/types/contentful-content";

type props = {
  className?: string;
  contentfulCustomText?: ContentfulCustomText;
};

export const CustomText: React.FunctionComponent<props> = ({
  className,
  contentfulCustomText,
}) => {
  if (
    contentfulCustomText?.text !== undefined &&
    contentfulCustomText?.text.length > 0
  ) {
    return (
      <p
        className={
          contentfulCustomText?.alignment === "center"
            ? `${className} text-center`
            : contentfulCustomText?.alignment === "right"
            ? `${className} text-right`
            : `${className} text-left`
        }
      >
        {contentfulCustomText?.text}
      </p>
    );
  } else {
    return null;
  }
};
