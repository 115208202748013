type props = {
  src: string;
  href: string;
  imgClass?: string;
  buttonText: string;
};

export const GiftCardsItem: React.FunctionComponent<props> = ({
  src,
  href,
  imgClass,
  buttonText
}) => {
  return (
    <div className="w-11/12 default-w-2/3 mw-650 justify-self-center my-5">
      <img className={`${imgClass} w-full`} src={src} alt="" />
      <a className="theme-3-btn-small theme-font-alt mx-3/10" href={href}>
        {buttonText}
      </a>
    </div>
  );
};
