import { CaretDown } from "@styled-icons/boxicons-regular/CaretDown";
import { ContentfulNavbarDropdown } from "../api/types/contentful-content";

// refactor url to be in contentful
type props = {
  navbarDropdown: ContentfulNavbarDropdown;
  url1?: string;
  url2?: string;
  url3?: string;
  url4?: string;
  url5?: string;
  onClick: Function;
  showDropdown: boolean;
};

export const NavbarDropdown: React.FunctionComponent<props> = ({
  navbarDropdown,
  url1,
  url2,
  url3,
  url4,
  url5,
  onClick,
  showDropdown,
}) => {
  return (
    <div className="dropdown">
      <li>
        <button className="dropdown-text" onClick={() => onClick()}>
          <CaretDown className="navbar-caret-down-fill" />
          {navbarDropdown.label}
        </button>
      </li>
      <div
        className={
          showDropdown
            ? "dropdown-content-show dropdown-content"
            : "dropdown-content"
        }
      >
        {navbarDropdown &&
          navbarDropdown?.navbarHeader1 &&
          !navbarDropdown?.navbarHeader1.disabled &&
          url1 && <a href={url1}>{navbarDropdown?.navbarHeader1.text}</a>}
        {navbarDropdown &&
          navbarDropdown?.navbarHeader2 &&
          !navbarDropdown?.navbarHeader2.disabled &&
          url2 && <a href={url2}>{navbarDropdown?.navbarHeader2.text}</a>}
        {navbarDropdown &&
          navbarDropdown?.navbarHeader3 &&
          !navbarDropdown?.navbarHeader3.disabled &&
          url3 && <a href={url3}>{navbarDropdown?.navbarHeader3.text}</a>}
        {navbarDropdown &&
          navbarDropdown?.navbarHeader4 &&
          !navbarDropdown?.navbarHeader4.disabled &&
          url4 && <a href={url4}>{navbarDropdown?.navbarHeader4.text}</a>}
        {navbarDropdown &&
          navbarDropdown?.navbarHeader5 &&
          !navbarDropdown?.navbarHeader5.disabled &&
          url5 && <a href={url5}>{navbarDropdown?.navbarHeader5.text}</a>}
      </div>
    </div>
  );
};
