import { useEffect, useState } from "react";
import { useApi } from "../../api";
import { EVENTS_PAGE } from "../../api/contentful";
import {
  CONTENTFUL_GET_ENTRY_RESPONSE,
  ContentfulContent,
} from "../../api/types/contentful-content";
import { EventsEventTile } from "../../components/events-event-tile";

export const Events = () => {
  const api = useApi();
  const [contentfulContent, setContentfulContent] =
    useState<ContentfulContent>();

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.contentful.getEntry(EVENTS_PAGE);
      if (response.type !== CONTENTFUL_GET_ENTRY_RESPONSE) return null;
      setContentfulContent(response.data.fields);
      return response.data.fields;
    };
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="page-container flex flex-col">
      <div className="grid grid-cols-1 pb-5">
        {contentfulContent?.event1 && (
          <EventsEventTile event={contentfulContent.event1} />
        )}
        {contentfulContent?.event2 && (
          <EventsEventTile event={contentfulContent.event2} />
        )}
        {contentfulContent?.event3 && (
          <EventsEventTile event={contentfulContent.event3} />
        )}
        {contentfulContent?.event4 && (
          <EventsEventTile event={contentfulContent.event4} />
        )}
        {contentfulContent?.event5 && (
          <EventsEventTile event={contentfulContent.event5} />
        )}
        {contentfulContent?.event6 && (
          <EventsEventTile event={contentfulContent.event6} />
        )}
        {contentfulContent?.event7 && (
          <EventsEventTile event={contentfulContent.event7} />
        )}
        {contentfulContent?.event8 && (
          <EventsEventTile event={contentfulContent.event8} />
        )}
        {contentfulContent?.event9 && (
          <EventsEventTile event={contentfulContent.event9} />
        )}
        {contentfulContent?.event10 && (
          <EventsEventTile event={contentfulContent.event10} />
        )}
      </div>
    </div>
  );
};
