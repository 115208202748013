import { ContentfulTestimonial } from "../api/types/contentful-content";

type props = {
  testimonial: ContentfulTestimonial;
};

export const TestimonialTile: React.FunctionComponent<props> = ({
  testimonial,
}) => {
  return (
    <div className="grid grid-cols-1 theme-bg-color-3 w-near-full default-w-2/3 justify-self-center my-2">
      {testimonial?.image && (
        <img className="w-full" src={testimonial.image} alt="" />
      )}
      {testimonial?.name && (
        <h1
          className={
            "theme-text-color-4 theme-font text-3xl default-text-5xl mb-1 px-5 default-px-10 text-center mt-2"
          }
        >
          {testimonial.name}
        </h1>
      )}
      {testimonial?.description && (
        <p className="text-white text-1xl default-text-2xl mt-2 mb-8 px-5 text-center">
          {testimonial.description}
        </p>
      )}
    </div>
  );
};
