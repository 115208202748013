import { useEffect, useState } from "react";
import { useApi } from "../../api";
import { RESERVATIONS_PAGE } from "../../api/contentful";
import {
  CONTENTFUL_GET_ENTRY_RESPONSE,
  ContentfulContent,
} from "../../api/types/contentful-content";
import { CustomHeader } from "../../components/custom-header";
import { CustomText } from "../../components/custom-text";

export const Reservations = () => {
  const api = useApi();
  const [contentfulContent, setContentfulContent] =
    useState<ContentfulContent>();

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.contentful.getEntry(RESERVATIONS_PAGE);
      if (response.type !== CONTENTFUL_GET_ENTRY_RESPONSE) return null;
      setContentfulContent(response.data.fields);
      return response.data.fields;
    };
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const useScript = (url: string): any => {
    useEffect(() => {
      const script = document.createElement("script");
      script.src = url;
      script.async = true;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }, [url]);
  };

  return (
    <div className="page-container flex flex-col">
      <div className="grid grid-cols-1">
        {contentfulContent?.image1 && (
          <img className="w-full" src={contentfulContent.image1} alt="" />
        )}
        {contentfulContent?.header1 && (
          <CustomHeader
            className="theme-text-color-3 theme-font text-5xl px-5 default-px-10"
            contentfulCustomText={contentfulContent.header1}
          />
        )}
        {contentfulContent?.description1 && (
          <CustomText
            className="theme-text-color-3 text-2xl px-5 mt-0 default-px-10"
            contentfulCustomText={contentfulContent.description1}
          />
        )}
        {contentfulContent?.description2 && (
          <CustomText
            className="theme-text-color-3 text-2xl px-5 mt-0 default-px-10"
            contentfulCustomText={contentfulContent.description2}
          />
        )}
        {contentfulContent?.description3 && (
          <CustomText
            className="theme-text-color-3 text-2xl px-5 mt-0 default-px-10"
            contentfulCustomText={contentfulContent.description3}
          />
        )}
        {contentfulContent?.description4 && (
          <CustomText
            className="theme-text-color-3 text-2xl px-5 mt-0 default-px-10"
            contentfulCustomText={contentfulContent.description4}
          />
        )}
        {contentfulContent?.description5 && (
          <CustomText
            className="theme-text-color-3 text-2xl px-5 mt-0 default-px-10"
            contentfulCustomText={contentfulContent.description5}
          />
        )}
        {contentfulContent?.description6 && (
          <CustomText
            className="theme-text-color-3 text-2xl px-5 mt-0 default-px-10"
            contentfulCustomText={contentfulContent.description6}
          />
        )}
        {contentfulContent?.description7 && (
          <CustomText
            className="theme-text-color-3 text-2xl px-5 mt-0 default-px-10"
            contentfulCustomText={contentfulContent.description7}
          />
        )}
        {contentfulContent?.description8 && (
          <CustomText
            className="theme-text-color-3 text-2xl px-5 mt-0 default-px-10"
            contentfulCustomText={contentfulContent.description8}
          />
        )}
        {contentfulContent?.description9 && (
          <CustomText
            className="theme-text-color-3 text-2xl px-5 mt-0 default-px-10"
            contentfulCustomText={contentfulContent.description9}
          />
        )}
        <ul className="px-5 default-px-10 list-style-inside">
          {contentfulContent?.bullets1?.map((text, i) => (
            <li
              key={i}
              className="theme-text-color-3 text-xl text-left default-my-2 my-4"
            >
              {text}
            </li>
          ))}
        </ul>
        <div className="grid grid-cols-1">
          <div className="w-full default-w-11/12 justify-self-center overflow-hidden my-2 default-m-4">
            {contentfulContent?.header2 && (
              <CustomHeader
                className="theme-text-color-3 theme-font text-5xl mb-1 px-5 default-px-10"
                contentfulCustomText={contentfulContent.header2}
              />
            )}
            <iframe
              title="reservation"
              className="w-full acuity"
              src="https://app.squarespacescheduling.com/schedule.php?owner=28037105"
            >
              {useScript("https://embed.acuityscheduling.com/js/embed.js")}
            </iframe>
          </div>
        </div>
      </div>
    </div>
  );
};
