import { useEffect, useState } from "react";
import { useApi } from "../../api";
import { ONLINE_MENU_PAGE } from "../../api/contentful";
import {
  CONTENTFUL_GET_ENTRY_RESPONSE,
  ContentfulContent,
} from "../../api/types/contentful-content";
import { CustomHeader } from "../../components/custom-header";
import { CustomText } from "../../components/custom-text";

export const OnlineMenu = () => {
  const api = useApi();
  const [contentfulContent, setContentfulContent] =
    useState<ContentfulContent>();

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.contentful.getEntry(ONLINE_MENU_PAGE);
      if (response.type !== CONTENTFUL_GET_ENTRY_RESPONSE) return null;
      setContentfulContent(response.data.fields);
      return response.data.fields;
    };
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="page-container flex flex-col">
      <div className="grid grid-cols-1">
        {contentfulContent?.header1 && (
          <CustomHeader
            className="theme-text-color-3 theme-font text-5xl mb-1 px-5 default-px-10"
            contentfulCustomText={contentfulContent.header1}
          />
        )}
        {contentfulContent?.description1 && (
          <CustomText
            className="theme-text-color-3 text-2xl px-5 mb-2 default-px-10"
            contentfulCustomText={contentfulContent.description1}
          />
        )}
        {contentfulContent?.image1 && (
          <img
            className="w-full default-w-2/3 default-mt-4 mb-2 justify-self-center"
            src={contentfulContent.image1}
            alt=""
          />
        )}
        {contentfulContent?.image2 && (
          <img
            className="w-full default-w-2/3 default-mt-4 mb-2 justify-self-center"
            src={contentfulContent.image2}
            alt=""
          />
        )}
      </div>
    </div>
  );
};
