import { useState } from "react";
import {
  ApplyForAdoptionPastVetsFormData,
  ApplyForAdoptionPastVetsFormDataVet,
  ApplyForAdoptionPastVetsFormDataVetErrors,
} from "../api/types/apply-for-adoption-form-data";
import { formatPhoneNumber } from "../lib/text-helpers";
import { Input } from "./input";
import { RadioButton } from "./radio-button";

type props = {
  applyForAdoptionPastVetsFormData?: ApplyForAdoptionPastVetsFormData;
  addPastVet: Function;
  deletePastVet: Function;
};

export const ApplyForAdoptionPastVetsForm: React.FunctionComponent<props> = ({
  applyForAdoptionPastVetsFormData,
  addPastVet,
  deletePastVet,
}) => {
  const [currentVet, setCurrentVet] =
    useState<ApplyForAdoptionPastVetsFormDataVet>({
      animalName: "",
      veterinarian: "",
      phoneNumber: "",
    });
  const [currentVetErrors, setCurrentVetErrors] =
    useState<ApplyForAdoptionPastVetsFormDataVetErrors>({});
  const [yesOrNo, setYesOrNo] = useState("Yes");

  const submitVet = () => {
    let valid = validateVet();
    if (valid) {
      addPastVet(currentVet);
      setCurrentVet({
        animalName: "",
        veterinarian: "",
        phoneNumber: "",
      });
    }
  };

  const validateVet = (): boolean => {
    let valid = true;

    if (!currentVet.animalName || currentVet.animalName.trim().length === 0) {
      setCurrentVetErrors((currentVetErrors) => ({
        ...currentVetErrors,
        animalNameError: "Please enter a name.",
      }));
      valid = false;
    } else {
      setCurrentVetErrors((currentVetErrors) => ({
        ...currentVetErrors,
        animalNameError: undefined,
      }));
    }

    if (
      !currentVet.veterinarian ||
      currentVet.veterinarian.trim().length === 0
    ) {
      setCurrentVetErrors((currentVetErrors) => ({
        ...currentVetErrors,
        veterinarianError: "Please enter a veterinarian.",
      }));
      valid = false;
    } else {
      setCurrentVetErrors((currentVetErrors) => ({
        ...currentVetErrors,
        veterinarianError: undefined,
      }));
    }

    if (!currentVet.phoneNumber) {
      setCurrentVetErrors((currentVetErrors) => ({
        ...currentVetErrors,
        phoneNumberError: "Please enter a phone number.",
      }));
      valid = false;
    } else if (currentVet.phoneNumber.trim().length !== 14) {
      setCurrentVetErrors((currentVetErrors) => ({
        ...currentVetErrors,
        phoneNumberError:
          "Please enter a 10 digit phone number (XXX) XXX-XXXX.",
      }));
      valid = false;
    } else {
      setCurrentVetErrors((currentVetErrors) => ({
        ...currentVetErrors,
        phoneNumberError: undefined,
      }));
    }

    return valid;
  };

  return (
    <div>
      <ul className="px-5 default-px-10 list-style-inside">
        {applyForAdoptionPastVetsFormData?.pastVets?.map((vet, i) => (
          <li
            key={i}
            className="theme-text-color-3 text-xl text-left list-style-none default-my-2 my-4"
          >
            <div className="grid grid-cols-2-8/10 mt-4">
              <p>
                Animal Name: {vet.animalName} Veterinarian: {vet.veterinarian}{" "}
                Phone Number: {vet.phoneNumber}
              </p>
              <button
                className="theme-3-btn-small theme-font-alt mx-4"
                onClick={() => deletePastVet(i)}
              >
                X
              </button>
            </div>
          </li>
        ))}
      </ul>
      {yesOrNo === "Yes" && (
        <div className="grid grid-cols-1 default-grid-cols-2 mt-4">
          <Input
            containerClass="px-5 default-px-10 my-4 align-content-baseline"
            className="theme-3-input"
            label="Animal's Name"
            labelClass="theme-3-input-label"
            name="animalName"
            value={currentVet?.animalName}
            error={currentVetErrors?.animalNameError}
            onChange={(e) =>
              setCurrentVet((currentVet) => ({
                ...currentVet,
                animalName: e.target.value,
              }))
            }
            onBlur={() => {
              var output = currentVet?.animalName?.trim();
              setCurrentVet((currentVet) => ({
                ...currentVet,
                animalName: output,
              }));
            }}
          />
          <Input
            containerClass="px-5 default-px-10 my-4 align-content-baseline"
            className="theme-3-input"
            label="Veterinarian"
            labelClass="theme-3-input-label"
            name="veterinarian"
            value={currentVet?.veterinarian}
            error={currentVetErrors?.veterinarianError}
            onChange={(e) =>
              setCurrentVet((currentVet) => ({
                ...currentVet,
                veterinarian: e.target.value,
              }))
            }
            onBlur={() => {
              var output = currentVet?.veterinarian?.trim();
              setCurrentVet((currentVet) => ({
                ...currentVet,
                veterinarian: output,
              }));
            }}
          />
          <Input
            containerClass="px-5 default-px-10 my-4 align-content-baseline"
            className="theme-3-input"
            label="Phone Number"
            labelClass="theme-3-input-label"
            name="phoneNumber"
            value={currentVet?.phoneNumber}
            error={currentVetErrors?.phoneNumberError}
            onChange={(e) => {
              var output = formatPhoneNumber(e.target.value);
              if (output || output === "") {
                setCurrentVet((currentVet) => ({
                  ...currentVet,
                  phoneNumber: output,
                }));
              }
            }}
          />
        </div>
      )}
      {yesOrNo === "Yes" && (
        <button
          className="theme-3-btn-small theme-font-alt margin-auto"
          onClick={() => submitVet()}
        >
          Add Above Vet
        </button>
      )}
      <div className="grid grid-cols-1 mt-4 mb-6">
        <label className={"theme-3-input-label px-5 default-px-10 text-left"}>
          Would you like to add another Vet?
        </label>
        <RadioButton
          containerClass="px-5 default-px-10 mt-4 mb-2 align-content-baseline"
          label="Yes"
          labelClass={
            yesOrNo === "Yes"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="addAnotherVet_yes"
          checked={yesOrNo === "Yes"}
          value={yesOrNo}
          onChange={() => setYesOrNo("Yes")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-2 mb-4 align-content-baseline"
          label="No"
          labelClass={
            yesOrNo === "No" ? "radio-label radio-label-checked" : "radio-label"
          }
          name="addAnotherVet_no"
          checked={yesOrNo === "No"}
          value={yesOrNo}
          onChange={() =>
            yesOrNo === "Yes" &&
            applyForAdoptionPastVetsFormData?.pastVets?.length !== 0
              ? setYesOrNo("No")
              : ""
          }
        />
      </div>
    </div>
  );
};
