import { useState } from "react";
import {
  ApplyForAdoptionPetsFormData,
  ApplyForAdoptionPetsFormDataPet,
  ApplyForAdoptionPetsFormDataPetErrors,
} from "../api/types/apply-for-adoption-form-data";
import { formatDate } from "../lib/text-helpers";
import { Input } from "./input";
import { RadioButton } from "./radio-button";

type props = {
  applyForAdoptionPetsFormData?: ApplyForAdoptionPetsFormData;
  addPet: Function;
  deletePet: Function;
};

export const AdoptionApplicationListOfPetsForm: React.FunctionComponent<
  props
> = ({ applyForAdoptionPetsFormData, addPet, deletePet }) => {
  const [currentPet, setCurrentPet] = useState<ApplyForAdoptionPetsFormDataPet>(
    {
      name: "",
      typeOfPet: "",
      sex: "",
      age: "",
      yearsOwned: "",
      dateOfVaccination: "",
      spayedOrNeutered: "",
      indoorsOrOutdoors: "",
    }
  );
  const [currentPetErrors, setCurrentPetErrors] =
    useState<ApplyForAdoptionPetsFormDataPetErrors>({});
  const [yesOrNo, setYesOrNo] = useState("Yes");

  const submitPet = () => {
    let valid = validatePet();
    if (valid) {
      addPet(currentPet);
      setCurrentPet({
        name: "",
        typeOfPet: "",
        sex: "",
        age: "",
        yearsOwned: "",
        dateOfVaccination: "",
        spayedOrNeutered: "",
        indoorsOrOutdoors: "",
      });
    }
  };

  const validatePet = (): boolean => {
    let valid = true;

    if (!currentPet.name || currentPet.name.trim().length === 0) {
      setCurrentPetErrors((currentPetErrors) => ({
        ...currentPetErrors,
        nameError: "Please enter a name.",
      }));
      valid = false;
    } else {
      setCurrentPetErrors((currentPetErrors) => ({
        ...currentPetErrors,
        nameError: undefined,
      }));
    }

    if (!currentPet.typeOfPet || currentPet.typeOfPet.trim().length === 0) {
      setCurrentPetErrors((currentPetErrors) => ({
        ...currentPetErrors,
        typeOfPetError: "Please enter a type of pet.",
      }));
      valid = false;
    } else {
      setCurrentPetErrors((currentPetErrors) => ({
        ...currentPetErrors,
        typeOfPetError: undefined,
      }));
    }

    if (!currentPet.sex || currentPet.sex.trim().length === 0) {
      setCurrentPetErrors((currentPetErrors) => ({
        ...currentPetErrors,
        sexError: "Please enter a sex.",
      }));
      valid = false;
    } else {
      setCurrentPetErrors((currentPetErrors) => ({
        ...currentPetErrors,
        sexError: undefined,
      }));
    }

    if (!currentPet.age || currentPet.age.trim().length === 0) {
      setCurrentPetErrors((currentPetErrors) => ({
        ...currentPetErrors,
        ageError: "Please enter an age.",
      }));
      valid = false;
    } else {
      setCurrentPetErrors((currentPetErrors) => ({
        ...currentPetErrors,
        ageError: undefined,
      }));
    }

    if (!currentPet.yearsOwned || currentPet.yearsOwned.trim().length === 0) {
      setCurrentPetErrors((currentPetErrors) => ({
        ...currentPetErrors,
        yearsOwnedError: "Please enter years owned.",
      }));
      valid = false;
    } else {
      setCurrentPetErrors((currentPetErrors) => ({
        ...currentPetErrors,
        yearsOwnedError: undefined,
      }));
    }

    if (
      !currentPet.dateOfVaccination ||
      currentPet.dateOfVaccination.trim().length === 0
    ) {
      setCurrentPetErrors((currentPetErrors) => ({
        ...currentPetErrors,
        dateOfVaccinationError: "Please enter a date of vaccination.",
      }));
      valid = false;
    } else {
      setCurrentPetErrors((currentPetErrors) => ({
        ...currentPetErrors,
        dateOfVaccinationError: undefined,
      }));
    }

    if (
      !currentPet.spayedOrNeutered ||
      currentPet.spayedOrNeutered.trim().length === 0
    ) {
      setCurrentPetErrors((currentPetErrors) => ({
        ...currentPetErrors,
        spayedOrNeuteredError: "Please select an answer.",
      }));
      valid = false;
    } else {
      setCurrentPetErrors((currentPetErrors) => ({
        ...currentPetErrors,
        spayedOrNeuteredError: undefined,
      }));
    }

    if (
      !currentPet.indoorsOrOutdoors ||
      currentPet.indoorsOrOutdoors.trim().length === 0
    ) {
      setCurrentPetErrors((currentPetErrors) => ({
        ...currentPetErrors,
        indoorsOrOutdoorsError: "Please select an answer.",
      }));
      valid = false;
    } else {
      setCurrentPetErrors((currentPetErrors) => ({
        ...currentPetErrors,
        indoorsOrOutdoorsError: undefined,
      }));
    }

    return valid;
  };

  return (
    <div>
      <ul className="px-5 default-px-10 list-style-inside">
        {applyForAdoptionPetsFormData?.pets?.map((pet, i) => (
          <li
            key={i}
            className="theme-text-color-3 text-xl text-left list-style-none default-my-2 my-4"
          >
            <div className="grid grid-cols-2-8/10 mt-4">
              <p>
                Name: {pet.name} Type of Pet: {pet.typeOfPet} Sex: {pet.sex}{" "}
                Age: {pet.age} Years Owned: {pet.yearsOwned} Date of
                Vaccination: {pet.dateOfVaccination} Spayed/Neutered:{" "}
                {pet.spayedOrNeutered} Indoors/Outdoors: {pet.indoorsOrOutdoors}
              </p>
              <button
                className="theme-3-btn-small theme-font-alt mx-4"
                onClick={() => deletePet(i)}
              >
                X
              </button>
            </div>
          </li>
        ))}
      </ul>
      {yesOrNo === "Yes" && (
        <div className="grid grid-cols-1 default-grid-cols-2 mt-4">
          <Input
            containerClass="px-5 default-px-10 my-4 align-content-baseline"
            className="theme-3-input"
            label="Name"
            labelClass="theme-3-input-label"
            name="name"
            value={currentPet?.name}
            error={currentPetErrors?.nameError}
            onChange={(e) =>
              setCurrentPet((currentPet) => ({
                ...currentPet,
                name: e.target.value,
              }))
            }
            onBlur={() => {
              var output = currentPet?.name?.trim();
              setCurrentPet((currentPet) => ({
                ...currentPet,
                name: output,
              }));
            }}
          />
          <Input
            containerClass="px-5 default-px-10 my-4 align-content-baseline"
            className="theme-3-input"
            label="Type of Pet"
            labelClass="theme-3-input-label"
            name="typeOfPet"
            value={currentPet?.typeOfPet}
            error={currentPetErrors?.typeOfPetError}
            onChange={(e) =>
              setCurrentPet((currentPet) => ({
                ...currentPet,
                typeOfPet: e.target.value,
              }))
            }
            onBlur={() => {
              var output = currentPet?.typeOfPet?.trim();
              setCurrentPet((currentPet) => ({
                ...currentPet,
                typeOfPet: output,
              }));
            }}
          />
          <Input
            containerClass="px-5 default-px-10 my-4 align-content-baseline"
            className="theme-3-input"
            label="Sex"
            labelClass="theme-3-input-label"
            name="sex"
            value={currentPet?.sex}
            error={currentPetErrors?.sexError}
            onChange={(e) =>
              setCurrentPet((currentPet) => ({
                ...currentPet,
                sex: e.target.value,
              }))
            }
            onBlur={() => {
              var output = currentPet?.sex?.trim();
              setCurrentPet((currentPet) => ({
                ...currentPet,
                sex: output,
              }));
            }}
          />
          <Input
            containerClass="px-5 default-px-10 my-4 align-content-baseline"
            className="theme-3-input"
            label="Age"
            labelClass="theme-3-input-label"
            name="age"
            value={currentPet?.age}
            error={currentPetErrors?.ageError}
            onChange={(e) =>
              setCurrentPet((currentPet) => ({
                ...currentPet,
                age: e.target.value,
              }))
            }
            onBlur={() => {
              var output = currentPet?.age?.trim();
              setCurrentPet((currentPet) => ({
                ...currentPet,
                age: output,
              }));
            }}
          />
          <Input
            containerClass="px-5 default-px-10 my-4 align-content-baseline"
            className="theme-3-input"
            label="Years Owned"
            labelClass="theme-3-input-label"
            name="yearsOwned"
            value={currentPet?.yearsOwned}
            error={currentPetErrors?.yearsOwnedError}
            onChange={(e) =>
              setCurrentPet((currentPet) => ({
                ...currentPet,
                yearsOwned: e.target.value,
              }))
            }
            onBlur={() => {
              var output = currentPet?.yearsOwned?.trim();
              setCurrentPet((currentPet) => ({
                ...currentPet,
                yearsOwned: output,
              }));
            }}
          />
          <Input
            containerClass="px-5 default-px-10 my-4 align-content-baseline"
            className="theme-3-input"
            label="Date of Vaccinations"
            labelClass="theme-3-input-label"
            name="dateOfVaccinations"
            value={currentPet?.dateOfVaccination}
            error={currentPetErrors?.dateOfVaccinationError}
            placeholder="MM/DD/YYYY"
            onChange={(e) => {
              var output = formatDate(e.target.value);
              if (output || output === "") {
                setCurrentPet((currentPet) => ({
                  ...currentPet,
                  dateOfVaccination: output,
                }));
              }
            }}
            onBlur={() => {
              var output = currentPet?.dateOfVaccination?.trim();
              setCurrentPet((currentPet) => ({
                ...currentPet,
                dateOfVaccination: output,
              }));
            }}
          />
          <Input
            containerClass="px-5 default-px-10 my-4 align-content-baseline"
            className="theme-3-input"
            label="Spayed/Neutered"
            labelClass="theme-3-input-label"
            name="spayedOrNeutered"
            value={currentPet?.spayedOrNeutered}
            error={currentPetErrors?.spayedOrNeuteredError}
            onChange={(e) =>
              setCurrentPet((currentPet) => ({
                ...currentPet,
                spayedOrNeutered: e.target.value,
              }))
            }
            onBlur={() => {
              var output = currentPet?.spayedOrNeutered?.trim();
              setCurrentPet((currentPet) => ({
                ...currentPet,
                spayedOrNeutered: output,
              }));
            }}
          />
          <Input
            containerClass="px-5 default-px-10 my-4 align-content-baseline"
            className="theme-3-input"
            label="Indoors, Outdoors, or Both"
            labelClass="theme-3-input-label"
            name="indoorsOrOutdoors"
            value={currentPet?.indoorsOrOutdoors}
            error={currentPetErrors?.indoorsOrOutdoorsError}
            onChange={(e) =>
              setCurrentPet((currentPet) => ({
                ...currentPet,
                indoorsOrOutdoors: e.target.value,
              }))
            }
            onBlur={() => {
              var output = currentPet?.indoorsOrOutdoors?.trim();
              setCurrentPet((currentPet) => ({
                ...currentPet,
                indoorsOrOutdoors: output,
              }));
            }}
          />
        </div>
      )}
      {yesOrNo === "Yes" && (
        <button
          className="theme-3-btn-small theme-font-alt margin-auto"
          onClick={() => submitPet()}
        >
          Add Above Pet
        </button>
      )}
      <div className="grid grid-cols-1 mt-4 mb-6">
        <label className={"theme-3-input-label px-5 default-px-10 text-left"}>
          Would you like to add another Pet?
        </label>
        <RadioButton
          containerClass="px-5 default-px-10 mt-4 mb-2 align-content-baseline"
          label="Yes"
          labelClass={
            yesOrNo === "Yes"
              ? "radio-label radio-label-checked"
              : "radio-label"
          }
          name="addAnotherPastPet_yes"
          checked={yesOrNo === "Yes"}
          value={yesOrNo}
          onChange={() => setYesOrNo("Yes")}
        />
        <RadioButton
          containerClass="px-5 default-px-10 mt-2 mb-4 align-content-baseline"
          label="No"
          labelClass={
            yesOrNo === "No" ? "radio-label radio-label-checked" : "radio-label"
          }
          name="addAnotherPastPet_no"
          checked={yesOrNo === "No"}
          value={yesOrNo}
          onChange={() =>
            yesOrNo === "Yes" &&
            applyForAdoptionPetsFormData?.pets?.length !== 0
              ? setYesOrNo("No")
              : ""
          }
        />
      </div>
    </div>
  );
};
