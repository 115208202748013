import { ContentfulCustomText } from "../api/types/contentful-content";

type props = {
  className?: string;
  contentfulCustomText?: ContentfulCustomText;
};

export const CustomHeader: React.FunctionComponent<props> = ({
  className,
  contentfulCustomText,
}) => {
  return (
    <h1
      className={
        contentfulCustomText?.alignment === "center"
          ? `${className} text-center`
          : contentfulCustomText?.alignment === "right"
          ? `${className} text-right`
          : `${className} text-left`
      }
    >
      {contentfulCustomText?.text}
    </h1>
  );
};
