import React from "react";

type props = React.InputHTMLAttributes<HTMLTextAreaElement> & {
  label?: string;
  className?: string;
  labelClass?: string;
  containerClass?: string;
  innerRef?: any;
  error?: string;
};

export const TextArea: React.FunctionComponent<props> = ({
  className,
  label,
  labelClass,
  containerClass,
  innerRef,
  error,
  ...props
}) => {
  return (
    <div className={`grid grid-cols-1 ${containerClass}`}>
      {label && (
        <label
          htmlFor={props.name}
          className={
            error ? `${labelClass} input-error-message` : `${labelClass}`
          }
        >
          {label}
        </label>
      )}
      <textarea {...props} className={`${className}`} />
      {error && <p className="input-error-message">{error}</p>}
    </div>
  );
};
