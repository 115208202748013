import { ContentfulHomeNews } from "../api/types/contentful-content";

type props = {
  news: ContentfulHomeNews;
};

export const HomeNews: React.FunctionComponent<props> = ({ news }) => {
  return (
    <div>
      {news?.title && (
        <h1 className="theme-text-color-3 theme-font text-5xl mb-1 px-5 default-px-10">
          {news.title}
        </h1>
      )}
      {news?.text && news?.link && news?.linkText && (
        <div>
          <p className="theme-text-color-3 text-2xl px-5 mb-0 default-px-10">
            {news.text}
          </p>
          <a
            className="theme-text-color-3 text-2xl px-5 mb-0 default-px-10"
            href={news.link}
            target="_blank"
            rel="noreferrer"
          >
            {news.linkText}
          </a>
        </div>
      )}
      {news?.image && (
        <img
          className="w-full default-w-2/3 default-mt-4 justify-self-center"
          src={news.image}
          alt=""
        />
      )}
    </div>
  );
};
