export const numbersOnly = (input: string) => {
  if (!input) return input;
  var output = input.replace(/[^0-9]+/g, "");
  return output;
};

export const lettersOnly = (input: string) => {
  if (!input) return input;
  var output = input.replace(/[^a-zA-Z\s]+/g, "");
  return output;
};

export const formatPhoneNumber = (input: string) => {
  if (!input) return input;
  const phoneNumber = input.replace(/[^\d]/g, "");
  if (phoneNumber.length < 4) return phoneNumber;
  if (phoneNumber.length < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )} ${phoneNumber.slice(6, 10)}`;
};

export const formatCreditCard = (input: string) => {
  if (!input) return input;
  const creditCard = input.replace(/[^\d]/g, "");
  if (creditCard.length < 5) return creditCard;
  if (creditCard.length < 9) {
    return `${creditCard.slice(0, 4)} ${creditCard.slice(4)}`;
  }
  if (creditCard.length < 13) {
    return `${creditCard.slice(0, 4)} ${creditCard.slice(
      4,
      8
    )} ${creditCard.slice(8, 12)}`;
  }
  return `${creditCard.slice(0, 4)} ${creditCard.slice(
    4,
    8
  )} ${creditCard.slice(8, 12)} ${creditCard.slice(12, 16)}`;
};

export const formatDate = (input: string) => {
  if (!input) return input;
  const date = input.replace(/[^\d]/g, "");
  if (date.length < 3) {
    return date;
  }
  if (date.length < 5) {
    return `${date.slice(0, 2)}/${date.slice(2)}`;
  }
  return `${date.slice(0, 2)}/${date.slice(2, 4)}/${date.slice(4, 8)}`;
};
