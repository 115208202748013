import { App } from "./App";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import "./navbar.css";
import "./index.css";
import "./fonts/rastanty-cortez.ttf";

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);
