import { useEffect, useState } from "react";
import { useApi } from "../api";
import {
  ContentfulContent,
  CONTENTFUL_GET_ENTRY_RESPONSE,
} from "../api/types/contentful-content";
import { FOOTER_CONTENT } from "../api/contentful";
import { SocialFacebook } from "@styled-icons/foundation/SocialFacebook";
import { Instagram } from "@styled-icons/boxicons-logos/Instagram";

export const Footer = () => {
  const api = useApi();
  const [contentfulContent, setContentfulContent] =
    useState<ContentfulContent>();

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.contentful.getEntry(FOOTER_CONTENT);
      if (response.type !== CONTENTFUL_GET_ENTRY_RESPONSE) return null;
      setContentfulContent(response.data.fields);
      return response.data.fields;
    };
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="footer">
      <div className="grid grid-cols-1 default-grid-cols-2">
        <div className="footer-logo">
          <img
            className="w-5/6 default-w-1/2 max-default-mw-400"
            src={"/new-logo.png"}
            alt=""
          />
        </div>
        <div className="grid grid-cols-2 align-content-center">
          <div>
            {contentfulContent?.address && contentfulContent?.address.line1 && (
              <p className="text-xl default-text-2xl mt-0 mb-2">
                <a
                  className="footer-address"
                  href="https://www.google.com/maps/place/7852+E+96th+St,+Fishers,+IN+46037/@39.9282056,-86.0262888,17z/data=!3m1!4b1!4m5!3m4!1s0x8814b3449a54bff7:0xa86003bd6ebb63a6!8m2!3d39.9282015!4d-86.0241001"
                  target="_blank"
                  rel="noreferrer"
                >
                  {contentfulContent.address.line1}
                </a>
              </p>
            )}
            {contentfulContent?.address && contentfulContent?.address.line2 && (
              <p className="text-xl default-text-2xl mt-0 mb-2">
                <a
                  className="footer-address"
                  href="https://www.google.com/maps/place/7852+E+96th+St,+Fishers,+IN+46037/@39.9282056,-86.0262888,17z/data=!3m1!4b1!4m5!3m4!1s0x8814b3449a54bff7:0xa86003bd6ebb63a6!8m2!3d39.9282015!4d-86.0241001"
                  target="_blank"
                  rel="noreferrer"
                >
                  {contentfulContent.address.line2}
                </a>
              </p>
            )}
            {contentfulContent?.phoneNumber && (
              <p className="text-xl default-text-2xl mt-0 mb-5">
                <a
                  className="footer-phone-number"
                  href={`tel:${contentfulContent.phoneNumber}`}
                >
                  {contentfulContent.phoneNumber}
                </a>
              </p>
            )}
            <p className="text-xl default-text-2xl mt-0 mb-6">
              <a
                className="footer-email"
                href="mailto:info@smittenkittencatcafe.com?subject=New Message: SmittenKittenCatCafe.com&body="
              >
                Email Us
              </a>
            </p>
          </div>
          <div className="grid grid-cols-1 align-content-flex-start place-items-center">
            {contentfulContent?.headerText1 && (
              <h1 className="footer-header">{contentfulContent.headerText1}</h1>
            )}
            <div className="grid grid-cols-2">
              <a
                className="footer-facebook-icon"
                href="https://www.facebook.com/FishersCatCafe/"
                target="_blank"
                rel="noreferrer"
              >
                <SocialFacebook className="footer-facebook-icon" />
              </a>
              <a
                className="footer-instagram-icon"
                href="https://www.instagram.com/smittenkittencatcafe"
                target="_blank"
                rel="noreferrer"
              >
                <Instagram className="footer-instagram-icon" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
