import { ContentfulEventsEvent } from "../api/types/contentful-content";

type props = {
  event: ContentfulEventsEvent;
};

export const EventsEventTile: React.FunctionComponent<props> = ({ event }) => {
  return (
    <div className="grid grid-cols-1 w-near-full default-w-2/3 mw-800 justify-self-center mt-5">
      {event?.image && !event?.video && (
        <img className="event-tile-image" src={event.image} alt="" />
      )}
      {!event?.image && event?.video && (
        <iframe className="event-tile-video" src={event.video} />
      )}
      <div className={"grid grid-cols-1 theme-bg-color-3 theme-3-box-shadow"}>
        <div className="event-tile-background px-3">
          {event?.name && (
            <h1 className="theme-text-color-1 theme-font text-4xl default-text-5xl text-left-forced mt-4 mb-2">
              {event.name}
            </h1>
          )}
          {event?.date?.day && (
            <h1 className="theme-text-color-1 theme-font text-3xl mt-2 mb-0 text-left">
              {event.date.day}
            </h1>
          )}
          {event?.date?.month && (
            <p className="theme-text-color-1 text-2xl mt-0 mb-2 text-left">
              {event.date.month}
            </p>
          )}
          {event?.name && <span className="line-break" />}
          {event?.description && (
            <p className="theme-text-color-3 text-xl mt-0 text-left">
              {event.description}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
